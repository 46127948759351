import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../admin.css'
import { IoArrowUndoOutline } from 'react-icons/io5'
import {
  Button,
  Badge,
  Row,
  Container,
  Col,
  Form,
} from 'react-bootstrap'

import Swal from 'sweetalert2'

const URI = `${process.env.REACT_APP_BACKEND}`

const EditTheme = () => {
  const [form, setForm] = useState([])

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }
  const handleCheck = (e) => {
    const { name, value } = e.target
    if (e.target.checked) {
      setForm({
        ...form,
        [name]: 1,
      })
    } else {
      setForm({
        ...form,
        [name]: 0,
      })
    }
  }
  const { id } = useParams()
/*
  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.put(URI + 'theme/' + id, form)

    Swal.fire({
      title: 'Theme Updated!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/theme/all')
  }

  useEffect(() => {
    getThemeById()
  }, [])
*/
  const getThemeById = async () => {
    const res = await axios.get(URI + 'theme/' + id)
    console.log(res.data)
    setForm(res.data)
  }

  const doNothing = (e) => {
    e.preventDefault()
  }
  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" href="/admin/theme/all" variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Updating a Theme
          </h5>
          <Form onSubmit={doNothing}>
            <Form.Group>
              <Form.Label>Active Theme</Form.Label>
              <Form.Check
                type="checkbox"
                name=""
                style={{ paddingTop: '0.4rem' }}
                value={form.themeActive}
                onChange={handleCheck}
                checked={form.themeActive ? true : false}
                label="Activo?"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Text from Theme</Form.Label>
              <Form.Control
                name="themesText"
                className="service-title"
                value={form.themesText}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Background</Form.Label>
              <Form.Control
                name="themesBack"
                className="service-title"
                value={form.themesBack}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image Nr.1</Form.Label>
              <Form.Control
                name="themesImg1"
                className="service-title"
                value={form.themesImg1}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Url No.1</Form.Label>
              <Form.Control
                name="themesUrl1"
                className="service-title"
                value={form.themesUrl1}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>External Url No.1</Form.Label>
              <Form.Control
                name="themesUrlExt1"
                className="service-title"
                value={form.themesUrlExt1}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image Nr.2</Form.Label>
              <Form.Control
                name="themesImg2"
                className="service-title"
                value={form.themesImg2}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Url No.2</Form.Label>
              <Form.Control
                name="themesUrl2"
                className="service-title"
                value={form.themesUrl2}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>External Url No.2</Form.Label>
              <Form.Control
                name="themesUrlExt2"
                className="service-title"
                value={form.themesUrlExt2}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image Nr.3</Form.Label>
              <Form.Control
                name="themesImg3"
                className="service-title"
                value={form.themesImg3}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Url No.3</Form.Label>
              <Form.Control
                name="themesUrl3"
                className="service-title"
                value={form.themesUrl3}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>External Url No.3</Form.Label>
              <Form.Control
                name="themesUrlExt3"
                className="service-title"
                value={form.themesUrlExt3}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default EditTheme
