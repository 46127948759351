import { useState } from 'react'
import axios from 'axios'
//import { useNavigate } from 'react-router-dom'

const URI = `${process.env.REACT_APP_BACKEND}`

export const useFormServ = (initialForm, validationForm, typeForm, id, lang) => {
  const [form, setForm] = useState(initialForm)
  const [errorsEnd, setErrorsEnd] = useState({})
  const [loading, setLoading] = useState(false)
  const [responseForm, setResponseForm] = useState(null)
  const [topass, setTopass] = useState(null)
  const [imgToDelete, setImgToDelete] = useState([])
 // const navigate = useNavigate()
  /*const goBack = () => {
    navigate(-1)
  }*/

  const handleChange = (e) => {   
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
    handleValidate()
  }
  const handleCheck = (e) => {
    const { name, value } = e.target
    
    if (e.target.checked) {
      setForm({
        ...form,
        [name]: 1,
      })
    } else {
      setForm({
        ...form,
        [name]: 0,
      })
    }
    handleValidate()
  }

  const handleValidate = () => {
    setErrorsEnd(validationForm(form,lang))
  }

  const handleBlur = (e) => {
   // handleChange(e)
   // setErrors(validationForm(form,lang))
  }
 

  const handleSubmit = async (e) => {
    e.preventDefault()
     if (Object.keys(errorsEnd).length === 0) {
      console.log('errores es cero')
      console.log(form)
      form.countryId = parseInt(form.countryId)
      form.cityId = parseInt(form.cityId)
     // form.published = form.published == "" ? form.pulished : 0
      if (lang === 'es')
        {
          if (form.titleEn === '' || form.titleEn == null)
            form.titleEn = form.title
          if (form.descripEn === '' || form.descripEn == null)
            form.descripEn = form.descrip
          if (form.bolderTextEn === '' || form.bolderTextEn == null)
            form.bolderTextEn = form.bolderText
          
          if (form.workTimeEn === '' || form.workTimeEn == null)
            form.workTimeEn = form.workTime
          
        }
        else
        {
          if (form.title === '' || form.title == null)
            form.title = form.titleEn
          if (form.descrip === '' || form.descrip == null)
            form.descrip = form.descripEn
          if (form.bolderText === '' || form.bolderText == null)
            form.bolderText = form.bolderTextEn
          if (form.workTime === '' || form.workTime == null)
            form.workTime = form.workTimeEn
        }
      setLoading(true)

      try {
        switch (typeForm) {
          case 'updType': {
            await axios.put(URI + 'sales/' + id, form)
            setResponseForm(true)
            setTopass('')
            setLoading(false)
            if (imgToDelete.length > 0) {
              try {
                for (let i = 0; i < imgToDelete.length; i++) {
                  await axios.delete(URI + 'images/' + imgToDelete[i])
                }
              } catch (error) {
                setTopass(
                  `No se pudo borrar las fotos eliminadas durante el proceso:${error.response.data}`,
                )
              }
            }
            return
          }
          case 'addType': {
            
            await axios.post(URI + 'sales/', form)
            setResponseForm(true)
            setTopass('')
            setLoading(false)
            if (imgToDelete.length > 0) {
              try {
                for (let i = 0; i < imgToDelete.length; i++) {
                  await axios.delete(URI + 'images/' + imgToDelete[i])
                }
              } catch (error) {
                setTopass(
                  `No se pudo borrar las fotos eliminadas durante el proceso:${error.response.data}`,
                )
              }
            }
            setForm(initialForm)
            return
          }
          default:
            return null
        }
      } catch (error) {
        console.log('error al validar')
        
        setLoading(false)
        setResponseForm(false)
      }
    } else {
      return
    }
  }

  return {
    form,
    setForm,
    errorsEnd,
    loading,
    responseForm,
    topass,
    imgToDelete,
    setImgToDelete,
    handleChange,
    handleCheck,
    handleBlur,
    handleSubmit,
  }
}
