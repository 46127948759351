import { useState, useEffect } from 'react'

export const useWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  // const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    setWidth(window.innerWidth)
    // setHeight(window.innerHeight)
  }

  //return { width, height }
  return { width }
}
