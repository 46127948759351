import React, { useEffect } from 'react'
// importing aos
import AOS from 'aos'
import IsoTopeGrid from 'react-isotope'
import AmazingFilters from './AmazingFilters'

import 'aos/dist/aos.css'
//images
import aimg1 from '../img/amazing/amazing-1.jpg'
import aimg2 from '../img/amazing/amazing-2.jpg'
import aimg3 from '../img/amazing/amazing-3.jpg'
import aimg4 from '../img/amazing/amazing-4.jpg'
import aimg5 from '../img/amazing/amazing-5.jpg'
import aimg6 from '../img/amazing/amazing-6.jpg'
import aimg7 from '../img/amazing/amazing-7.jpg'
import aimg8 from '../img/amazing/amazing-8.jpg'
import aimg9 from '../img/amazing/amazing-9.jpg'
import './amazing.css'
const cardsLayout = [
  {
    id: 'a',
    im: aimg1,
    row: 0,
    col: 0,
    w: 1,
    h: 1,
    filter: ['test', 'chart'],
  },
  {
    id: 'b',
    im: aimg2,
    row: 0,

    col: 1,
    w: 1,
    h: 1,
    filter: ['test1', 'tile'],
  },
  {
    id: 'c',
    im: aimg3,
    row: 0,
    col: 3,
    w: 1,
    h: 1,
    filter: ['test', 'chart'],
  },
  {
    id: 'd',
    im: aimg4,
    row: 1,
    col: 0,
    w: 1,
    h: 1,
    filter: ['test1', 'tile'],
  },
  {
    id: 'e',
    im: aimg5,
    row: 1,
    col: 1,
    w: 1,
    h: 1,
    filter: ['test', 'tile'],
  },
  {
    id: 'f',
    im: aimg6,
    row: 1,
    col: 2,
    w: 1,
    h: 1,
    filter: ['test1', 'chart'],
  },
  {
    id: 'h',
    im: aimg7,
    row: 2,
    col: 0,
    w: 1,
    h: 1,
    filter: ['test1', 'chart'],
  },
  {
    id: 'i',
    im: aimg8,
    w: 1,
    h: 2,
    filter: ['test1', 'chart'],
  },
  {
    id: 'j',
    im: aimg9,
    w: 1,
    h: 2,
    filter: ['test1', 'chart'],
  },
]

const filtersDefault = [
  { label: 'all', isChecked: true },
  { label: 'test', isChecked: false },
  { label: 'test1', isChecked: false },
  { label: 'chart', isChecked: false },
  { label: 'tile', isChecked: false },
]

export default function Amazing1() {
  useEffect(() => {
    AOS.init()
  }, [])
  const [filters, updateFilters] = React.useState(filtersDefault)

  return (
    <section id="amazing" className="amazing">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>INTERESANTES</h2>
          <p>Sitios, lugares, espacios ... Visitelos!</p>
        </div>
        <AmazingFilters filters={filters} updateFilters={updateFilters} />

        <div className="col-lg-4 col-md-6 amazing-item">
          <IsoTopeGrid
            gridLayout={cardsLayout} // gridlayout of cards
            //layoutMode= 'fitRows'
            //itemSelector = '.amazing-item'
            //noOfCols={4} // number of columns show in one row
            //unitWidth={200} // card width of 1 unit
            //unitHeight={100} // card height of 1 unit
            filters={filters} // list of selected filters
          >
            {cardsLayout.map((card, index) => {
              return (
                <div key={card.id}>
                  <img src={card.im} alt="" />
                  {card.filter.map((f, index) => {
                    return (
                      <>
                        <span key={index}>{f}</span>/n
                        <br />
                      </>
                    )
                  })}
                </div>
              )
            })}
          </IsoTopeGrid>
        </div>
      </div>
    </section>
  )
}
