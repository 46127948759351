import React, { useState, useContext } from 'react'
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Spinner,
  Image,
} from 'react-bootstrap'
import { FaPersonArrowDownToLine, FaEye, FaEyeSlash, FaKey } from 'react-icons/fa6'

//import { useForm } from '../hooks/useForm'
import './auth.css'
import logo from '../img/logo/logowhite.png'
import { TimerMessage } from '../components/TimerMessage'
import OTPRequest from './OTPRequest.jsx'
import PwdChange from './PwdChange.jsx'

//const URI = `${process.env.REACT_APP_BACKEND}`

const initialForm = {
  usermail: '',
  password: '',
}

const URI = `${process.env.REACT_APP_BACKEND}`

const Login = () => {
  const { texts} = useContext(LanguageContext)
  const navigate = useNavigate()
  const [agree, setAgree] = useState(false)
  const [recovering, setRecovering] = useState(false)
  //const [showOtp, setShowOtp] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [topass, setTopass] = useState(null)
  const [userId, setUserId] = useState(null)
  const [response, setResponse] = useState(null)
  const { login, usrLang, setEmail,setOTP, setShowWhat, showWhat } = useContext(AuthContext)
  // showWhat smail show send email, sotp show to put otp and verify, schange show new password and again (this last value is setting in OTPRequest)
  const { handleLanguage } = useContext(LanguageContext)
  const { showtext } = useParams()
  const { from } = useParams()
  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const validationsForm = (form,isRecover) => { 
    console.log(`entro a validation ${form.usermail}`)
    //errors.usermail=''
    //errors.password=''
    
    console.log(errors.usermail)
   // let errors = {}
    //expresiones regulares para cada tipo de campo    
    let regexUserMail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
    //let regexComments = /^.{1,255}$/
    if (!form.usermail.trim()) {
      errors.usermail = texts.errReq
    } else if (!regexUserMail.test(form.usermail.trim())) {
      errors.usermail = texts.errFormat
    } else if (form.usermail.trim().length > 150) {
      errors.usermail = texts.err150
    } 
    if (!isRecover) {
        if (!form.password.trim()) {
          errors.password = texts.errReq
        }
    }
    console.log(errors)
    //return errors
 
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        //console.log(`form al llamar al login: ${form.usermail},${form.password}`)
        await login(form)
        handleLanguage(usrLang)
        setTopass(form.usermail)
        setLoading(false)
        setResponse(true)       
        return
      } catch (error) {        
        if (error.toString().indexOf('409') !== -1) 
          setTopass(texts.errEmail)
        else if (error.toString().indexOf('400') !== -1) 
          setTopass(texts.errPass) 
        setLoading(false)
        setResponse(false)
      }
    }
  }
    
 const sendGmail = async() => {    
   const OTP = Math.floor(Math.random() * 9000 + 1000)
   setOTP(OTP)  
   await axios
        .post(URI + 'nodemailer', {
          OTP: OTP,
          gmail: form.usermail,
          name: texts.auEmailTo,
          subject: texts.auEmailSubj,
          message: texts.auEmailMess,
   })  
   .then(() => {
    setTopass(texts.auSendedEmail)
   })
   .catch((err) => {
          setTopass(texts.auEmailError)
          console.error(err)
   })
}
/*
const setValid = (myForm,isRecover) => {
  setError(validationsForm(myForm,isRecover))
  
}
*/
  const handleReset = async() => { 
    console.log('reset')  
    setErrors({})
    setTopass('') 
    validationsForm(form,true)    
    if (Object.keys(errors).length === 0) {
      console.log('no error')
      setShowWhat('smail')
      setEmail(form.usermail)         
      const res = await checkEmail(form)
        if (res.data) {
          console.log('es usuario')
          setUserId(res.data.id)          
          //setErrors({})
          sendGmail()                   
          setShowWhat('sotp')        
          setRecovering(true)
        }
        else {
          console.log('no es usuario')
          setTopass(texts.errEmail)
          setLoading(false)
          setResponse(false)
        } 
    }
    else {
      console.log('si error')
        setLoading(false)
        setResponse(false)
    }    
  }
  const checkEmail = async (inputs) => {
    return await axios.post(URI + 'auth/finduser', inputs)
  }
  
    const registration = () => {
    navigate('/auth/registration')
  }

  const doNothing = (e) => {
    e.preventDefault()
  }

  const handleCancel = () => {
    setRecovering(false)
  }

  return (
    <div className="auth pt-2">
      <Container fluid className="auth-card">
        {showtext==='1' &&  
          <Row>
            <Col>
              <TimerMessage
                mess={from === '1' ? texts.RegisterSite : texts.Register}
                variant={'danger'}
                navig={'close'}              />
            </Col>
          </Row>
        }
        <Row>
          <Col>
          <Image
                    src={logo} 
                    alt={texts.title}
                    title={texts.title}
                  />{' '} 
          </Col>
        </Row>
        {!response && (
          <div>
            <Row>
              <Col>
                <Card>
                  <Card.Header className="auth-card-header">
                    {texts.auLogin }
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={doNothing}>
                      <>
                        <Row>
                          <Col>
                            {(topass !== null && topass !=='') && <p className="auth-err">{topass}</p>}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="auth-label">
                           {texts.auEmail}:
                          </Col>
                        </Row>
                        <Row>                          
                          
                          {recovering ?
                            <Col className="auth-label-mail">
                                {form.usermail}  
                            </Col>
                          :
                            <Col className="auth-label">
                              <input
                                autoComplete="off"
                                className="form-control inputstl auth-input"
                                name="usermail"
                                placeholder={texts.auEmailPH}
                                type="text"
                                autoFocus
                                onChange={handleChange}
                                value={form.usermail}                    
                                required
                              />
                              </Col>
                          }                            
                          
                        </Row>
                        <Row>
                          {errors.usermail && (
                            <p className="auth-err">{errors.usermail}</p>
                          )}
                        </Row>
                        {!recovering &&
                            <div>
                              <Row>
                                <Col className="auth-label">{texts.auPass}:</Col>
                              </Row>
                              <Row>
                                <Col className="auth-icontext">
                                  <input
                                    className="form-control inputstl auth-input"
                                    type={showPwd ? 'text' : 'password'}
                                    autoComplete="off"
                                    name="password"
                                    placeholder={texts.auPassPH}
                                    onChange={handleChange}
                                    value={form.password}
                                    
                                    required
                                  />

                                  <span
                                    className="icon-inside"
                                    onClick={() => setShowPwd(!showPwd)}
                                  >
                                    {showPwd ? <FaEye /> : <FaEyeSlash />}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                {errors.password && (
                                  <p className="auth-err">{errors.password}</p>
                                )}
                              </Row>
                              <Row>
                                <Col className="auth-label">
                                  <div>
                                    <Form.Check
                                      type="checkbox"
                                      name="agree"
                                      style={{ paddingTop: '0.4rem' }}
                                      value={agree}
                                      onChange={(e) => setAgree(!agree)}
                                      checked={agree}                                    
                                      label={texts.auTyCon}
                                    />{' '}
                                  </div>
                                </Col>
                              </Row> 
                              {!agree &&
                              <Row>
                                <Col className="auth-advice-yellow">
                                  &nbsp;&nbsp;{texts.auMark}
                                </Col>
                              </Row>
                              }
                              {/*
                              <Row className="mt-2">
                                <Col className="auth-label">
                                  <div>
                                    <Form.Check
                                      type="checkbox"
                                      name="rememberMe"
                                      style={{ paddingTop: '0.4rem' }}
                                      value={false}
                                      checked={false}
                                      
                                      label="Recordarme?"
                                    />{' '}
                                  </div>
                                </Col>
                              </Row> 
                            */} 
                            {agree &&                 
                              <Row>
                                <Col>
                                  
                                    <Button
                                      onClick={handleSubmit}
                                      className="auth-btnini"                            
                                      disabled={!agree}
                                    >
                                      {loading && (
                                        <Spinner
                                          style={{ textAlign: 'center' }}
                                          size="sm"
                                          animation="border"
                                          variant="light"
                                        />
                                      )}{' '}
                                      {texts.auLogin}
                                    </Button>
                                
                                </Col>
                              </Row>
                          }
                          </div>
                        }
                        <Row>
                          <Col className='d-flex justify-content-center'>
                            {!recovering ?
                              <Link onClick={handleReset} style={{marginTop:'10px',fontSize:'0.8rem',textDecoration:'none'}}>
                              <FaKey />&nbsp;{texts.auForgetPass}
                            </Link>
                            :
                                showWhat === 'smail' ?
                                  <div className='center'>
                                    <p className='auth-advice-yellow' style={{textAlign:'start'}} > 
                                      {texts.auSendEmail}
                                      </p>
                                    <Button variant="success" onClick={handleReset}>
                                      <span  style={{ fontSize: '0.8rem' }}>{texts.auSendEmailYes}</span> 
                                    </Button>&nbsp;
                                    <Button variant="danger" onClick={handleCancel}>
                                      <span style={{ fontSize: '0.8rem' }}>{texts.auSendEmailNo}</span> 
                                    </Button>
                                  </div>
                              : showWhat === 'sotp' ?
                                  <div className='center'>
                                    <OTPRequest />
                                  </div>
                                  :
                                  <div className='center'>
                                    <PwdChange 
                                      propsId={userId}
                                    />                                   
                                  </div>
                            }                           
                          </Col>
                        </Row>                    
                      </>
                    </Form>
                  </Card.Body>
                  <Card.Footer className="auth-registration">                  
                      <Button
                        variant="warning"
                        onClick={registration}
                        style={{ fontSize: '0.8rem' }}>
                        {texts.auRegi} <FaPersonArrowDownToLine />
                      </Button>                    
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="auth-advice">
                <Link to={'/conditions'}>
                  [{texts.auTermyCond}]
                </Link>
              </Col>
            </Row>
          </div>
        )}
      </Container>
      <Row>
        <Col>
          {response && (
            <TimerMessage
              mess={texts.auWelcome}
              variant={'success'}
              navig={'/home'}
              timetogo="1500"
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Login
