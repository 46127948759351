import React, { useState, useEffect, useContext } from 'react'
import LanguageContext from '../context/LanguageContext'
import SearchContext from '../context/SearchContext'
import { Carousel, Container, Row, Image, Col, Modal, Button, Card } from 'react-bootstrap'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
// import for themes section
import 'aos/dist/aos.css'
import '../content/home.css'
import Texts from '../content/Texts.js'
import ReactPlayer from 'react-player'
import { useWidth } from '../hooks/useWidth'
import {
  FaAngleRight,
  FaAngleLeft,
  FaRegNewspaper,
} from 'react-icons/fa'

const URI = `${process.env.REACT_APP_BACKEND}`
const URIMG = `${process.env.REACT_APP_BACKENDPUB}`
//const URIMG = `${process.env.REACT_APP_IMAGES}`
//const typeId =0

const Home = () => {
  const { width } = useWidth()
  const { texts} = useContext(LanguageContext)
  const { handleSearchId } = useContext(SearchContext)
  const [carousel, setCarousel] = useState([])
  const [news, setNews] = useState([])
  const [diary, setDiary] = useState([])
  const [diaryCuban, setDiaryCuban] = useState([])
  const [typeList, setTypeList] = useState([])
  const navigate = useNavigate()

  function getDate() {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    const date = today.getDate()
    return `${month}${date}${year}`
  }
  let today = useState(getDate());
    
  let idiom = texts.idiom === 'es' ? true : false

  const getCarouselById = async () => {
    const res = await axios.get(URI + 'carousel/caroId/1')
    console.log(res.data)
    setCarousel(res.data)
  } 
  
  const getNewsById = async () => {
    const res = await axios.get(URI + 'carousel/caroId/3')
    setNews(res.data)
  }
   
  const getType= async () => {
    const res = await axios.get('/api/type/')
    console.log('types')
    console.log(res.data)
    setTypeList(res.data.sort((a,b) => a.typeName.localeCompare(b.typeName)))
  }
     
  useEffect(() => {
    getCarouselById()
    console.log(carousel)
    getNewsById()
    console.log(news)
    getType()
    //eslint-disable-next-line
  }, [])
/*
  const getSaleExtByActive = async () => {
    const res = await axios.get(URI + 'salext/active/1')
    setTheme(res.data)
  }
 */
const getDiaryByDate = async () => {
  const res = await axios.get(URI + `diary/date/${today[0]}`)
  console.log(today[0])
  console.log(res.data)
  setDiaryCuban(res.data.filter((d) => d.from === 1))
  setDiary(res.data.filter((d) => d.from !== 1))
}

useEffect(() => {
    getDiaryByDate()
  //  getSaleExtByActive()
     //eslint-disable-next-line
}, [])
 
  //Modal
  const [show, setShow] = useState(false);
  const [from, setFrom] = useState();
 // const [showType, setShowType] = useState(1);

  const handleClose = () => {
    setShow(false);
  }

  const sliders = document.querySelector(".news-caro-box")

  const leftArrowClick = () => {
    sliders.scrollLeft -= sliders.offsetWidth   
  }
  const rightArrowClick = () => {
   sliders.scrollLeft += sliders.offsetWidth  
  }

 const handlingSearch = (typeId) => {
  handleSearchId(typeId)
  navigate('/shop/shophome/1/0')
 }

  return (

      <div id='home' className='home'>
        <Container fluid  className='box-home p-0' >
            <Row className='box-home'>
              <Carousel fade indicators={false} className="home-carousel center">             
                {carousel.map((caro) => (
                  <Carousel.Item key={caro.id}>
                    <Link to={caro.link}>
                      <Image 
                        src={URIMG + 'carousel/' + (width < 992 ?caro.sImg:caro.img)}
                        className="home-img"
                        title={`${idiom ? caro.imgName : caro.imgNameEn} ${idiom ? caro.imgPlace : caro.imgPlaceEn}`}
                      />
                      <Carousel.Caption className='center'>
                        <h3>{idiom ? caro.imgName : caro.imgNameEn}&nbsp;({idiom ? caro.imgPlace : caro.imgPlaceEn})</h3>
                        
                      </Carousel.Caption>
                    </Link>
                  </Carousel.Item>
                ))}
              </Carousel>        
            </Row>   

      <div className='gradient'>
        
      <Row id='news' className='news-row1' >
          <Col xs={12} >
              <div>
              <Carousel indicators={false} controls={false}>                
                {news.map((caro) => (
                  <Carousel.Item interval={2000} key={caro.id}>
                    <Link to={caro.link} target='_blank'> 
                    {<span className='news-row1-text'><FaRegNewspaper style={{marginBottom:'2px',color:'#c1bfbf'}}/>&nbsp;{idiom ? caro.imgName : caro.imgNameEn}    
                    </span>}
                    </Link>
                  </Carousel.Item>
                ))}
              </Carousel>
              </div>
        </Col>            
      </Row>
      <Row>
        <Col xs={12}>
          <div className='news-services'>
              <span className='news-services-text'>
                {texts.hoTitleServices}                  
              </span>   
            <div className='news-caro-serv'>              
            <div className='news-caro-box'>
                {typeList.map((item) => (
               
                      <Card 
                        key={item.id} 
                        onClick={()=>handlingSearch(item.id)}
                        className='news-caro-card'
                        >
                            <Card.Header style={{paddingLeft:'0'}} >
                                <span style={{color:'#b80404'}}> ✮&nbsp;</span><span className='ext-title'>{idiom ? item.typeName : item.typeNameEn}</span>
                            </Card.Header>
                            <Card.Img
                                src={URI + 'types/' + (
                                  (item.typeImg !== null && item.typeImg !== '') ?item.typeImg:'no-image.jpg')}
                                title={item.typeName}
                             />                   
                      </Card>  
     
                      ))}
                  </div>
                <Button id='left-arrow' className='news-caro-left-arrow' onClick={leftArrowClick}
                ><FaAngleLeft />
                </Button>                   
                <Button id='right-arrow' className='news-caro-right-arrow' onClick={rightArrowClick}><FaAngleRight />
                </Button>         
            </div>
          </div>    
        </Col>
      </Row>
      <Row  className='news-row3 center'>       
        <Col xs={6}>                   
          <div className='news-row3-begin center-class' onClick={()=>{setShow(true);setFrom(0)}} >
                 <Image
                  src={URIMG + 'diary/init-img/social.gif'}                  
                  width='100'
                  height='100'
                  title={texts.hoSurprisePlus}             
                  style={{marginTop:'-15px'}}       
                />
               <span className='news-row3-text' >... Desde las Redes</span> 
          </div>
        </Col>       
        <Col xs={6}>         
              <div className='news-row3-end center-class' onClick={()=>{setShow(true);    setFrom(1)}} >
              <span className='news-row3-text'>A lo Cubano ...</span>
              <Image
                  src={URIMG + 'diary/init-img/home-img.gif'}                  
                  width={width<800?'85' : '90'}
                  height={width<800?'75' : '80'}
                  className='ext-gift'
                  title={texts.hoSurprisePlus} 

                  style={{marginTop:'-2px'}}         
                />                
              </div>              
        </Col>       
      </Row>
      </div>
        <Row id = 'About Services' className='box-slogans-services' style={{textAlign:'center'}}>
          <Col lg={12} xs={12} md={12}  className='p-0' >
            <Link to='/shop/shophome/1/0'>
              <Image
                 src={width < 992 ? (idiom ? URIMG + '/home/services-small.jpg' : URI + 'home/servicesen-small.jpg'):(idiom ? URIMG + '/home/services.jpg' : URI + 'home/servicesen.jpg')}
                 className='slogans'                 
                 fluid
              />
            </Link>
          </Col>
        </Row>
        <Row id='about sites' className='box-slogans-sites pb-3 mb-0'  style={{textAlign:'center'}}>
          <Col lg={12} xs={12} md={12} sm={12}  className='p-0'>            
            <Link to='/sites/1/0'>
            <Image
                 src={width < 992 ? (idiom ? URIMG + 'home/sites-small.gif' : URIMG + 'home/sitesen-small.gif'):(idiom ? URIMG + 'home/sites.gif' : URIMG + 'home/sitesen.gif')}
                 className='slogans'                 
                 fluid
              />           
            </Link>
          </Col>
        </Row>

        <Modal 
          scrollable 
          show={show} 
          dialogClassName='modal-90'
          onHide={handleClose} 
          centered> 
          <Modal.Header closeButton className='modal-header'>            
          </Modal.Header>         
          <Modal.Body className='modal-body align-center'>
          {from ===1 ? 
            diaryCuban[0] && (                              
              diaryCuban[0].type === 1 ? 
                idiom? 
                <Texts description={diaryCuban[0].diary} />
                :
                <Texts description={diaryCuban[0].diaryEn} />
              :
                diaryCuban[0].type === 2 ?
                        <Image 
                          src={URIMG + 'diary/multi/' + diaryCuban[0].diary}
                          title={texts.hoSurpriseLus}
                          className='modal-image'
                        />
                :
                      <div className='player-wrapper'>
                        <ReactPlayer 
                          url={URIMG + 'diary/multi/' + diaryCuban[0].diary}
                          controls  
                          volume='0.1'                        
                          width='100%'
                          height='100%'
                          className='react-player'
                        />
                      </div>
            )
          :
            diary[0] && (                              
              diary[0].type === 1 ? 
                idiom? 
                <Texts description={diary[0].diary} />
                :
                <Texts description={diary[0].diaryEn} />
              :
                diary[0].type === 2 ?
                        <Image 
                          src={URIMG + 'diary/multi/' + diary[0].diary}
                          title={texts.hoSurpriseLus}
                          className='modal-image'
                        />
                :
                      <div className='player-wrapper'>
                        <ReactPlayer 
                          url={URIMG + 'diary/multi/' + diary[0].diary}
                          controls  
                          volume='0.1'                        
                          width='100%'
                          height='100%'
                          className='react-player'
                        />
                      </div>
            )
          } 
           
                    
          </Modal.Body>
          <Modal.Footer className='modal-footer'>
            {from ===1 ? 
              diaryCuban[0] && (
                idiom?diaryCuban[0].diaryDet: diaryCuban[0].diaryDetEn
              )   
            : 
              diary[0] && (
                idiom?diary[0].diaryDet: diary[0].diaryDetEn
              )
            }
            
          </Modal.Footer >
      </Modal>           
     </Container>
     </div>  

  )
}

export default Home