import React, { useEffect, useState, useContext, useRef } from 'react'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import GetRating from '../components/GetRating.js'
import GetRatingTotal from '../components/GetRatingTotal.js'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Container, Card, Row, Col, Button } from 'react-bootstrap'
import axios from 'axios'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../content/sites.css'
import { TimerMessage } from '../components/TimerMessage'
import { FaVoteYea, FaTelegram, FaFacebook, FaInstagramSquare, FaRegEdit } from 'react-icons/fa'
import {
  FaRegEnvelope,
  FaSquareXTwitter,
  FaRegTrashCan,
} from 'react-icons/fa6'
import { TiPencil } from 'react-icons/ti'
import { IoArrowUndoOutline } from 'react-icons/io5'
import noPhotos from '../img/shop/nofotos.jpg'
import Swal from 'sweetalert2'

const URI = `${process.env.REACT_APP_BACKEND}`

const SitesIn = () => {
  const { texts } = useContext(LanguageContext)
  const { currentUser } = useContext(AuthContext)
  const firstDiv = useRef()
  const [evalYes, setEvalYes] = useState(false)
  const [image, setImage] = useState('')
  const vuser = currentUser?.dataValues.userName
  const vuseraccess = currentUser?.dataValues.access != null ? currentUser?.dataValues.access : ''
  useEffect(() => {
    AOS.init()
    firstDiv.current.focus()
  }, [])

  const Mailto = ({ email, subject, body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  
 
  const { id } = useParams()

  const [item, setItem] = useState([])

  const navigate = useNavigate()

  const getSiteById = async () => {
    const response = await axios.get(URI + 'site/' + id)
    setItem(response.data)
    if (response.data.sitesPicId !==0 && response.data.sitesPicId != null)
          axios
              .get(URI+'images/'+response.data.sitesPicId)
              .then((res) => {   
                setImage(URI+res.data)
              })
              .catch(err=> {
                setImage(noPhotos)
              })
    else
    setImage(noPhotos)
    
  }
  
  useEffect(() => {
    getSiteById()
    //eslint-disable-next-line
  }, [])

  const handleEval = () => {
    setEvalYes(true)
  }
  const handleWhere = () => {
    navigate('/sites/1/0')
  }
  const siteAdd = async (e) => {
    navigate(`/sites/siteadd`)
  }

  const siteUpdate = () => {
    navigate(`/sites/siteupd/${id}`)
  }

  const siteDelete = () => {
    {vuseraccess === 'admin' ?
        Swal.fire({
          title: 'Esta seguro de Eliminar el Sitio ?',
          text: 'No podra opinar sobre él una vez borrado!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, eliminelo!',
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              // deleting sale in sales table
              axios.delete(URI + 'site/' + id)
              Swal.fire({
                title: 'Eliminado!',
                text: 'Sitio Web para Comentar',
                icon: 'success',
              })
              navigate('/sites/1/0')
            } catch (error) {
              Swal.fire({
                title: 'No fue posible eliminar el sitio!',
                text: 'Sitio Web para Comentar',
                icon: 'warning',
              })
            }
          }
        })
      : 
      Swal.fire({
        title: 'Esta seguro de Eliminar el Sitio ?',
        text: 'Por el momento solo será marcado para no ser Publicado!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminelo!',
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            item.sitesPublished = false
            axios.put(URI + 'site/' + id, item)
            Swal.fire({
              title: 'Eliminada su Publicación!',
              text: 'Sitio Web para Comentar',
              icon: 'success',
            })
            navigate('/sites/1/0')
          } catch (error) {
            Swal.fire({
              title: 'No fue posible eliminar el sitio!',
              text: 'Sitio Web para Comentar',
              icon: 'warning',
            })
          }
        }
      })
    }
  }

  return (
    <div className="sitesin center-class" tabIndex={-1} ref={firstDiv}>
      <Container className="ms-4 me-4 mb-4 p-1">
        <div lg={12} className="section-title" data-aos="fade-right">
          <h2>{texts.siEvalTit}</h2>
          <p style={{ fontFamily: 'fantasy' }}>{texts.siEvalSubt}</p>
        </div>
        
        {(item.userId === currentUser?.dataValues.id || vuseraccess === 'admin') && (
              <div style={{textAlign:'center',marginBottom:'10' }}>
                <Button
                  className="me-3 button-shadow"
                  size="sm"
                  type="none"
                  onClick={siteAdd}
                  variant="warning"
                  title={texts.siNewTitle}
                >
                  <div style={{ color: 'ffffff', display: 'flex' }}>
                    <FaRegEdit style={{ fontSize: '1.2rem' }} />
                    {texts.siNew}
                  </div>
                </Button>
                <Button
                  className="me-3 button-shadow"
                  size="sm"
                  onClick={siteUpdate}
                  variant="warning"
                  title={texts.siUpdTitle}
                >
                  <div style={{ color: 'ffffff', display: 'flex' }}>
                    <TiPencil style={{ fontSize: '1.2rem' }} />
                    {texts.siUpd}
                  </div>
                </Button>
               
                  <Button
                    className="me-3 button-shadow"
                    size="sm"
                    onClick={() => siteDelete()}
                    variant="danger"
                    title={texts.siDelTitle}
                  >
                    <div style={{ color: 'ffffff', display: 'flex' }}>
                      <FaRegTrashCan style={{ fontSize: '1.2rem' }} />
                      {texts.seDel}
                    </div>
                  </Button>
                
              </div>
            )} 
        <div className="d-flex mb-1 justify-content-center">
          <Button 
           size="sm" 
           onClick={handleWhere}
           variant="link"
           className='ioarrow'>
           
              <IoArrowUndoOutline
                style={{
                  fontSize: '1rem',
                  color: '#641e02',
                }}
                title={texts.backPage}
              />

          </Button>
          <div className="service-title" style={{paddingTop:'4'}}>
            {item.sitesName}
          </div>
             
         

        </div>
        <Row>
          <Col lg={4} md={12} sm={12} xs={12}>
            <Row>
              <Col>
                <Link to={item.sitesUrl} target="_blank">
                  <Card
                    className="m-2 sites-card  card-shadow"
                    data-aos="zoom-in"
                    data-aos-delay="10"
                    style={{ width: '100%', hight: '100%' }}
                  >
                    <Card.Header className="sites-cheader ">
                      <h1>{item.sitesName}</h1>
                      <p>{texts.idiom === 'es' ? item.sitesSubt : item.sitesSubtEn}</p>
                    </Card.Header>
                    <Card.Img
                      variant="top"
                      src={image}
                      className="sites-pic"
                    />
                  </Card>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={12} sm={12} xs={12}>
            <Row>
              <Col>
                {evalYes &&
                  (!vuser ? (
                    <TimerMessage
                      mess={
                        texts.evalRegis
                      }
                      variant={'danger'}
                      navig={'close'}
                      timetogo="2000"
                    />
                  ) : (
                    <span>
                      <GetRating
                        propsType="site"
                        propsId={id}
                        propsUser={vuser}
                        navig={'/sites/1/0'}
                      />
                    </span>
                  ))}

                <Card className="sites-cheader m-2 p-2 ">
                  <h5>{texts.siAbout}: </h5>
                  <h2>
                    {texts.idiom === 'es' ?
                      item.sitesText != null
                      ? item.sitesText
                      : texts.siNoInfo
                     :
                      item.sitesTextEn != null
                      ? item.sitesTextEn
                      : texts.siNoInfo
                    }  
                  </h2>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="center-class">
                <span className="sites-label">{texts.siUrl}:</span>
                <span className="sites-text"> <Link to={item.sitesUrl} target="_blank">{item.sitesUrl} </Link></span>
              </Col>
            </Row>
            {item.sitesMail &&
            <Row>
              <Col className="center-class">
                <span className="sites-label"><FaRegEnvelope/>{' '}{texts.siEmail}:</span>
                <span className="sites-text">
                    <Mailto email={item.sitesMail}  subject='' body=''>{item.sitesMail} </Mailto> 
                  
                </span>
              </Col>
            </Row>
            }
            <Row>
                  <Col>
                    

                    <div className="site-social-links mt-2 center">
                     {item.sitesFacebook &&
                        <Link to={item.sitesFacebook} target="_blank" className='me-2'>
                          <FaFacebook />
                        </Link>
                      }
                      {item.sitesInstagram &&
                        <Link to={item.sitesInstagram} target="_blank" className='me-2'>
                          <FaInstagramSquare />
                        </Link>
                      }
                      {item.sitesX &&
                        <Link to={item.sitesX} target="_blank" className='me-2'>
                          <FaSquareXTwitter />
                        </Link>
                      }
                      {item.sitesTelegram &&  
                        <Link to={item.sitesTelegram} target="_blank">
                          <FaTelegram />
                        </Link>
                      }
                    </div>
                  </Col>
                </Row>
            <Row className="mt-3">
              <Col className="center-class">
                {!evalYes && (
                  <Button variant="info" onClick={handleEval}>
                    <div className="sites-label">
                      <span>
                        {texts.siComment} &nbsp; <FaVoteYea />
                      </span>
                    </div>
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row data-aos="fade-left" className="mt-5">
          <Col>
            <span className="service-title">{texts.siOpinion}</span>
            <p>
              {' '}
              <GetRatingTotal
                propsType="site"
                propsId={id}
                yesComment={true}
                onlyStars={false}
                withEvals={false}
              />
            </p>
          </Col>
        </Row>
        {/*
        <Row data-aos="fade-left" className="mt-5">
          <Col>
            <span className="service-title">{texts.siOpinion}</span>
            <p>
              {' '}
              <GetRatingTotal
                propsType="site"
                propsId={id}
                yesComment={true}
                onlyStars={false}
                withEvals={false}
              />
            </p>
          </Col>
        </Row>
        */}
      </Container>
    </div>
  )
}

export default SitesIn

/*
 <div className='offer-radiob' data-aos="fade-left">
                  <div className='p-4' >Hola <b>{vuserName}</b>!, desea ofertar </div>
                        <div class="form-check p-4">
                          <input 
                            class="form-check-input" 
                            type="radio" 
                            name="rbdef"
                            value="1"
                            id="rbp"
                            checked={category == 1 ? true: false}
                            onChange={changeCategory}
                          />
                          <label class="form-check-label" for="rbp">
                            Un Producto?
                          </label>
                        </div>
                        <div class="form-check">
                          <input 
                            class="form-check-input" 
                            type="radio" 
                            name="rbdef"
                            value="0"
                            id="rbs"
                            checked={category == 0 ? true: false}
                            onChange={changeCategory}
                          />
                          <label class="form-check-label" for="rbs">
                            Un Servicio?
                          </label>
                        </div>
                  </div>
*/
