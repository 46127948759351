import axios from 'axios'
import { useState, useEffect } from 'react' //hooks, gestiona estado de los componentes funcionales
import { Link, useNavigate } from 'react-router-dom'
import '../admin.css'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { Button, Badge, Row, Container, Col, Table } from 'react-bootstrap'

const URI = `${process.env.REACT_APP_BACKEND}`

// configurating hooks
const AllCities = () => {
  const [cities, setCities] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getCities()
  }, [])

  const getCities = async () => {
    const res = await axios.get(URI + 'city')
    setCities(res.data)
  }

  const deleteCity = async (id) => {
    await axios.delete(`${URI}city/${id}`)
    getCities()
  }

  const handleWhere = () => {
    navigate('/auth/userOptions')
  }

  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" onClick={handleWhere} variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Cities
          </h5>
          <Link to="/admin/city/add" className="btn btn-primary mt-2 mb-2">
            Adicionar{' '}
            <i className="bi bi-plus-circle-fill" aria-hidden="true"></i>
          </Link>
          <Table className="table">
            <thead className="table-primary">
              <tr>
                <th>Country</th>
                <th>City</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {cities.map((city) => (
                <tr key={city.id}>
                  <td> {city.country.countryName} </td>
                  <td> {city.cityName} </td>
                  <td>
                    <Link
                      to={`../admin/city/edit/${city.id}`}
                      className="btn btn-info"
                    >
                      <FaRegEdit />
                    </Link>
                    <Button
                      onClick={() => deleteCity(city.id)}
                      className="btn btn-danger"
                    >
                      <FaRegTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default AllCities
