import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import '../admin.css'

import { Form, Container, Row, Col, Image } from 'react-bootstrap'
import sp from '../../img/navbar/spanish.png'
import en from '../../img/navbar/english.png'
import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`

const EditSite = () => {
  const [form, setForm] = useState([])
  const navigate = useNavigate()
  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }
  const { id } = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.put(URI + 'site/' + id, form)
    Swal.fire({
      title: 'Site actualizado satisfactoriamente!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/site/all')
  }

  useEffect(() => {
    getSiteById()
     //eslint-disable-next-line
  }, [])

  const getSiteById = async () => {
    const res = await axios.get(URI + 'site/' + id)
    setForm(res.data)
  }

  const doNothing = (e) => {
    e.preventDefault()
  }

  return (
    <div>

      <Container className="container-elements">
        <Row>
          <Col className="col offset-sm-1">
            <div className="header-elements">Actualizando datos del sitio</div>
            <Form onSubmit={doNothing}>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Nombre del Sitio:
                </label>
                <div className="col-sm-1">
                  <input
                    name="sitesName"
                    value={form.sitesName}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Subtitulo del Sitio:
                </label>
                <div className="col-sm-1">
                  <Image
                    src={sp} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <input
                    name="sitesSubt"
                    value={form.sitesSubt}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
                
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  
                </label>
                
                <div className="col-sm-1">
                  <Image
                    src={en} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <input
                    name="sitesSubtEn"
                    value={form.sitesSubtEn}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Descripcion:</label>
                <div className="col-sm-6">
                  <Image
                    src={sp} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <textarea
                    name="sitesText"
                    value={form.sitesText}
                    onChange={handleChange}
                    rows="3"
                    className="form-control"
                  />
                </div>
               
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Descripcion:</label>
                <div className="col-sm-6">
                  <Image
                    src={encodeURI} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <textarea
                    name="sitesTextEn"
                    value={form.sitesTextEn}
                    onChange={handleChange}
                    rows="3"
                    className="form-control"
                  />
                </div>
               
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">URL:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesUrl"
                    value={form.sitesUrl}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Correo Electronico:
                </label>
                <div className="col-sm-6">
                  <input
                    name="sitesMail"
                    value={form.sitesMail}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Facebook:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesFacebook"
                    value={form.sitesFacebook}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Instagram:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesInstagram"
                    value={form.sitesInstagram}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Telegram:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesTelegram"
                    value={form.sitesTelegram}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">X(Twiter):</label>
                <div className="col-sm-6">
                  <input
                    name="sitesX"
                    value={form.sitesX}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Orden:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesOrder"
                    value={form.sitesOrder}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Imagen:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesPic"
                    value={form.sitesPic}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Publicado?:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesPublished"
                    value={form.sitesPublished}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 ">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary mt-2 mb-2"
                  >
                    Actualizar
                    <FaRegCheckCircle />
                  </button>
                  <Link
                    to="/admin/site/all"
                    className="btn btn-danger mt-2 mb-2"
                  >
                    Cancelar
                    <IoCloseCircleOutline />
                  </Link>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EditSite
