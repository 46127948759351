import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Badge, Form, Container, Row, Col } from 'react-bootstrap'
import '../admin.css'
import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoArrowUndoOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`

const AddCountry = () => {
  const [form, setForm] = useState([])
  // const [vcountryId, setCountryId] = useState('')
  // const [vcountryName, setCountryName] = useState('')
  // const [vcountryActive, setCountryActive] = useState('true')

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(URI + 'country/', form)
    Swal.fire({
      title: 'Country added!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/country/all')
  }

  const doNothing = (e) => {
    e.preventDefault()
  }

  return (
    <div>
      <Container className="container-elements">
        <Row>
          <Col lg={12}>
            <h5>
              <Button size="sm" href="/admin/country/all" variant="link">
                <Badge bg="light" className="button-shadow">
                  <IoArrowUndoOutline
                    style={{
                      fontSize: '1rem',
                      color: '#641e02',
                    }}
                    title="DASHBOARD..."
                  />
                </Badge>
              </Button>
              Adding a Country
            </h5>
            <Form onSubmit={doNothing}>
              <Form.Group>
                <Form.Label>Phone Code</Form.Label>
                <Form.Control
                  name="countryId"
                  className="service-title"
                  value={form.countryId}
                  onChange={handleChange}
                  type="text"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Country Name</Form.Label>
                <Form.Control
                  name="countryName"
                  className="service-title"
                  value={form.countryName}
                  onChange={handleChange}
                  type="text"
                />
              </Form.Group>
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    className="mt-2 mb-2"
                  >
                    Save
                    <FaRegCheckCircle />
                  </Button>
                  <Button
                    variant="danger"
                    href="/admin/country/all"
                    className="mt-2 mb-2"
                  >
                    Cancel
                    <IoCloseCircleOutline />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddCountry
