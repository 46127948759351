import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap'
import { TimerMessage } from '../components/TimerMessage'

const URI = `${process.env.REACT_APP_BACKEND}`

const OTPRequest = () => {
  const {texts} = useContext(LanguageContext)
  const { email, otp , setShowWhat} = useContext(AuthContext)
  const [timerCount, setTimer] = useState(60)
  const [OTPinput, setOTPinput] = useState('')
  const [sendCode, setSendCode] = useState(false)
  const [disable, setDisable] = useState(true)
  const [mess, setMess] = useState('')
  const [variant, setVariant] = useState('')

  const resendOTP = () => {
  //  console.log('entre a resendOTP')
    if (disable) return
     axios
     .post(URI + 'nodemailer', {
      OTP: otp,
      gmail: email,
      name: texts.auEmailTo,
      subject: texts.auEmailSubj,
      message: texts.auEmailMess,      
      })
      .then(() => setDisable(true))
      .then(() =>  setMess(texts.auNewOtp))           
      .then(() => setTimer(60))
      .catch(console.log)
  }

  const verfiyOTP = () => {

   if (parseInt(OTPinput) === otp) {
      setMess(texts.auOtpAccept)
      setVariant('warning')
      setSendCode(true)
      setShowWhat('schange')   
    }
    setMess(texts.auOtpNotAccept)
    setVariant('danger')
    return
  }

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval)
        if (lastTimerCount <= 1) setDisable(false)
        if (lastTimerCount <= 0) return lastTimerCount
        return lastTimerCount - 1
      })
    }, 1000) //each count lasts for a second
    return () => clearInterval(interval)
  }, [disable])
  const doNothing = (e) => {
    e.preventDefault()
  }

  return (
    <div>
      <p className='auth-advice-yellow' style={{textAlign:'start'}} >{texts.auOtpUse}</p>
        <Form onSubmit={doNothing}>
          <Row className='mb-2'>
            <Col className="opt-body">
              <input
                maxLength="4"
                type="text"
                name=""
                className="otp-number mb-5"
                id=""
                disabled={disable ? false : true}
                onChange={(e) => {
                  setOTPinput(e.target.value)
                }}
                autoFocus
              ></input>
            </Col>
          </Row>
          <Row className='mb-10'>
            <Col>
              <Button
                variant="primary"
                onClick={verfiyOTP}
                style={{ fontSize: '0.8rem' }}
              >
                {texts.auOtpVerify}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="link"
                onClick={resendOTP()}
                style={{ fontSize: '0.8rem'}}
              >
                {texts.auOtpNotReceived}
                {disable
                  ? `${texts.auOtpResendUntil}:${timerCount}s`
                  : texts.auOtpResend}
              </Button>
            </Col>
          </Row>
        </Form>
              
                {!sendCode && (
                  <TimerMessage mess={mess} variant={variant} timetogo="2000" />
                )}

    </div>

  )   
}

export default OTPRequest
