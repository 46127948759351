import React, { useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import SearchContext from '../context/SearchContext'
import LanguageContext from '../context/LanguageContext'
import { Navbar, Container, Nav, Image, Form, Row, Col, Modal, Button } from 'react-bootstrap'
import logo from '../img/logo/logo.png'
import { motion } from 'framer-motion'
import donation from '../img/navbar/donation.png'
import spa from '../img/navbar/spanish.png'
import eng from '../img/navbar/english.png'
import usr from '../img/navbar/user4.png'
import {
  FaBars,
  FaStreetView,
  FaHome,
} from 'react-icons/fa'
import { SiJsonwebtokens } from 'react-icons/si'
import { FaMagnifyingGlass, FaRegCircleUser, FaCircleUser, FaHandHoldingHeart, FaCheck} from 'react-icons/fa6'
import { IoCloseSharp } from 'react-icons/io5'
import { BsBagHeart } from "react-icons/bs"
import { MdOutlineManageAccounts } from "react-icons/md";
import './header.css'
import { useWidth } from '../hooks/useWidth'

const Header = () => {
  const { width } = useWidth()
  const { currentUser,setCurrentUser, logout} = useContext(AuthContext)
  const { search, handleSearch } = useContext(SearchContext)
  const { texts, handleLanguage} = useContext(LanguageContext)
  const [sureLogout, setSureLogout] = useState(false) 
  const navigate = useNavigate()

  useEffect(() => {
   
    if (currentUser)
      {
        handleLanguage(currentUser?.dataValues.userLang)
      }
      else
      {
        handleLanguage('es')        
      }
      //eslint-disable-next-line
  }, [])

  const toggleLang = () => {
    
    if (currentUser)
      {
          handleLanguage(texts.idiom==='es'?'en':'es')
          setCurrentUser({
            ...currentUser, // Copia otros campos
            dataValues: {
              ...currentUser.dataValues,
            userLang: texts.idiom==='es'?'en':'es'
            }
          });
         
      }
      else
      { 
        handleLanguage(texts.idiom==='es'?'en':'es')
      }
   }

   const handlelogout = () => {
    setSureLogout(false)
    logout(currentUser?.dataValues.id,texts.lang,currentUser?.dataValues.currencyId)
    navigate('/home')
  }

  const [collapsed, setCollapsed] = useState(true)

  return (
    <Container fluid>

        <div>          
          <Row>
            <Col>
              <Navbar fixed="top" expand="lg">
                <Navbar.Brand href="/home">
                <Image 
                  src={logo} 
                  alt={texts.title}
                  title={texts.title}
                   height= '50px'
                  />
                </Navbar.Brand>  
                <Navbar.Text>
                  <div className='d-flex justify-content-flex-end'>
                  {width > 991 &&
                  <Form className="d-flex form-search">
                    <Form.Control
                      name="searchId"
                      type="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder={texts.heSearch}
                      className="search-form-text "
                      aria-label="Search"
                      style={{width:'500px' }}
                    />
                    <span className="icon-inside-search">
                      <FaMagnifyingGlass />
                    </span>
                  </Form>
                  }
                  <Nav.Link  className="me-1 navlink-magnify ">                   
                      {texts.idiom === 'es' ? (
                        <img
                         onClick={toggleLang}
                          src={spa}
                          alt=""
                          title="Idioma"
                          className="navicon"
                        />
                      ) : (
                        <img
                        onClick={toggleLang}
                          src={eng}
                          alt=""
                          title="Idioma"
                          className="navicon"
                        />
                      )}
                
                  </Nav.Link>
                  <Nav.Link href="/donation" className="me-1 navlink-magnify ">
                    
                      <img
                        src={donation}
                        alt=""
                        title="Donate, help us to stay here!"
                        className="navicon"
                      />

                  </Nav.Link>
                  {currentUser ? (
                   
                   <Nav.Link   className="navuser navlink-magnify ">
                     <div className="d-flex" style={{marginRight:'20px'}} onClick= {()=>setSureLogout(true)}>
                       
                         <img
                           src={usr}
                           alt=""
                           title={texts.auUserClose}
                           className="navicon navlink-magnify "
                           style={{
                             fontSize: '0.8rem',
                             marginBottom: '3px',
                           }}
                         />
                          
                              <span className="navuser" >
                                &nbsp;{width > 991 ? `Hi, ${currentUser?.dataValues.userName.substring(0, 15)} ! ` : <FaCheck style={{fontSize:'0.6rem'}}/>}
                              </span>
                          
                         
                         
  
                     </div>
                   </Nav.Link>
                 ) : (
                   <Nav.Link href="/auth/login/0/0">
                     
                       <img
                         src={usr}
                         alt=""
                         title={texts.auUserLogin}
                         className="navicon navlink-magnify "
                         style={{ fontSize: '0.8rem', marginBottom: '3px'}}
                       />
                     
                   </Nav.Link>
                 )}
                  
                  </div>
                </Navbar.Text>          
              </Navbar>
            </Col>
          </Row>
          {width < 991 &&
          <Row>
              <Col>
                <Navbar
                  fixed="top"
                  className="navbar2"
                  
                  style={{color: '#fff'}}
                >
                 
                  <Form className="d-flex form-search">
                    <Form.Control
                      name="searchId"
                      type="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder={texts.heSearch}
                      className="search-form-text "
                      aria-label="Search"
                    />
                    <span className="icon-inside-search">
                      <FaMagnifyingGlass />
                    </span>
                  </Form>
                  
                </Navbar>
                
              </Col>
          </Row>
          }
          <Row>
            <Col>
              <Navbar fixed="top" className="navbar1">
              
                <Nav>
                  <Nav.Link href="/home">
                    <div className="navlink-min">
                      <FaHome />
                      &nbsp;{texts.heHome}
                    </div>
                  </Nav.Link>
                  <Nav.Link href={`/sites/1/0`}>
                    <div className="navlink-min">
                      <SiJsonwebtokens />
                      &nbsp;{texts.heSites}
                    </div>
                  </Nav.Link>

                  <Nav.Link href="/shop/shophome/1/0">
                    <div className="navlink-min">
                      <FaStreetView />
                      &nbsp;{texts.heServices}
                    </div>
                  </Nav.Link>
                  <Nav.Link href="/arts">
                    <div className="navlink-min">
                    <BsBagHeart />
                    &nbsp;{texts.heCubanCrafts}
                    </div>
                  </Nav.Link>
                  {currentUser &&
                  <Nav.Link href="/auth/userOptions">
                    <div className="navlink-min">
                    <MdOutlineManageAccounts />
                    &nbsp;{texts.heMyAccount}
                    </div>
                  </Nav.Link>
                  }
                 

                 
                 
                </Nav>
              </Navbar>
            </Col>
          </Row>

        </div>


       <Modal centered size='sm' show={sureLogout} onHide={()=>setSureLogout(false)} animation={false}>
                     
                     <Modal.Header className='modal-user'>{texts.userCloseQuestion}</Modal.Header>
                     <Modal.Footer className='modal-user'>
                       <Button variant="warning" onClick={()=>setSureLogout(false)}>
                       {texts.userCloseNo}
                       </Button>
                       <Button variant="secondary" onClick={handlelogout}>
                         {texts.userCloseYes}
                       </Button>
                      
                     </Modal.Footer>
          </Modal>
    </Container>
  )
}

export default Header