import axios from 'axios'
import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../admin.css'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { Button, Badge, Row, Container, Col, Card } from 'react-bootstrap'

const URI = `${process.env.REACT_APP_BACKEND}`

// configurating hooks
const AllTheme = () => {
  const [theme, setTheme] = useState([])

  const navigate = useNavigate()

  //procedure to show all countriess
  const getTheme = async () => {
    const res = await axios.get(URI + 'theme')
    setTheme(res.data)
    console.log(res.data)
  }

  useEffect(() => {
    getTheme()
  }, [])
  // procedure to delete a countries
  const deleteTheme = async (id) => {
    await axios.delete(`${URI}theme/${id}`)
    getTheme()
  }

  const handleWhere = () => {
    navigate('/auth/userOptions')
  }

  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" onClick={handleWhere} variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Themes
          </h5>
          <Link to="/admin/theme/add" className="btn btn-primary mt-2 mb-2">
            Add <i className="bi bi-plus-circle-fill" aria-hidden="true"></i>
          </Link>
          {theme.map((theme) => (
            <div key={theme.id}>
              <Card>
                <Card.Header>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Active Theme:
                    </Col>
                    <Col>{theme.themesActive === 1 ? 'Yes' : 'No'}</Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Theme's Text:
                    </Col>
                    <Col>{theme.themesText}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Background:
                    </Col>
                    <Col>{theme.themesBack}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Image Nr.1:
                    </Col>
                    <Col>{theme.themesImg1}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Url Nr.1:
                    </Col>
                    <Col>{theme.themesUrl1}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      External Url Nr.1:
                    </Col>
                    <Col>{theme.themesUrlExt1}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Image Nr.2:
                    </Col>
                    <Col>{theme.themesImg2}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Url Nr.2:
                    </Col>
                    <Col>{theme.themesUrl2}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      External Url Nr.2:
                    </Col>
                    <Col>{theme.themesUrlExt2}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Image Nr.3:
                    </Col>
                    <Col>{theme.themesImg3}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      Url Nr.3:
                    </Col>
                    <Col>{theme.themesUrl3}</Col>
                  </Row>
                  <Row>
                    <Col xs={2} className="card-column1">
                      External Url Nr.3:
                    </Col>
                    <Col>{theme.themesUrlExt3}</Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col xs={2} className="card-column1"></Col>
                    <Col>
                      <Button
                        href={`/admin/theme/edit/${theme.id}`}
                        variant="info"
                      >
                        <FaRegEdit />
                      </Button>
                      <Button
                        onClick={() => deleteTheme(theme.id)}
                        variant="danger"
                      >
                        <FaRegTrashAlt />
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}
export default AllTheme
