import axios from 'axios'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import '../admin.css'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import { Form } from 'react-bootstrap'

const URI = `${process.env.REACT_APP_BACKEND}`

// configurating hooks
const AllCarousel = () => {
  const [carousel, setCarousel] = useState([])
  const [carouselId, setCarouselId] = useState(0)

  const handleSelectCarousel= (e) => {
   
    if (e.target.value !== 0 ) 
      {
        setCarouselId(e.target.value)
        getCarousel(e.target.value)
      }
  }
 
   //procedure to show all countriess
   const getCarousel = async (caroId) => {
    const res = await axios.get(`${URI}carousel/caroid/${caroId}`)
    setCarousel(res.data)
    console.log(res.data)
  }

   // procedure to delete a countries
   const deleteCarousel = async (id) => {
    await axios.delete(`${URI}carousel/${id}`)
    getCarousel()
  }



  return (
    <div>
      <div className="container-elements">
        <div className="row">
          <div className="col offset-sm-1">
            <h5>CAROUSEL MANAGEMENT</h5>
              <div className='d-flex'>               
                  <Form.Select
                    label='Tipo de Carousel'
                    name="caroselId"
                    className="service-city"
                    onChange={handleSelectCarousel}
                    value={carouselId}                  >
                    <option value="0" selected>
                      Select Carousel
                    </option>
                    <option value="1" selected>
                      Carousel Places
                    </option>
                    <option value="2" selected>
                      Carousel Services
                    </option>
                    <option value="3" selected>
                      Carousel News
                    </option>                 
                  </Form.Select>
                  {carouselId !== 0 &&
                  <Link
                  to={`../admin/carousel/add/${carouselId}`} 
                  className="btn btn-primary">
                    Adicionar{' '}
                    <i className="bi bi-plus-circle-fill" aria-hidden="true"></i>
                  </Link>
                }                
              </div>
            <table className="table">
              <thead className="table-primary">
                <tr>
                  <th>Cod</th>
                  <th>Imagen Activa</th>
                  <th>Imagen</th>
                  <th>Imagen Chica</th>
                  <th>Nombre de la Imagen</th>
                  <th>Lugar de la Imagen</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {carousel.map((caro) => (
                  <tr key={caro.id}>
                    <td> {caro.carouselId} </td>
                    <td> {caro.imgActive ? "Yes" : "No"} </td>
                    <td> {caro.img} </td>
                    <td> {caro.sImg} </td>
                    <td> {caro.imgName} </td>
                    <td> {caro.imgPlace} </td>
                    <td>
                      <Link
                        to={`../admin/carousel/edit/${caro.id}`}
                        className="btn btn-info"
                      >
                        <FaRegEdit />
                      </Link>
                      <button
                        onClick={() => deleteCarousel(caro.id)}
                        className="btn btn-danger"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AllCarousel
