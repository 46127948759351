import { Routes, Route, Navigate } from 'react-router-dom'

// import content
import Home from '../content/Home'
import Arts from '../content/Arts'
import Amazing from '../content/Amazing'
import AboutUs from '../content/AboutUs'
import Sites from '../content/Sites'
import SitesIn from '../content/SitesIn'
import SiteAdd from '../content/SiteAdd'
import SiteUpd from '../content/SiteUpd'
import Donation from '../content/Donation'
import Success from '../payment/Success'
import Cancel from '../payment/Cancel'

// importing authentification
//import Auth from '../authentication/Auth'
import Login from '../authentication/Login'
import UserOptions from '../authentication/UserOptions'
import Profile from '../authentication/Profile'
import Registration from '../authentication/Registration'
import Conditions from '../authentication/Conditions'
import PwdChange from '../authentication/PwdChange'
import OTPRequest from '../authentication/OTPRequest'

import ShopHome from '../shop/ShopHome'
import ServiceUpd from '../shop/ServiceUpd'
import ServiceShow from '../shop/ServiceShow'
import ServiceAdd from '../shop/ServiceAdd'
import ServiceEval from '../shop/ServiceEval'
import CompShowCountries from '../admin/countries/AllCountries'
import CompCreateCountry from '../admin/countries/AddCountry'
import CompEditCountry from '../admin/countries/EditCountry'
import AllCities from '../admin/cities/AllCities'
import AddCity from '../admin/cities/AddCity'
import EditCity from '../admin/cities/EditCity'
import AllCarousel from '../admin/carousel/AllCarousel'
import AddCarousel from '../admin/carousel/AddCarousel'
import EditCarousel from '../admin/carousel/EditCarousel'
import AllTheme from '../admin/themes/AllTheme'
import AddTheme from '../admin/themes/AddTheme'
import EditTheme from '../admin/themes/EditTheme'

import AllSites from '../admin/site/AllSites'
import AddSite from '../admin/site/AddSite'
import EditSite from '../admin/site/EditSite'

import AllDiary from '../admin/diary/AllDiary'
import AddDiary from '../admin/diary/AddDiary'
import EditDiary from '../admin/diary/EditDiary'

import CompShowType from '../admin/type/AllType'
import CompCreateType from '../admin/type/AddType'
import CompEditType from '../admin/type/EditType'

//import Images from '../components/Images'

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="home" />} />
      <Route path="home" element={<Home />} />
      <Route path="sites/:published/:user" element={<Sites />} />
      <Route path="sites/sitesin/:id" element={<SitesIn />} />
      <Route path="sites/siteupd/:id" element={<SiteUpd />} />
      <Route path="sites/siteadd" element={<SiteAdd />} />
      <Route path="arts" element={<Arts />} />
      <Route path="amazing" element={<Amazing />} />
      <Route path="aboutus" element={<AboutUs />} />
      <Route path="donation" element={<Donation />} />
      <Route path="success" element={<Success />} />
      <Route path="cancel" element={<Cancel />} />

      <Route path="/auth/profile" element={<Profile />} />
      <Route path="/auth/login/:showtext/:from" element={<Login />} /> {/*showtext = 0 no muestra mensaje, 1 lo muestra, from = 1 el mesaje es de sitio, sino es de servicio*/}
      <Route path="/auth/userOptions" element={<UserOptions />} />
      <Route path="/auth/registration" element={<Registration />} />
      <Route path="/conditions" element={<Conditions />} />
      <Route path="/auth/pwdchange" element={<PwdChange />} />
      <Route path="/auth/otprequest" element={<OTPRequest />} />
      <Route path="/shop/shophome/:published/:user" element={<ShopHome />} />
      <Route path="/shop/shophome/serviceupd/:id" element={<ServiceUpd />} />
      <Route path="/shop/shophome/serviceshow/:id" element={<ServiceShow />} />
      <Route path="/shop/shophome/serviceval/:id" element={<ServiceEval />} />
      <Route path="/shop/shophome/serviceadd" element={<ServiceAdd />} />

      <Route path="/admin/country/all" element={<CompShowCountries />} />
      <Route path="/admin/country/add" element={<CompCreateCountry />} />
      <Route path="/admin/country/edit/:id" element={<CompEditCountry />} />

      <Route path="/admin/carousel/all" element={<AllCarousel />} />
      <Route path="/admin/carousel/add/:id" element={<AddCarousel />} />
      <Route path="/admin/carousel/edit/:id" element={<EditCarousel />} />

      <Route path="/admin/theme/all" element={<AllTheme />} />
      <Route path="/admin/theme/add" element={<AddTheme />} />
      <Route path="/admin/theme/edit/:id" element={<EditTheme />} />

      <Route path="/admin/city/all" element={<AllCities />} />
      <Route path="/admin/city/add" element={<AddCity />} />
      <Route path="/admin/city/edit/:id" element={<EditCity />} />

      <Route path="/admin/site/all" element={<AllSites />} />
      <Route path="/admin/site/add" element={<AddSite />} />
      <Route path="/admin/site/edit/:id" element={<EditSite />} />

      <Route path="/admin/type/all" element={<CompShowType />} />
      <Route path="/admin/type/add" element={<CompCreateType />} />
      <Route path="/admin/type/edit/:id" element={<CompEditType />} />

      <Route path="/admin/diary/all" element={<AllDiary />} />
      <Route path="/admin/diary/add" element={<AddDiary />} />
      <Route path="/admin/diary/edit/:id" element={<EditDiary />} />

    </Routes>
  )
}

export default Routers
