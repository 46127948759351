import React, { useState, useEffect,useContext } from 'react'
import { Card } from 'react-bootstrap'
import { FaRegEnvelope, FaMobileScreen } from 'react-icons/fa6'
import axios from 'axios'
import { Link } from 'react-router-dom'
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css'
import noPhotos from '../img/shop/nofotos.jpg'
import GetRatingTotal from '../components/GetRatingTotal.js'
import LanguageContext from '../context/LanguageContext'

const URI = `${process.env.REACT_APP_BACKEND}`

const ServiceCard = ({ item }) => {
  console.log('items')
  console.log(item.id)
  const { texts } = useContext(LanguageContext)
  //const URI = `${process.env.REACT_APP_BACKEND}`
  const [vSumTotal, setVSumTotal] = useState(0)
  const [vEvals, setVEvals] = useState(0)

  const getShopAverage = async () => {
    const res = await axios.get(URI + 'shopeval/oneId/' + item.id)
    if (res.data.length > 0) {
      setVEvals(res.data.length)
      setVSumTotal(
        Math.ceil(
          res.data.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.Eval) / res.data.length,
            0,
          ),
        ),
      )
    } else {
      setVEvals(0)
      setVSumTotal(0)
    }
  }

  // getting data from eval and comment
  useEffect(() => {
    getShopAverage()
     //eslint-disable-next-line
  }, [])

  const [image, setImage] = useState('')

  useEffect(() => {
    AOS.init()
    if ((item.imgs !== null) & (item.imgs !== '')) {
      let id = item.imgs.split(',')[0]
      axios
        .get(URI + 'images/' + id)
        .then((res) => {
          setImage(URI + res.data)
        })
        .catch((err) => {
          console.error(err)
          setImage(noPhotos)
        })
    } else {
      setImage(noPhotos)
    }
     //eslint-disable-next-line
  }, [])

  //modal
 // const [show, setShow] = useState(false)
 // const handleClose = () => setShow(false)
 // const handleShow = () => setShow(true)
 
  return (
    <>
      <Link to={`/shop/shophome/serviceshow/${item.id}`}>
        <div data-aos="zoom-in" data-aos-delay="1">
          <Card
            className="m-2 card-shadowy"
            key={item.id}
            style={{ width: '20rem', height: '30rem' }}
          >
            <Card.Header className="service-header">
              {texts.idiom === 'es' ? item.type.typeName : item.type.typeNameEn}
            </Card.Header>
            <Card.Img
              variant="top"
              src={image}
              className="service-img"
              style={{ width: '19.8rem', height: '13rem' }}
            />
            <Card.Body>
              <Card.Text>
                <div className="service-titlefront">{texts.idiom === 'es' ? (item.title != null ? item.title : item.titleEn) : (item.titleEn !=null ? item.titleEn : item.title)}</div>
                <div className="service-textfront">
                  {texts.idiom === 'es' ? (item.descrip !=null ?item.descrip.substring(0, 70):item.descripEn.substring(0, 70)):(item.descripEn != null ?item.descripEn.substring(0, 70): item.descrip.substring(0, 70))}...{' '}
                  <Link
                    className="service-link"
                    to={`/shop/shophome/serviceshow/${item.id}`}
                  >
                    [ver más]
                  </Link>{' '}
                </div>
                <div className="service-bolderfront">
                  {texts.idiom === 'es' ? (item.bolderText != null ? item.bolderText.substring(0, 70): item.bolderTextEn.substring(0, 70)) : (item.bolderTextEn != null ? item.bolderTextEn.substring(0, 70):item.bolderText.substring(0, 70)) }...
                </div>

                <div className="service-phonemail">
                  <FaMobileScreen />
                  &nbsp;
                  {item.contactPhone}&nbsp; <FaRegEnvelope />
                  &nbsp;
                  {item.contactMail}
                </div>
                <div className="service-city">
                  {item.city.cityName}, &nbsp;
                  {item.country.countryName}
                </div>
              </Card.Text>
            </Card.Body>
            <Card.Footer className="center-class">
              <p>
                <GetRatingTotal
                  propsType="serv"
                  propsId={item.id}
                  yesComment={false}
                  onlyStars={true}
                  withEval={true}
                />
                {/*<span style={{ textAlign: 'end' }}>
                  <Link
                    className="service-link"
                    to={`/shop/shophome/serviceval/${item.id}`}
                  >
                    [
                    <span style={{ background: '#f0f2f3' }}>
                      &nbsp;
                      <FaVoteYea />
                      &nbsp;Evaluar&nbsp;
                    </span>
                    ]
                  </Link>
                  </span>*/}
              </p>
            </Card.Footer>
          </Card>
        </div>
      </Link>
    </>
  )
}

export default ServiceCard
