import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate , useParams } from 'react-router-dom'
import { Button, Badge, Row, Container, Col, Form } from 'react-bootstrap'
import '../admin.css'
import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoArrowUndoOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`


const EditDiary = () => {
    const [form, setForm] = useState([])
    const navigate = useNavigate()
    const handleChange = (e) => {
      const { name, value } = e.target
      setForm({
        ...form,
        [name]: value,
      })
    }
    
   
    const { id } = useParams()
  
    const handleSubmit = async (e) => {
      e.preventDefault()
      await axios.put(URI + 'diary/' + id, form)
      Swal.fire({
        title: 'Sorpresa actualizada satisfactoriamente!',
        text: 'DashBoard',
        icon: 'success',
      })
      navigate('/admin/diary/all')
    }
  
    useEffect(() => {
      getDiaryById()
       //eslint-disable-next-line
    }, [])
  
    const getDiaryById = async () => {
      const res = await axios.get(URI + 'diary/' + id)
      setForm(res.data)
    }
  
    const doNothing = (e) => {
      e.preventDefault()
    }
    return (
      <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" href="/admin/city/all" variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Adding a Surprise
          </h5>
          <Form onSubmit={doNothing}>
            <Form.Group>
              <Form.Label>Pais</Form.Label>
              <Form.Select
                name="type"
                className="service-city"
                onChange={handleChange}
                value={form.type}
              >
                <option value="1" selected={form.type===1?true:false}>
                  Written
                </option>
                <option value="2" selected={form.type===2?true:false} >
                  Picture
                </option>
                <option value="3" selected={form.type===3?true:false}>
                  Video
                </option>
                
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Date(mesdiaaño)</Form.Label>
              <Form.Control
                name="dateId"
                className="service-title"
                value={form.dateId}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Surprise</Form.Label>
              <Form.Control
                name="diary"
                className="service-title"
                value={form.diary}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Procedencia</Form.Label>
              <Form.Select
                name="from"
                className="service-city"
                onChange={handleChange}
                value={form.from}
              >
                <option value="1" selected={form.from===1?true:false}>Cubanos</option>
                <option value="2" selected={form.from!==1?true:false}>Redes</option> 
              </Form.Select>
            </Form.Group>
            {form.type === 1 &&
            <Form.Group>
              <Form.Label>Surprise in English</Form.Label>
              <Form.Control
                name="diaryEn"
                className="service-title"
                value={form.diaryEn}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            }
            <Form.Group>
              <Form.Label>Details</Form.Label>
              <Form.Control
                name="diaryDet"
                className="service-title"
                value={form.diaryDet}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Details in English</Form.Label>
              <Form.Control
                name="diaryDetEn"
                className="service-title"
                value={form.diaryDetEn}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  className="mt-2 mb-2"
                >
                  Save
                  <FaRegCheckCircle />
                </Button>
                <Button
                  variant="danger"
                  href="/admin/diary/all"
                  className="mt-2 mb-2"
                >
                  Cancel
                  <IoCloseCircleOutline />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default EditDiary
