import axios from 'axios'
import { useState, useEffect } from 'react' //hooks, gestiona estado de los componentes funcionales
import { Link, useNavigate } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { Button, Badge, Row, Container, Col, Table } from 'react-bootstrap'
import '../admin.css'

const URI = `${process.env.REACT_APP_BACKEND}`

// configurating hooks
const AllCountries = () => {
  const [countries, setCountries] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getCountries()
  }, [])

  const getCountries = async () => {
    const res = await axios.get(URI + 'country')
    setCountries(res.data)
  }

  const deleteCountry = async (id) => {
    await axios.delete(`${URI}country/${id}`)
    getCountries()
  }
  const handleWhere = () => {
    navigate('/auth/userOptions')
  }

  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" onClick={handleWhere} variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Countries
          </h5>
          <Link to="/admin/country/add" className="btn btn-primary mt-2 mb-2">
            Adicionar{' '}
            <i className="bi bi-plus-circle-fill" aria-hidden="true"></i>
          </Link>
          <Table className="table">
            <thead className="table-primary">
              <tr>
                <th>Cod-Telef</th>
                <th>Pais</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {countries.map((country) => (
                <tr key={country.id}>
                  <td> {country.countryId} </td>
                  <td> {country.countryName} </td>
                  <td>
                    <Link
                      to={`../admin/country/edit/${country.id}`}
                      className="btn btn-info"
                    >
                      <FaRegEdit />
                    </Link>
                    <Button
                      onClick={() => deleteCountry(country.id)}
                      className="btn btn-danger"
                    >
                      <FaRegTrashAlt />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default AllCountries
