import React, { useState,useEffect,useContext } from 'react'
import {Link} from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Spinner,
  Image,
} from 'react-bootstrap'
import logo from '../img/logo/logowhite.png'
import '../authentication/auth.css'
import { TimerMessage } from '../components/TimerMessage'

const URI = `${process.env.REACT_APP_BACKEND}`

const Profile = () => {
  const {texts} = useContext(LanguageContext)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [topass, setTopass] = useState(null)
  const [form, setForm] = useState([])
  const { currentUser } = useContext(AuthContext)  
  let id = currentUser?.dataValues.id

  const validationsForm = (form) => {
    let errors = {}
    //expresiones regulares para cada tipo de campo
    let regexUserName = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü\s]+$/
    let regexText150 = /^.{1,150}$/
    let regexText45 = /^.{1,45}$/
    let regexText30 = /^.{1,30}$/
    let regexText15 = /^.{1,15}$/
    let regexPhone = /^[0-9,+, ,(,),-]*$/
    if (!form.userName || form.userName.length===0) {
      errors.userName = texts.errReq
    } else if (!regexUserName.test(form.userName)) {
      errors.userName = texts.errFormatName
    } else if (!regexText15.test(form.userName)) {
      errors.userName = texts.err15
    }
    if (!regexText150.test(form.name)) {
      errors.name = texts.Err150
    }
    if (form.phone && !regexPhone.test(form.phone)) {
      errors.phone = texts.errFormat
    } else if (
      form.phone &&
      !regexText30.test(form.phone)
    ) {
      errors.phone = texts.err30
    }
    if (!regexText150.test(form.address)) {
      errors.address = texts.err150
    }
    if (!regexText150.test(form.adress1)) {
      errors.adress1 = texts.err150
    }
    if (!regexText150.test(form.city)) {
      errors.city = texts.err150
    }
    if (!regexText150.test(form.state)) {
      errors.state = texts.err150
    }
    if (!regexText150.test(form.country)) {
      errors.country = texts.err150
    }
    if (form.zipCode && !regexPhone.test(form.zipCode)) {
      errors.zipCode = texts.errFormat
    } else if (
      form.zipCode &&
      !regexText45.test(form.zipCode)
    ) {
      errors.zipCode= texts.err45
    }
    return errors
  }

  const getUserById = async () => {
    const res = await axios.get(URI + 'auth/' + id)
    setForm(res.data)
  }
  useEffect(() => {
    getUserById()
    //eslint-disable-next-line
  }, [])

  
  

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const doNothing = (e) => {
    e.preventDefault()
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors(validationsForm(form))    
   if (Object.keys(errors).length === 0) {
    //if (errors.length === 0) {
      setLoading(true)
      try {        
        await axios.put(URI + 'auth/' + id, form)   
        setTopass(form.username)
        setLoading(false)
        setResponse(true)
      } catch (e) {
        setTopass(`Revise: ${JSON.parse(e.request.response).message}`)
        setLoading(false)
        setResponse(false)
      }
    }
  }
  
  

  return (
    <div className="auth">
      <Container fluid className="auth-card-wide">
        <Row>
          <Col>
          <Image
                    src={logo} 
                    alt={texts.title}
                    title={texts.title}
                  />{' '} 
          </Col>
        </Row>
        <Row>
        <Col>
            <Card>
            
              <Card.Header className="auth-card-header">
                {texts.auComp}
              </Card.Header>
              <Card.Body>
              <Form onSubmit={doNothing}>
                  <>
                    <Row>
                      <Col>
                        {(topass != null && topass.substr(0, 6)) ===
                          texts.seCheck && <p className="auth-err">{topass}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        {texts.auEmail} (Email): 
                      </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                      <input
                          className="form-control inputstl auth-input"
                          name="userMail"                    
                          type="text"
                          autoFocus
                          disable={true}
                          value={form.userMail}                          
                        />
                      </Col>
                    </Row>
                   
                    <Row>
                      <Col className="auth-label">{texts.auUserName} </Col>
                      <Col className="auth-label">{texts.phone} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="userName"                    
                          type="text"
                          autoFocus
               
                          onChange={handleChange}
                          value={form.userName}
                          required
                        />
                      </Col>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="phone"                    
                          type="text"
                          autoFocus
           
                          onChange={handleChange}
                          value={form.phone}
                         />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      {errors.userName && (
                        <p className="auth-err">{errors.userName}</p>
                      )}
                      </Col>
                      <Col>
                      {errors.phone && (
                        <p className="auth-err">{errors.phone}</p>
                      )}
                      </Col>
                      
                    </Row>                    
                    <Row>
                      <Col className="auth-label">{texts.auName} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="name"                    
                          type="text"
                          autoFocus
     
                          onChange={handleChange}
                          value={form.name}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {errors.name && (
                        <p className="auth-err">{errors.name}</p>
                      )}
                    </Row>
                   
                    <Row>
                      <Col className="auth-label">{texts.auAddress} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="address"                    
                          type="text"
                          autoFocus
                  
                          onChange={handleChange}
                          value={form.address}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {errors.address && (
                        <p className="auth-err">{errors.address}</p>
                      )}
                    </Row>
                    <Row>
                      <Col className="auth-label">{texts.auAddress1} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="address1"                    
                          type="text"
              
                          onChange={handleChange}
                          value={form.address1}

                        />
                      </Col>
                    </Row>
                    <Row>
                      {errors.address1 && (
                        <p className="auth-err">{errors.address1}</p>
                      )}
                    </Row>
                    <Row>
                      <Col className="auth-label">{texts.city} </Col>
                      <Col className="auth-label">{texts.auZipCode} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="city"                    
                          type="text"
              
                          onChange={handleChange}
                          value={form.city}

                        />
                      </Col>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="zipCode"                    
                          type="text"
              
                          onChange={handleChange}
                          value={form.zipCode}

                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {errors.city && (
                          <p className="auth-err">{errors.city}</p>
                        )}
                      </Col>
                      <Col>
                        {errors.zipCode && (
                          <p className="auth-err">{errors.zipCode}</p>
                        )}
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col className="auth-label">{texts.auState} </Col>
                      <Col className="auth-label">{texts.country} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="state"                    
                          type="text"
              
                          onChange={handleChange}
                          value={form.state}
      
                        />
                      </Col>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="country"                    
                          type="text"
              
                          onChange={handleChange}
                          value={form.country}
     
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                      {errors.state && (
                        <p className="auth-err">{errors.state}</p>                      
                      )}
                      </Col>
                      <Col>
                      {errors.country && (
                        <p className="auth-err">{errors.country}</p>
                      )}
                      </Col>
                    </Row>
                    
                    
                    
                  </>
                  </Form>
              </Card.Body>

              <Card.Footer className='pt-0'>
              <Row>
                      <Col>
                        {!response && (
                          <Button
                            onClick={handleSubmit}
                            className="auth-btnini"
                       
                          >
                            {loading && (
                              <Spinner
                                style={{ textAlign: 'center' }}
                                size="sm"
                                animation="border"
                                variant="success"
                              />
                            )}{' '}
                            {texts.auUpdUser}
                          </Button>
                        )}
                      </Col>
                    </Row>
              </Card.Footer>
  
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="auth-advice">
            <Link to={'/conditions'}>
              [Términos y Condiciones de Uso del Sitio]
            </Link>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col>
          {response && (
            <TimerMessage
              mess={'Bienvenido a AvenidaCuba...!'}
              variant={'success'}
              navig={'/auth/userOptions'}
              timetogo="2000"
            />
          )}
        </Col>
      </Row>
    </div> 
  )
}


export default Profile

