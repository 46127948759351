import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Badge, Form, Container, Row, Col } from 'react-bootstrap'
import '../admin.css'
import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoArrowUndoOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`

const EditCountry = () => {
  const [form, setForm] = useState([])

  // const [vcountryId, setCountryId] = useState('')
  // const [vcountryName, setCountryName] = useState('')

  const navigate = useNavigate()

  const { id } = useParams()

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.put(URI + 'country/' + id, form)
    Swal.fire({
      title: 'Updated Country!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/country/all')
  }

  useEffect(() => {
    getCountryById()
     //eslint-disable-next-line
  }, [])

  const getCountryById = async () => {
    const res = await axios.get(URI + 'country/' + id)
    // setCountryId(res.data.countryId)
    // setCountryName(res.data.countryName)
    setForm(res.data)
  }
  const doNothing = (e) => {
    e.preventDefault()
  }
  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" href="/admin/country/all" variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Updating a Country
          </h5>
          <Form onSubmit={doNothing}>
            <Form.Group>
              <Form.Label>Phone Code</Form.Label>
              <Form.Control
                name="countryId"
                className="service-title"
                value={form.countryId}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Country Name</Form.Label>
              <Form.Control
                name="countryName"
                className="service-title"
                value={form.countryName}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  className="mt-2 mb-2"
                >
                  Save
                  <FaRegCheckCircle />
                </Button>
                <Button
                  variant="danger"
                  href="/admin/country/all"
                  className="mt-2 mb-2"
                >
                  Cancel
                  <IoCloseCircleOutline />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default EditCountry
