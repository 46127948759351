import ReactHtmlParser from "react-html-parser";

const Texts = props => {
    return (
    <div className="hijo">
    <h4>{props.title}</h4>
    <p>{ReactHtmlParser(props.description)}</p>
    </div>
    )
}

export default Texts