import { createContext, useState} from 'react'

const LanguageContext = createContext()
//const initialLanguage = ''
const translations = {
  es: {
    idiom: 'es',
    langsite: 'Español',
    title: 'Sitio de Intercambio entre Cubanos por el mundo...',
    backPage: 'Regresar a Página Anterior...',
    year:'Año',
    phone: 'Teléfono',
    print: ' Imprimir ',
    continue: 'Continuar',
    userCloseYes:'Cerrar',
    userCloseNo: 'Continuar Accediendo',
    userCloseQuestion:'Deseas cerrar tu sesión?',
    printSucc: 'Impresión realizada!...',
    printNo: 'Imposible imprimir, revise!...',
    noFind: 'No existen coincidencias que mostrar',
    Register: 'Registrese antes de realizar o actualizar una oferta...',
    RegisterSite: 'Registrese antes de adicionar o actualizar un Sitio...',
    country:'País',
    city:'Ciudad',
    facebook: 'Facebook',
    instagram: 'Instagram',
    telegram:'Telegram',
    x:'X(Twiter)',
    fill: '* Llenado obligatorio',
    cancel: 'Cancelar Edición...',
    update: 'Actualizar Datos...',
    add: 'Adicionar Datos...',
    auUser: 'Usuario',
    auName:'Nombre Completo:',
    auAddress:'Dirección',
    auAddress1:'Dirección (más)',
    auRegis:'Registro Usuario',
    auComp:'Completar datos Usuario',
    auChange:'Cambiando contraseña',
    auUserName:'Nombre Usuario:',
    auRegi:'No es usuario?...regístrese aquí',
    auRegisMine:'Registrar mi usuario',
    auUpdUser:'Actualizar mis datos',
    auLogin:'Inicio de Sesión',
    auUserNamePH:'Su Nombre de Usuario...',
    auEmail: 'Correo Electrónico (Email)',
    auEmailPH: 'correo@xxxx...', 
    auPassPH:'Su contraseña...',
    auPass:'Contraseña',
    auSentEmail:'Si selecciona Enviar Correo, un código de recuperación será enviado al correo que se muestra arriba (su correo de registro) ...',
    auSendEmailYes:'Enviar Correo',
    auSendedEmail:'Correo Enviado...!',
    auEmailTo:'Estimado Usuario del Sitio AvenidaCuba:',
    auEmailSubj:'Correo de Recuperación de Contraseña',
    auEmailMess:'El código que se ha enviado a su correo debe ser utilizado por usted para proceder a resetear su contraseña y poder fijar una nueva. Recuerde su código, vuelva al Sitio y siga las instrucciones... Gracias.',
    auEmailError:'Error de Conexión, imposible enviar el correo de Verificación!...',
    auSendEmailNo:'Regresar',
    auTermyCond:'Términos y Condiciones de Uso del Sitio',
    auTyCon:'Acepto Términos y Condiciones de Uso',
    auNewOtp:'Un nuevo código ha sido enviado a su correo electrónico.',
    auOtpAccept:'Código aceptado...!',
    auOtpNotAccept:'Código incorrecto... intente de nuevo o solicite un nuevo codigo.',
    auOtpUse:'Hemos enviado un correo con el Código de Verificación, revise y obtenga el código, después introdúzcalo debajo.',
    auOtpVerify:'Verifique Código!',
    auOtpNotReceived:'No recibiste el Código?...',
    auOtpResendUntil:'Espere... Reenviar Código en ',
    auOtpResend:'Reenviar Código',
    auForgetPass:'Olvidó su contraseña?',
    auWelcome:'Bienvenido a AvenidaCuba...',
    auZipCode:'Código Postal',
    auState:'Estado/Provincia',
    auOpt: 'OPCIONES',
    auQuit: 'Salir',
    auComplete: 'Completar Datos del Usuario (Recomendado!)',
    auNewOffer: 'Mi Nueva Oferta',
    auNewOfferNotPub: 'Mis Ofertas no Publicadas aún',
    auNewOfferNotPubAdmin: 'Aprobar Ofertas de Servicio',
    auNewSite: 'Mi Nuevo Sitio para Comentar',
    auNewSiteNotPub: 'Mis Sitios no Publicados aún',
    auNewSiteNotPubAdmin: 'Aprobar Sitios',
    auOnlyMineSite: 'Mostrar solo mis Sitios',
    auOnlyMine: 'Mostrar solo mis ofertas',
    auPassChange: 'Cambiar Contraseña',
    auTerm: 'Términos y Condiciones de Uso del Sitio',
    auUse: 'Uso del Sitio',
    auCond: 'Términos y Condiciones',
    auClosed: 'Sesión Cerrada!',
    auMark: '(Debe Leer y Marcar [✔︎] aceptando antes de Iniciar Sesion)',
    auUserClose:'Cerrar Sesion/Otros Datos Usuario',
    auUserLogin:'Iniciar Sesion!',
    doTitle:'Donación',
    doSubt:'Haga una donación y ayude a mantenernos activos!',
    doAdd:'Donar!',
    doAddTitle:'Proceder a Donar!',
    doanonymous:'Anónimo',
    doanonymousyes:'Deseo ser Anónimo',
    doOther:'A Donar (puede teclear otra cantidad):',
    doFor:'Donado por:',
    doGrateful:'Nuestro agradecimiento para:',
    doClickToSee:'Click para visualizarlos todos...',
    doOurDonors:'Nuestros Donantes??...',
    doOk:'Usted ha donado!',
    doThanks:'Nuestro profundo agradecimiento...',
    errPass: '📍Contraseña incorrecta...',
    errReq:'📍Este campo es requerido...',
    errFormatName:'📍Solo letras, números y espacios',
    errFormat:'📍Formato incorrecto...',
    errEmail:'📍El correo no es usuario del Sitio...',
    err150:'📍Máximo 150 cacteres...',
    err15:'📍Máximo 15 cacteres...',
    err30:'📍Máximo 30 cacteres...',
    err45:'📍Máximo 45 cacteres...',
    errDif:'📍Las contraseñas no coinciden...',
    errChangePass: '📍Imposible efectuar cambio de contraseña...',
    errUserExist: '📍El usuario ya existe para este Sitio... ',
    errRegis: '📍No fue posible realizar el Registro del Usuario... ',
    heHome: 'Inicio',
    heSites: 'Sitios',
    heServices: 'Servicios',
    heCubanCrafts: 'Artesania',
    heMyAccount: 'Mi Cuenta',
    heDonate: 'Se amable... ayúdanos a mantenernos ayudando a los cubanos!',
    heSiteNew: 'Proponer Sitio',
    heOffer: 'Ofertar Servicio',
    heSearch: 'Buscar aqui...',
    hoTitleServices: 'Necesita un Servicio?... su solución puede estar aquí, Revise!!',
    hoSurprise: 'Nuestro regalo diario!...',
    hoSurprisePlus: 'Nuestro regalo diario!...',
    siInfo: 'Informacion sobre Sitios',
    siSubt: 'Sitios... Utilidad y Opinion de los Usuarios',
    siSubtAdd: 'Sitios... Adicionando Nuevo Sitio',
    siSubtUpd: 'Sitios... Actualizando un Sitio',
    siEvalTit: 'Evalua y Comenta',
    siEvalSubt: 'Sitios Utiles... o no?. Ayuda a otros a elegir!',
    siOpinion: 'HABLANDO ACERCA DE ESTE SITIO...',
    siComment: 'Comente y Evalúe el Sitio',
    siAbout: 'Acerca del Sitio',
    siNoInfo: 'Sin información',
    siUrl: 'URL del Sitio',
    siEmail: 'Email Contacto Sitio',
    siAdd: 'Adicionar Sitio',
    siAddnoAdmin: 'Proponer Sitio',
    siNew: 'Nuevo Sitio',
    siUpd: 'Editar',
    siUpdAct: 'Actualizar',
    siDelete: 'Eliminar',
    siAddSiteButton: 'Adicionar nuevo Sitio (solo Usuarios)',
    siNewTitle: 'Adicionar un Nuevo Sitio para Comentar...',
    siUpdTitle: 'Actualizar datos de un Sitio existente...(solo si usted lo adicionó)...',
    siDelTitle: 'Eliminar un Sitio existente... (solo si usted lo adicionó)...',
    siPubSite: 'Publicar el Sitio',
    evalComment: 'Ver Todos los Comentarios',
    evalCommentNot: 'Sin comentario.',
    evalOpinion: ' opinion(es)',
    evalRate: 'Evaluar',
    evalSend: 'Evaluación Enviada...!',
    evalError: 'Comentario mayor de 500 caracteres...Revise!',
    evalRegis: 'Registrese para dar su Opinión...',
    evalGiveOp: 'De su opinión aqui... Gracias',
    evalSendOp:  'Enviar opinión/evaluación',
    evalDelOp: 'Eliminar Evaluación',
    seWeb: 'Sitio Web',
    seInfo: 'Oferta de Servicios',
    seSubtNP: 'Servicios NO PUBLICADOS del usuario',
    seSelect: 'Seleccionar',
    seSubtP: 'Servicios PUBLICADOS del usuario',
    seSubt: 'Variados servicios ofertados...',
    seBtnOffer: 'Ofertar Nuevo Servicio',
    seAdviceHeader: 'Aviso Importante',
    seAdviceBody:
      'La Administración del Sitio no se responsabiliza con la calidad de los Servicios ni con el comportamiento de los anunciantes, somos una plataforma para facilitar el intercambio entre clientes, no intervenimos ni cobramos por ofertar o solicitar un servicio.',
    seAdviceBodySite:
      'La Administración del Sitio se reserva el derecho de modificar los datos oficiales del Sitio adicionado si estos han sido distorcionados. Las opiniones emitidas por ustedes serán respetadas en cualquier caso, siempre que no sean consideradas ofensivas... Su sitio será revisado y publicado con la mayor brevedad.',
    seAdviceBodyService:
      'La publicación del servicio que oferta es gratuita, solo deberá conocer que:',
    seAdviceBodyService2: '🔹 La publicación se hará pública después que se revise por la Administración del Sitio, reservándose el derecho de no hacerlo si los datos introducidos no son reales o están distorcionados o son ofensivos. De faltar datos se le comunicará al correo con el cual se ha registrado en el Sitio.',
    seAdviceBodyService3: '🔹 En los datos debe incluir siempre para poder acceder al Servicio por los clientes, un teléfono, correo o dirección.',
    seAdviceBodyService4: '🔹 El proceso de revisión será siempre menor de 48 horas, si no recibe un email nuestro previamente.',
    seAdviceFooter: ' Admin de AvenidaCuba ... Gracias',
    seProp: 'Propuestas',
    seDetails: 'Detalles del Servicio ...',
    seAdd: 'Adicionar Oferta...',  
    seAddnoAdmin: 'Proponer Oferta...',  
    seAddTitle: 'Adicionar una Nueva Oferta o Servicio...',
    seOffer: 'Ofertar',
    seUpd: 'Modificar Oferta...',
    seDelete: 'Eliminar Oferta...',
    seDel: 'Eliminar',
    seDesc: 'Sin descripción del Servicio',
    seWithoutData: 'Sin Datos',
    seCity: 'Ciudad',
    seEnlarge: 'Click para agrandar ...',
    seAbout: 'ACERCA DEL SERVICIO',
    seUpdate: 'Actualizar',
    seAdding: 'Adicionando un Servicio...',
    seUpdating: 'Modificando un Servicio...',
    seCheck: 'Revise',
    seCancelUpd: 'Cancelar Actualización...',
    seCancelAdd: 'Cancelar Oferta Iniciada...',
    seCancel: 'Cancelar',
    seUpdChange: 'Actualizar Cambios...',
    seReturn: 'Regresar',
    seUpdService: 'Servicio Actualizado...!',
    seAddService: 'Servicio Adicionado...!',
    seService: 'Servicio',
    seTitle: 'Titulo para mostrar',
    seDescrip: 'Descripción',
    seHours: 'Ubicación/Horario',
    seBolder: 'Algo a Enfatizar!',
    seNewImg: 'Nueva Imagen',
    seMaxImg: 'Ha seleccionado el número máximo de imágenes(5), para adicionar debe eliminar alguna...',
    sePubOffer: 'Publicar la Oferta',
    seFavImg: 'Seleccionar Imagen Favorita...',
    seDelImg: 'Eliminar Imagen...',
    seBiggest: 'Click para agrandar...',
    seSubjectMail: 'De un usuario del sitio web AvenidaCuba interesado en su oferta...',
    seNotPub: 'Si su oferta se adiciona sin marcar Publicar, será vista solo por Ud. dando click en su usuario en el menu superior, en la opción de Mis Ofertas no publicadas aún, podrá editarla y marcar Publicar para que pueda ser vista para todos...',
    seSitesName: 'Nombre del Sitio',
    seSitesSubt: 'Resumen del Sitio',
    seSitesText: 'Contenido del Sitio',
    conTitle: 'Quien Somos?!',
    conSubt: 'Contáctenos!',
    conCall: 'Llámenos',
    conDonation: 'Gracias por hacernos una donación!',
    conName:'Su Nombre',
    conMail: 'Su Correo Electrónico',
    conSubject:'Asunto',
    conObject:'Mensaje',
    conSended:'Su mensaje ha sido enviado. Gracias...',
    conSend:'Enviar Mensaje',
    conMakeDon: 'Haces una donación?',
    conHeader:
      'Somos AvenidaCuba, la Plataforma que une a todos los cubanos dispersos por el mundo y que les sirve de apoyo para gestionar servicios menores, ventas artesanales, hospedajes, rentas de todo tipo y cualquier otro servicio sin costo alguno. Usted puede ofertar sus servicios o productos, buscar lo que necesite, puede opinar sobre el servicio que brindan otros sitios con el fin de alertar o agradecer el trato recibido en ellos y facilitar una buena experiencia a sus hermanos cubanos. Si nuestro sitio le fue de ayuda y Ud. lo desea, puede hacernos una donación que nos ayude a permanecer para todos. Muchas gracias...!',
    sext1:'Sugerencias',
    sext2: ' del Día!!...',
    foLinks: 'Enlaces del Sitio',
    
    foContactUs: 'Contáctenos y Apóyenos!',
    foSendMess: 'Quien Somos?',
    foAvenue:
      'Una Avenida es un camino amplio y confortable hacia un lugar. Avenida Cuba es un sitio donde se unen las familias: apoyo, consejos, intercambios. Aqui le brindamos un espacio para facilitar la comunicacion entre los cubanos por el mundo... donde quiera que residan. Apóyanos en las redes sociales!!',
    ter0:'Lea hasta el final...',
    ter1:'El Sitio es solo una plataforma para facilitar el intercambio entre los usuarios del mismo, no intervenimos ni cobramos por ofertar o solicitar un servicio. Solo tenemos algunos Términos y Condiciones sencillos y claros que hay que cumplir y que deben todos conocer. Lea detalladamente antes de autentificarse y vuelva a leer antes de publicar para no incurrir en una falta.',   
  },

  en: {
    idiom: 'en',
    langsite: 'English',
    title: 'Exchange Site between Cubans around the world...',
    Register: 'Register before adding or updating an offer...',
    RegisterSite: 'Register before adding or updating a Site...',
    year:'Year',
    phone: 'Phone',
    print: ' Print ',
    continue: 'Continue',
    userCloseYes:'Close',
    userCloseNo: 'Continue Accessing',
    userCloseQuestion:'Do you want to close your session?',
    noFind: 'No existen coincidencias que mostrar',
    printSucc: 'Printed Successfully!...',
    printNo: 'It was impossible to print the file, take a look at that!...',
    backPage: 'Return to Previous Page...',
    country:'Country',
    city:'City',
    facebook: 'Facebook',
    instagram: 'Instagram',
    telegram:'Telegram',
    x:'X(Twiter)',
    fill: '* Mandatory filling',
    cancel: 'Cancel Edition...',
    update: 'Update Data...',
    add: 'Add Data...',
    auUser:'User',
    auName:'Full Name:',
    auAddress:'Address',
    auAddress1:'Address (more)',
    auRegis:'User Register',
    auComp:'Complete data User',
    auChange:'Changing password',
    auUserName:'User Name:',
    auRegisMine:'Register my User',
    auRegi:'Not a User?...register here',
    auUpdUser:'Update my User',
    auLogin:'Log in',
    auUserNamePH:'Your User Name...',
    auEmail: 'Electronic Mail',
    auEmailPH: 'mail@xxxx...', 
    auPassPH:'Your Password...',
    auPass:'Password',
    auSentEmail:'If you select Send Mail, a code will be send to you email (your register email) ...',
    auSendEmailYes:'Send Email',
    auSendEmailNo:'Return',
    auTermyCond:'Terms and Use Conditions of the Site',
    auTyCon:'I accept the Terms and Use Conditions',
    auNewOtp:'A new OTP has succesfully been sent to your email.',
    auOtpAccept:'Accepted Code...!',
    auOtpNotAccept:'Wrong Code... try again or request another code.',
    auOtpUse:'We have sent an email with the Verification Code, review and obtain  the code an then, enter it below.',
    auOtpVerify:'Check the Code!',
    auOtpNotReceived:'Did you not receive de Code?...',
    auOtpResendUntil:'Wait... Resend Code in ',
    auOtpResent:'Resend Code',
    auForgetPass:'Did you forget your password?',
    auWelcome:'Welcome to AvenidaCuba...',
    auSendedEmail:'Email Sended...!',
    auEmailTo:'Dear User of AvenidaCuba Site:',
    auEmailSubj:'Password Recover Email',
    auEmailMess:'The code that we are sending to your email must be used for you to reset your password and introduce a new one. Remeber that code and return to the Site and follow the instructions... Thanks.',
    auEmailError:'Conection Error, it is imposible send an email to your direction...!',    
    auZipCode:'ZIP Code',
    auState:'State/Province',
    auOpt: 'OPTIONS',
    auQuit: 'Quit',
    auComplete: 'Complete User Information (Recommended!)',
    auNewOffer: 'My new Offer',
    auNewOfferNotPub: 'My Offers Not Published yet',
    auNewOfferNotPubAdmin: 'Approve Service Offers',
    auOnlyMine: 'Show only my offers',
    auNewSite: 'My new Site to Comment',
    auNewSiteNotPub: 'My Sites Not Published yet',
    auNewSiteNotPubAdmin: 'Approve Sites',
    auOnlyMineSite: 'Show only my sites',
    auPassChange: 'Change Password',
    auTerm: 'Terms and Conditions of use of the Site',
    auUse: 'Use of the Site',
    auCond: 'Terms and Conditions',
    auClosed: 'Closed Session',
    auMark: '(You must Read and Check [✔︎] to accept this before Log in)',
    auUserClose:'Log Out/Other User Data',
    auUserLogin:'Login!',
    doAdd:'Donate!',
    doAddTitle:'Go Donate!',
    doanonymous:'Anonymous',
    doanonymousyes:'I wish to be anonymous',
    doOther:'To Donate (you can enter another amount):',
    doFor:'Donated By:',
    doGrateful:'Our thanks to:',
    doClickToSee:'Click to view them all...',
    doOurDonors:'Our Donors??...',
    doOk:'You Donated!',
    doThanks:'Tanks a lot...',
    errPass: '📍Wrong Password...',
    errReq:'📍This field is required...',
    errFormat:'📍Incorrect Format...',
    errFormatName:'📍Only letters, numbers and spaces...',
    errEmail:'📍This Email is not an user of the Site...',
    err150:'📍Maximum 150 characters...',
    err15:'📍Maximum 15 characters...',
    err30:'📍Maximum 30 characters...',
    err45:'📍Maximum 45 characters...',
    errDif:'📍Passwords do not match...',
    errChangePass: '📍We can\'t change the Password...',
    errUserExist: '📍User exists in this Site... ',
    errRegis: '📍We can\'t create new user... ',
    heHome: 'Home',
    heSites: 'Sites',
    heServices: 'Services',
    heCubanCrafts:'Crafts',
    heMyAccount: 'My Account',
    heOffer: 'Offer Service',
    heDonate: 'Be kind... help us to keep helping Cubans!',
    heSiteNew: 'Propose Site',
    heSearch: 'Search here...',
    hoTitleServices:'Do you need a Service?... your solution may be here, Check it out!!',
    hoSurprise: 'Our Daily Gift!...',
    hoSurprisePlus: 'Our Daily Gift!...',
    siInfo: 'Site Information',
    siSubt: 'Sites... Usefulness and User Opinion',
    siSubtAdd: 'Sites... Adding a New Site',
    siSubtUpd: 'Sites... Updating a Site',
    siEvalTit: 'Rate and Comment',
    siEvalSubt: 'Useful Sites... or not? Help others choose!',
    siOpinion: 'TALKING ABOUT THIS SITE...',
    siComment: 'Comment and Evaluatethe Site',
    siAbout: 'About Site',
    siUrl: 'Site URL',
    siEmail: 'Contact Email',
    siNoInfo: 'Without Information',
    siNew: 'New Site',
    siAdd: 'Add Site',
    siAddnoAdmin: 'Propose Site',
    siUpd: 'Edit',
    siUpdAct: 'Update',
    siDelete: 'Delete',
    siNewTitle: ' Add a New Site to Comment...',
    siUpdTitle: 'Update data of an existing Site...(only if you add it)...',
    siDelTitle: 'Delete an existing Site... (only if you add it)...',
    siAddSiteButton: 'Add New Site (Only users)',
    siPubSite: 'Publish the Site',
    evalComment: 'See all Comments',
    evalCommentNot: 'Without Comment.',
    evalOpinion: ' opinion(s)',
    evalRate: 'Evaluate',
    evalSend: 'Evaluation Send...!',
    evalError: 'Comment longer than 500 characters...Please Review',
    evalRegis: 'Register to give your opinion...',
    evalGiveOp: 'Your opinion here!... Thanks',
    evalSendOp:  'Send Opinion/Evaluation',
    evalDelOp: 'Delete Evaluation',
    seWeb: 'WebSite',
    seInfo: 'Service Offering',
    seSubtNP: 'Unpublished Services of User',
    seSelect: 'Select',
    seSubtP: 'Published Services of User',
    seSubt: 'Various Services Offered',
    seBtnOffer: 'Offer New Service',
    seAdviceHeader: 'Important announcement',
    seAdviceBody:
      'The Administration of this Site is not responsible for the quality of the Services or the behavior of the advertisers, we are a platform to facilitate exchange between clients, we do not intervene or charge for offering or requesting a service.',
    seAdviceBodySite:
      'The Administration of this Site reserves the right to modify the official data of the added Site if these have been distorted. The opinions expressed by you will be respected in any case, as long as they are not considered offensive... Your site will be reviewed and published as soon as possible.',
      
    seAdviceBodyService:
      'The publication of the service you offer is free, you only need to know that:',
    seAdviceBodyService2: '🔹 The publication will be public after it has been reviewed by the Site Administration, which reserves the right to not do so if the data entered is not real or is distorted or offensive. If there is missing data, you will be notified by the email with which you registered on the Site.',
    seAdviceBodyService3: '🔹 In order for customers to access the Service, the data must always include a telephone number, email address or address.',
    seAdviceBodyService4: '🔹 The review process will always take less than 48 hours, if you do not receive an email from us beforehand.',
    seAdviceFooter: ' AvenidaCuba Admin...Thank you',
    seProp: 'Proposals',
    seDetails: 'Details of the service...',
    seAdd: 'Add Offer...',
    seAddnoAdmin: 'Propose Offer...',  
    seAddTitle: 'Add a New Offer or Service...',
    seOffer: 'Offer',
    seUpd: 'Update Offer...',
    seUpdate: 'Update',
    seUpdating: 'Updating a Service...',
    seAdding: 'Adding a Service...',
    seDelete: 'Delete Offer...',
    seDel: 'Delete',
    seDesc: 'Without Description of the Service',
    seWithoutData: 'Without Data',
    seEnlarge: 'Click to Enlarge...',
    seCity: 'City',
    seAbout: 'ABOUT THE SERVICE',
    seCheck: 'Check',
    seCancelUpd: 'Cancel Update...',
    seCancelAdd: 'Cancel Add..',
    seCancel: 'Cancel',
    seUpdChange: 'Update Changes...',
    seReturn: 'Return',
    seHours: 'Location/Hours',
    seUpdService: 'Updated Service...!',
    seAddService: 'Added Service...!',
    seService: 'Service',
    seTitle: 'Title to Show',
    seDescrip: 'Description',
    seBolder: 'Someting to emphasize!',
    seNewImg: 'New Image',
    seMaxImg: 'You have seleted the maximum number of images(5), to select new one, you must delete some...',
    sePubOffer: 'Publish the Offer',
    seFavImg: 'Select Favorite Image...',
    seDelImg: 'Delete Image...',
    seBiggest: 'Click to enlarge...',
    seSubjectMail: 'From a user of AvenidaCuba website interested in your offer...',
    seNotPub: 'If your offer is added without checking Publish, it will be seen only by you by clicking on your username in the top menu, in the My Offers not yet published option, you can edit it and check Publish so that it can be seen by everyone ...',
    seSitesName: 'Sites Name',
    seSitesSubt: 'Summary of the Site',
    seSitesText: 'Content of the Site',
    conTitle: 'Who we are?!',
    conSubt: 'Contact Us!',
    conCall: 'Call',
    conDonation: 'Thanks for make a donation!',
    conMakeDon: 'Make a donation?',
    conName:'Your Name',
    conMail: 'Your Email',
    conSubject:'Subject',
    conObject:'Message',
    conSended:'Your message has been sent. Thank you...',
    conSend:'Send Message',
    conHeader:
      'We are AvenidaCuba, the Platform that unites all Cubans scattered around the world and that serves as support for them to manage minor services, craft sales, accommodations, rentals of all kinds and any other service at no cost. You can offer your services or products, search for what you need, you can comment on the service provided by other sites in order to alert or thank them for the treatment received there and provide a good experience to your Cuban brothers. If our site was helpful to you and you wish, you can make a donation to help us remain for everyone. Thank you so much...!',
    sext:'Suggestions of the day!!...',
    foLinks: 'Site Links',
    foContactUs: 'Contact and Support Us!',
    foSendMess: 'Who we are?',
    foAvenue:
      'An Avenue (Avenida in Spanish) is a wide and comfortable path towards a place. Avenida Cuba is a place where the families: support, advice, exchanges. Here we provide you a space to facilitate communication between Cubans around the world... wherever they reside. Support us on social networks!!',
    ter0:'Read until the end...',
    ter1:'The Site is only a platform to facilitate exchange between its users, we do not intervene or charge for offering or requesting a service. We only have some simple and clear Terms and Conditions that must be followed and that everyone should know. Please read carefully before authenticating and read again before posting to avoid incurring a fault.',
    
  },
}

const LanguageProvider = ({ children }) => {

 // const [language, setLanguage] = useState('')
  const [texts, setTexts] = useState([])
  
  const handleLanguage = (usrLanguage) => {
    if (usrLanguage === 'es') {
     // setLanguage('es')
      setTexts(translations.es)
    } else {
      //setLanguage('en')
      setTexts(translations.en)
    }
   
  }

 const data = { texts, handleLanguage}
/*
 const data = useMemo(()=>({
  texts,
  handleLanguage

 }),[texts])
 */ 
 return (
    <LanguageContext.Provider value={data}>{children}</LanguageContext.Provider>
  )
}
export { LanguageProvider }

export default LanguageContext
