const FilterBox = ({ filters, updateFilters }) => {
  const onFilter = (event) => {
    const {
      target: { value, checked },
    } = event

    updateFilters(
      filters.map((f) => {
        return {
          ...f,
          isChecked: value === 'all' ? true : f.label === value,
        }
      }),
    )
  }

  return (
    <div className="filters">
      <div className="row" data-aos="fade-up" data-aos-delay="200">
        <div className="col-lg-12 d-flex justify-content-center">
          {filters.map((f) => (
            <div id="amazing-filters" key={`${f.label}_key`}>
              <input
                id={f.label}
                type="checkbox"
                value={f.label}
                onChange={onFilter}
                checked={f.isChecked}
              />
              <label htmlFor={f.label}>{f.label}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default FilterBox
