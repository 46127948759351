import React, { useContext , useEffect, useRef } from 'react'
import '../authentication/auth.css'
import { useReactToPrint } from 'react-to-print'
import LanguageContext from '../context/LanguageContext'
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
} from 'react-bootstrap'
//import logo from '../img/logo/logowhite.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { IoArrowUndoOutline, IoPrintSharp } from 'react-icons/io5'

const actyear = new Date().getFullYear().toString()

const Conditions = () => {
  const componentRef = useRef()
  const { texts } = useContext(LanguageContext)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Term. y Cond. AvenidaCuba',
    onAfterPrint: ()=> Swal.fire(
      texts.print,
      texts.printSucc,
      'success',
    )
  })
 
  useEffect(() => {
    AOS.init()
  }, [])

  const Mailto = ({ email, subject, body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  return (

    <Container fluid className="auth p-3">
    <Row>
      <Col lg={12} className="section-title" data-aos="fade-up">
      <h2 data-aos="fade-left" data-aos-delay="10">
        {texts.auUse} {actyear}
        </h2>
        <p
          data-aos="fade-right"
          data-aos-delay="10"
          style={{ fontFamily: 'fantasy' }}
        >
          {texts.auCond}
        </p>
        
      </Col>
    </Row>
<Row>
    <div >
          <div style={{ display: 'flex',marginBottom:'2rem'}}>
              <Button 
                size="sm" 
                onClick={goBack}
                variant="link"
              >
                <Badge bg="light" className="button-shadow">
                  <IoArrowUndoOutline
                    style={{ fontSize: '1rem', color: '#641e02' }}
                    title={texts.backPage}
                  />
                </Badge>
              </Button>
              <Button 
                size="sm" 
                onClick={handlePrint}
                variant="link"
              >
                <Badge bg="light" className="button-shadow">
                  <IoPrintSharp
                    style={{ fontSize: '1rem', color: '#641e02' }}
                    title={texts.print}
                  />
                </Badge>
              </Button>
              <span  style={{ fontSize: '1rem', color: '#641e02',marginTop:'5', marginLeft:'10' }}>{texts.ter0}</span>
          </div>
    </div>
    <div className='conditions p-5' ref={componentRef} style={{ widht:'100%', height: window.innerHeight}}>
      <p style={{textTransform:'uppercase',fontWeight:'bold'}}>{texts.auTerm}</p>
      <p></p>
      <p className='advice'>{texts.ter1}</p>
      <p></p>      
      {texts.idiom === 'es' ?
        <div>
             <p style={{textDecoration:'underline'}}>
              El uso de este sitio web se rige por los términos y condiciones resumidos en este documento:
            </p>
            <p>
              El Sitio Web AvenidaCuba fue creado con el fin de facilitar el intercambio entre los Usuarios del mismo. No ofertamos productos, no damos cobertura a ningún servicio o producto, solo servimos de plataforma de intercambio donde los Usuarios pueden ofrecer o localizar sus productos o servicios, opinar sobre servicios recibidos, sobre otros sitios de utilidad para la comunidad cubana en cualquier lugar donde se encuentre.
            </p>
            <p>
              Al acceder a este Sitio usted está condicionado a cumplir y respetar las condiciones del mismo. Su acceso es gratuito para todos, pero si desea interactuar con el mismo, ya sea ofertar algo u opinar o evaluar un sitio o servicio, deberá registrarse y cumplir lo establecido al respecto. Cada Usuario será el responsable de lo que publique en el sitio y respetará las normas que el mismo establece y que se muetran más adelante, por lo que la contraseña y el Usuario son intransferibles y secretos para cada Usuario registrado.
            </p>
            <p className='advice-title' style={{fontWeight:'bold'}}>
              Derechos y Obligaciones de los Usuarios Registrados.
            </p>
            <p>
              Los interesados en Registrarse deberán previamente Aceptar los términos y condiciones del mismo.        
            </p>
            <p>
              Una vez que se han registrado deberán completar sus datos y mantenerlos actualiados. Esos datos no se harán públicos, son solo para el dominio del Sitio.
            </p>
            <p>
              Los Usuarios del Sitio podrán publicar ofertas de todo tipo de Productos o Servicios, dejando bien descrito los mismos y la posibilidad de acceder a ellos, ya sea un teléfono, email, Sitio Web o Dirección. Recuerde que no intervenimos entre los posibles consumidores y los que ofertan. De quien oferta depende la posibilidad o no de acceder a sus ofertas.
            </p>
            <p>
              Los Usuarios que realizan ofertas serán los responsables de la calidad y veracidad de las mismas y quienes acceden a ellas serán responsables de verificarlas y libres de utilizarlas o no.
            </p>
            <p>
              Los Usuarios también podrán opinar sobre Sitios de uso común para la comunidad cubana e incluso incluir sitios que consideren de utilidad y sobre los cuales deseen opinar. Los datos que se introduzcan de los mismos deben ser reales y exactos.
            </p>
            <p>
              El lenguaje utilizado deberá ser correcto, las opiniones serias y de manera decente y no ofensiva, aún cuando trasmitan opiniones negativas.
            </p>
            <p className='advice-title' style={{fontWeight:'bold'}}>
              Derechos y Obligaciones de la Administración del Sitio.
            </p>
            <p>
              La Administración del Sitio no se responsabiliza con las opiniones emitidas por los Usuarios del mimsmo ni con la calidad de los Servicios y Productos que se encuentran publicados. Dichas opiniones no reflejan el sentir de la Administración.
            </p>
            <p>
              La Administración del Sitio se reserva el derecho de eliminar opiniones u ofertas que se consideren ofensivas, ilegales o indecentes.
            </p>
            <p>
              Si algún dato aportado por un Usuario es incorrecto o no aceptable, el sitio puede corregirlo y enviará comunicación al Usuario.
            </p>
            <p>
              Si se detecta un Usuario que no cumpla con las normas establecidas, puede ser bloqueado o eliminado, previa comunicación al Usuario.
            </p> 
            <p>
              Pese a que hemos implantado todas las medidas adecuadas para garantizar la seguridad en esta materia, no controlamos ni garantizamos la ausencia de virus ni de otros elementos en los contenidos de la presente página Web.
            </p>   
            <p>
              AvenidaCuba se exime de cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a la presencia de virus en los contenidos que puedan producir alteraciones en el sistema informático, documentos electrónicos, ficheros o hardware del Usuario. No tenemos la obligación de controlar, y no controlamos, la utilización que los Usuarios hacen de este sitio Web, de los servicios que este sitio Web provee, ni de sus contenidos. No garantizamos que los Usuarios utilicen el sitio Web, sus servicios y sus contenidos de conformidad con los Términos y Condiciones aqui expuestos, ni que lo hagan de forma diligente y prudente.
            </p>
            <p> 
               Tampoco tiene la obligación de verificar, y no verifican, la identidad de los Usuarios de este sitio Web; ni la veracidad, de los datos que los mismos proporcionan sobre sí mismos o sus productos y servicios ofertados. 
            </p>
            <p>
              Cualquier sugerencia, duda o queja podrá ser enviada a nuestro correo electrónico. Su correo será respondido y su queja o sujerencia valorada y atendida.
            </p>         
            <p>
              {texts.ter11} <Mailto email='avenidacuba2024@gmail.com' subject='Sugerencia, Duda o Queja de Cliente' body='Para: Atención al Cliente - Sitio AvenidaCuba.'>avenidacuba2024@gmail.com</Mailto> {texts.ter12} 
            </p>
            <p>        
              Este documento fue revisado por última vez el 31 de Julio del 2024.
            </p> 
            <p>Administración Sitio AvenidaCuba </p>
          </div>
          :
          <div>
            <p style={{textDecoration:'underline'}}>              
              Use of this website is governed by the terms and conditions summarized in this document:  
            </p>
            <p>
              The AvenidaCuba Website was created in order to facilitate the exchange between its Users. We do not offer products, we do not cover any service or product, we only serve as an exchange platform where Users can offer or locate their products or services, give their opinion on services received, on other sites useful to the Cuban community whereverit is found.
            </p>
            <p>
              By accessing this Site you are conditioned to comply with and respect its conditions. Its access is free for everyone, but if you want to interact with it, whether to offer something or give an opinion or evaluate a site or service, you must register and comply with the provisions in this regard. Each User will be responsible for what they publish on the site and will respect the rules that it establishes and that are shown below, so the password and the User are non-transferable and secret for each registered User.
            </p>
            <p className='advice-title' style={{fontWeight:'bold'}}>
              Rights and Obligations of Registered Users.
            </p>
            <p>
              Those interested in Registering must first accept its terms and conditions.      
            </p>
            <p>
              Once they have registered, they must complete their details and keep them updated. These data will not be made public, they are only for the Site domain. 
            </p>
            <p>
              Users of the Site may publish offers of all types of Products or Services, leaving them well described and the possibility of accessing them, whether by telephone, email, Website or Address. Remember that we do not intervene between potential consumers and those who offer. Whether or not you can access their offers depends on who offers.
            </p>
            <p>
              Users who make offers will be responsible for their quality and veracity and those who access them will be responsible for verifying them and free to use them or not.
            </p>
            <p>
              Users will also be able to give their opinion on Sites commonly used by the Cuban community and even include sites that they consider useful and about which they wish to give their opinion. The data entered must be real and exact.
            </p>
            <p>
              The language used must be correct, the opinions serious and in a decent and non-offensive manner, even when negative opinions are conveyed.
            </p>
            <p className='advice-title' style={{fontWeight:'bold'}}>
              Rights and Obligations of the Site Administration.          
            </p>
            <p>
              The Site Administration is not responsible for the opinions expressed by its Users or for the quality of the Services and Products that are published. These opinions do not reflect the feelings of the Administration.    
            </p>
            <p>
              The Site Administration reserves the right to delete opinions or offers that are considered offensive, illegal or indecent.            
            </p>
            <p>
              If any data provided by a User is incorrect or not acceptable, the site may correct it and will send communication to the User.
            </p>
            <p>
              If a User is detected who does not comply with the established rules, they may be blocked or eliminated, after notifying the User.  
            </p> 
            <p>
              Although we have implemented all appropriate measures to guarantee security in this matter, we do not control or guarantee the absence of viruses or other elements in the contents of this website.   
            </p>   
            <p>
              AvenidaCuba is exempt from any liability for damages of any kind that may be due to the presence of viruses in the content that may cause alterations in the User's computer system, electronic documents, files or hardware. We have no obligation to control, and we do not control, the use that Users make of this Website, the services that this Website provides, or its contents. We do not guarantee that Users use the Website, its services and its contents in accordance with the Terms and Conditions set forth herein, nor that they do so in a diligent and prudent manner.
            </p>
            <p>               
              They also have no obligation to verify, and do not verify, the identity of the Users of this Website; nor the veracity of the data that they provide about themselves or their products and services offered.  
            </p>
            <p>
              Any suggestion, question or complaint can be sent to our email. Your email will be answered and your complaint or suggestion will be evaluated and attended to.
            </p>         
            <p>
              {texts.ter11} <Mailto email='avenidacuba2024@gmail.com' subject='Sugerencia, Duda o Queja de Cliente' body='Para: Customers Service - Web Site AvenidaCuba.'>avenidacuba2024@gmail.com</Mailto> {texts.ter12} 
            </p>
            <p>        
              This document was last reviewed on July 31, 2024.
            </p> 
            <p>AvenidaCuba Site Administration </p>
          </div>
        }
    </div>
    
  </Row>
</Container>   

    
  )
}

export default Conditions
