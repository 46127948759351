import React from 'react'
import Layout from './components/Layout'
import './app.css'
const App = () => {
  return (
    <body>
      <Layout />
    </body>
  )
}
export default App
