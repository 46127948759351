import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import LanguageContext from '../context/LanguageContext'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
//import { motion } from 'framer-motion'
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css'
import { ImEye } from 'react-icons/im'
import { FaFacebook, FaInstagramSquare, FaTelegram } from 'react-icons/fa'
import {FaSquareXTwitter} from 'react-icons/fa6'
import GetRatingTotal from '../components/GetRatingTotal.js'
import noPhotos from '../img/shop/nofotos.jpg'

const URI = `${process.env.REACT_APP_BACKEND}`

const SitesCard = ({ item }) => {
  // SETTING VARIABLES

  // IMAGES
  const [image, setImage] = useState('')
  const { texts} = useContext(LanguageContext)
  //Show images
  useEffect(() => {
    AOS.init()
    if (item.sitesPicId !== 0) {
      axios
        .get(URI+'images/'+item.sitesPicId)
        .then((res) => {   
          setImage(URI+res.data)
        })
        .catch(err=> {
          setImage(noPhotos)
        })
    }
    else {
      setImage(noPhotos)
    }
     //eslint-disable-next-line
  }, [])

  return (
    <>
      <Link to={`/sites/sitesin/${item.id}`}>
        <Card
          className="m-2 sites-card card-shadow"
          data-aos="zoom-in"
          data-aos-delay="10"
        >
          <Card.Header className="sites-cheader">
            <h1>{item.sitesName}</h1>

            <p>{texts.idiom ==='es' ? item.sitesSubt: item.sitesSubtEn}</p>
          </Card.Header>
          <Card.Img
            variant="top"
            src={image}
            className="sites-pic"
          />
        </Card>
      </Link>
      <Card className='card-noborder'>
        <Row className="sites-cfooter ">
          <Col className="ms-2 me-2">
            <a
              href={item.sitesUrl}
              title={item.sitesUrl}
              target="_blank"
              rel="noreferrer"
              className="me-2"
            >
              <ImEye />
            </a>
            <a
              href={item.sitesFacebook}
              title={item.sitesFacebook}
              target="_blank"
              rel="noreferrer"
              className="me-2"
            >
              <FaFacebook />
            </a>
            <a
              href={item.sitesInstagram}
              title={item.sitesInstagram}
              target="_blank"
              rel="noreferrer"
              className="me-2"
            >
              <FaInstagramSquare />
            </a>
            <a
              href={item.sitesX}
              title={item.sitesX}
              target="_blank"
              rel="noreferrer"
              className="me-2"
            >
              <FaSquareXTwitter />
            </a>
            <a
              href={item.sitesTelegram}
              title={item.Telegram}
              target="_blank"
              rel="noreferrer"
              className="me-2"
            >
              <FaTelegram />
            </a>
          </Col>
          <Col>
            <GetRatingTotal
              propsType="site"
              propsId={item.id}
              yesComment={false}
              onlyStars={true}
              withEvals={false}
            />
          </Col>
        </Row>
      </Card>

    </>
  )
}

export default SitesCard

