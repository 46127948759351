import axios from 'axios'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// para scroll al inicio de la pantalla, instale en clien react-scroll
import {Image } from 'react-bootstrap'
import { animateScroll as scroll } from 'react-scroll'

//import Checkout from '../../shop/CheckOut'
import '../admin.css'

import sp from '../../img/navbar/spanish.png'
import en from '../../img/navbar/english.png'

const URI = `${process.env.REACT_APP_BACKEND}`

const CompCreateType = () => {
  //scroll al inicio
  scroll.scrollToTop()

  const [vtypeId, setTypeId] = useState('')
  const [vtypeName, setTypeName] = useState('')
  const [vtypeNameEn, setTypeNameEn] = useState('')
  const [vtypeProd, setTypeProd] = useState('true')
  const navigate = useNavigate()

  const store = async (e) => {
    e.preventDefault()
    await axios.post(URI + 'type/', {
      typeId: vtypeId,
      typeName: vtypeName,
      typeNameEn: vtypeNameEn,
      typeProd: vtypeProd,
    })
    navigate('/admin/type/all')
  }

  return (
    <div>

      <div className="container-elements">
        <div className="row">
          <div className="col offset-sm-1">
            <div className="header-elements">
              Adicionando Tipo de Producto/Servicio
            </div>
            <form onSubmit={store}>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Tipo:</label>
                <div className="col-sm-6">
                <span>
                  <Image
                    src={sp} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <input
                    value={vtypeName}
                    onChange={(e) => setTypeName(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label"></label>
                <div className="col-sm-6">
                <span>
                  <Image
                    src={en} 
                    alt='English'
                    title='English'
                  />{' '}
                  <input
                    value={vtypeNameEn}
                    onChange={(e) => setTypeNameEn(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-10 offset-sm-2">
                  <div className="form-check">
                    <label className="form-check-label">
                      CONTIENE PRODUCTOS:
                      <input
                        value={vtypeProd}
                        onChange={(e) => setTypeProd(!vtypeProd)}
                        checked={vtypeProd}
                        type="checkbox"
                        className="form-check-input"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 ">
                  <button type="submit" className="btn btn-primary mt-2 mb-2">
                    Guardar
                    <i className="bi bi-check2-circle" aria-hidden="true"></i>
                  </button>
                  <Link
                    to="/admin/type/all"
                    className="btn btn-danger mt-2 mb-2"
                  >
                    Cancelar
                    <i className="bi bi-x-circle" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompCreateType
