import React, { useState } from 'react'
import { Alert} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

/*
mess: el mensaje
variant: danger, success, info, warning...
navig:
  si es 'close'- pone la ventana con X para cerrarla, elimina el mensaje y te deja en el mismo lugar.
  si es '-1' hace un retroceso atras cuando termina el tiempo
  si es '/..........' va a esa direccion cuando termina el tiempo

timetogo: el tiempo que demora antes de cerrar si no dice close en navig
*/

export const TimerMessage = (props) => {
  const [show, setShow] = useState(true)
  /*const [close, setClose] = useState(
    props.navig === 'close' ? 'onClose={() => setShow(false)} dismissible' : '',
  )*/
  const navigate = useNavigate()
  const closeIt = () => {
    props.navig
      ? setTimeout(
          () => navigate(props.navig === 'back' ? -1 : props.navig),
          parseInt(props.timetogo),
        )
      : setTimeout(parseInt(props.timetogo))
  }
  return (
    <>
      {props.navig === 'close' ? (
        <Alert
          show={show}
          className={`text-${props.variant}`}
          style={{
            margin: '1rem',
            textAlign: 'center',
            background:'#fbfbfb',
          }}
          onClose={() => setShow(false)}
          dismissible
        >
          {props.mess}
        </Alert>
      ) : (
        <Alert
          show={show}
          className={`text-${props.variant}`}
          style={{
            margin: '1rem',
            textAlign: 'center',
            background:'#fbfbfb',
          }}
        >
          {props.mess}
        </Alert>
      )}
      {props.navig !== 'close' && closeIt()}
    </>
  )
}
