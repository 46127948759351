import React, { useState, useContext } from 'react'
import { useNavigate} from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import {
  Row,
  Col,
  Button,
  Form,
  Spinner,
} from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa6'
import axios from 'axios'
import { TimerMessage } from '../components/TimerMessage'

const URI = `${process.env.REACT_APP_BACKEND}`

const PwdChange = (props) => {
  const {texts} = useContext(LanguageContext)
  const [agree, setAgree] = useState(false)
  const {setEmail , email } = useContext(AuthContext)
  const [passwordNew, setPasswordNew] = useState('')
  const [showPwdNew, setShowPwdNew] = useState(false)
  const [showPwdAgain, setShowPwdAgain] = useState(false)
  const [passwordAgain, setPasswordAgain] = useState('') 
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [topass, setTopass] = useState(null)

  const initialForm = {
    userid:  props.propsId,
    usermail: email,
    password: '',
  }
  const [form, setForm] = useState(initialForm)

  const validationsForm = (form) => {
      let errors = {}
          
      if (!form.password) {
        errors.password = texts.errReq
      }
      if (!passwordAgain) {
        errors.passwordAgain=texts.errReq
      }
      if (form.password !== passwordAgain) {
        errors.passwordAgain = texts.errDif
      }
      return errors
    }   

  const doNothing = (e) => {
    e.preventDefault()
  }
/*
  const handlePasswordAgain = async (e) => {
    if (passwordAgain === passwordNew) {
      setAgree(true)
    } else errors.password = `Dif: 1:${passwordNew}/2:${passwordAgain}`
  }
    */
  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }
  const changePass = async (inputs) => {
    await axios.post(URI + 'auth/pwdchange', inputs)
   }

    const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors(validationsForm(form))
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      setTopass('')
      try {
       // console.log(`antes de changePass: ${form}`)
        await changePass(form)   
        //navigate('/auth/login')    
        setLoading(false)
        setResponse(true)
        return
//          
      } catch (error) {
        if (error.toString().indexOf('402') !== -1) 
          setTopass(texts.errChangePass)
       
        setLoading(false)
        setResponse(false)
      } 
      setLoading(false)
      setResponse(false)
    }
  }

     

  return (
  <div>   
    <Form onSubmit={doNothing}>  
      <div>
        <Row>
          <Col className="auth-label">Nueva Contraseña:</Col>
        </Row>
        <Row>
          <Col className="auth-icontext">
            <input
              className="form-control inputstl auth-input"
              type={showPwdNew ? 'text' : 'password'}
              autoComplete="off"
              name="password"
              placeholder="Su nueva contraseña..."
              //onBlur={handleBlur}
              onChange={handleChange}
              value={form.password}
              required
            />

            <span
              className="icon-inside"
              onClick={() => setShowPwdNew(!showPwdNew)}
            >
              {showPwdNew ? <FaEye /> : <FaEyeSlash />}
            </span>
          </Col>
        </Row>
        <Row>
            {errors.password && (
              <p className="auth-err">{errors.password}</p>
            )}
        </Row>
        <Row>
          <Col className="auth-label">
            Repetir Nueva Contraseña:
          </Col>
        </Row>
        <Row>
          <Col className="auth-icontext">
            <input
              className="form-control inputstl auth-input"
              type={showPwdAgain ? 'text' : 'password'}
              autoComplete="off"
              name="passwordAgain"
              placeholder="Repita su nueva contraseña..."
              onChange={(e) => {
                setPasswordAgain(e.target.value)
              
              }}
              value={passwordAgain}
              required
            />

            <span
              className="icon-inside"
              onClick={() => setShowPwdAgain(!showPwdAgain)}
            >
              {showPwdAgain ? <FaEye /> : <FaEyeSlash />}
            </span>
          </Col>
        </Row>                          
        <Row>
          {errors.passwordAgain && (
            <p className="auth-err">{errors.passwordAgain}</p>
          )}
        </Row>
      </div>        
    </Form>                                  
    <Button
      onClick={handleSubmit}
      className="auth-btnini"
    >
      {loading && (
        <Spinner
          style={{ textAlign: 'center' }}
          size="sm"
          animation="border"
          variant="light"
        />
      )}{' '}
      Cambiar Contraseña
    </Button>            
    <Row>
        <Col>
        {response &&
              <TimerMessage
                mess={
                  'Contraseña Cambiada!.'
                }
                variant={'success'}                
                navig={'/home'}
                timetogo="1200"
              />
        }
        </Col>
      </Row>
  </div>
  )
}

export default PwdChange

