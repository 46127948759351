import React, { useState, useContext } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import logo from '../img/logo/logowhite.png'
import { Link, useParams, useNavigate } from 'react-router-dom'
import {
  NavDropdown,
  Container,
  Row,
  Col,
  Card,
  Image,
  Modal,
  Button,
} from 'react-bootstrap'
import {
  FaUserCheck,
  FaUserSlash,
  FaClipboard,
  FaClipboardList,
  FaTools,
  FaUserEdit,
  FaEdit,
  FaGlobeAmericas,
  FaCity,
} from 'react-icons/fa'
import { RxDropdownMenu } from 'react-icons/rx'
import { SiJsonwebtokens } from 'react-icons/si'
import { MdWeb } from 'react-icons/md'
import { CgWebsite } from "react-icons/cg";
import { TbWorldWww } from "react-icons/tb";

import { MdOutlineViewCarousel } from 'react-icons/md'
import '../authentication/auth.css'
import '../admin/admin.css'
import { TimerMessage } from '../components/TimerMessage'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'

//import Admin from '../admin/Admin'

const UserOptions = () => {
  const { lasturl } = useParams()
  const { texts} = useContext(LanguageContext)
  const [response, setResponse] = useState(null)
  const [sureLogout, setSureLogout] = useState(false) 

 // const [err, setErr] = useState(null)
  const [mess, setMess] = useState(null)
  const [variant, setVariant] = useState(null)
//  const { login } = useContext(AuthContext)

  //NAVEGACION
  const navigate = useNavigate()
  // ir a una pagina especifica
  /*const goToSomePage = () => {
    navigate(lasturl)
  }
  // ir atras
  const goBack = () => {
    navigate(-1)
  }*/

  //offcanvas
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const toggleShow = () => setShow((s) => !s)
/*
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  })
*/
  const { currentUser, logout } = useContext(AuthContext)
  let vuseraccess =
    currentUser?.dataValues.access != null ? currentUser?.dataValues.access : ''
  /*
    const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (inputs)
      try {
        await login(inputs)
        goToSomePage('/home')
      } catch (error) {
        setErr(error.response.data)
      }
    else {
      setErr('Faltan datos por completar, Revise!!')
    }
  }

  const registration = () => {
    navigate('/auth/registration')
  }
*/
 /* const handlePwdChange = () => {
    navigate('/auth/pwdchange')
  }
*/
  const handlelogout = () => {
    setSureLogout(false)
    logout(currentUser?.dataValues.id,texts.idiom)
    setMess(texts.auClosed)
    setVariant('warning')
    setResponse(true)
  }

  return (
    <Container fluid className="auth pt-2">
    
      <Container fluid className="auth-card">
        <Row>
          <Col> <Image
                    src={logo} 
                    alt={texts.title}
                    title={texts.title}
                  />{' '} 
                </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header className="auth-card-header">
                <div style={{ display: 'flex' }}>
                  {texts.auUser}:{' '}
                  <span
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: 'lighter',
                      color: '#daa520',
                    }}
                  >
                    <FaUserCheck />
                    &nbsp; {currentUser?.dataValues.userName}
                  </span>
                </div>
              </Card.Header>
              <Card.Body className="auth-card-body ">
                <span style={{ fontWeight: 'bolder' }}>{texts.auOpt}...</span>
                <NavDropdown.Item onClick= {()=>setSureLogout(true)} className="mt-2">
                  <FaUserSlash />
                  &nbsp; {texts.auQuit}
                </NavDropdown.Item>
                <NavDropdown.Item href="/auth/profile" className="mt-2">
                  <FaUserEdit />
                  &nbsp; {texts.auComplete}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="/shop/shophome/serviceadd"
                  className="mt-2"
                >
                  <FaEdit />
                  &nbsp;{texts.auNewOffer}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`/shop/shophome/1/${currentUser?.dataValues.id}`}
                  className="mt-2"
                >
                  <FaClipboardList />
                  &nbsp; {texts.auOnlyMine}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`/shop/shophome/0/${currentUser?.dataValues.id}`}
                  className="mt-2"
                >
                  <FaClipboard />
                  &nbsp; {texts.auNewOfferNotPub}
                </NavDropdown.Item>
                <NavDropdown.Divider />  

                <NavDropdown.Item
                  href="/sites/siteadd"
                  className="mt-2"
                >
                  <TbWorldWww />
                  &nbsp;{texts.auNewSite}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`/sites/1/${currentUser?.dataValues.id}`}
                  className="mt-2"
                >
                  <CgWebsite />
                  &nbsp; {texts.auOnlyMineSite}
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`/sites/0/${currentUser?.dataValues.id}`}
                  className="mt-2"
                >
                  <MdWeb />
                  &nbsp; {texts.auNewSiteNotPub}
                </NavDropdown.Item>
               
                <NavDropdown.Divider />                               
                {vuseraccess === 'admin' ? (
                  <NavDropdown.Item onClick={toggleShow} className="mt-2">
                    <FaTools />
                    &nbsp; Dashboard
                  </NavDropdown.Item>
                ) : (
                  true
                )}
                 <NavDropdown.Divider />                               
                {vuseraccess === 'admin' ? (
                  <NavDropdown.Item
                  href={`/shop/shophome/0/0`}
                  className="mt-2"
                  >
                    <FaClipboard />
                    &nbsp; {texts.auNewOfferNotPubAdmin}
                  </NavDropdown.Item>
                ) : (
                  true
                )}
                {vuseraccess === 'admin' && (
                <NavDropdown.Item
                  href={`/sites/0/0`}
                  className="mt-2"
                >
                  <MdWeb />
                  &nbsp; {texts.auNewSiteNotPubAdmin}
                </NavDropdown.Item>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="auth-advice">
            <Link to={'/conditions'}>
              {texts.auTerm}
            </Link>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col>
          {response && (
            <TimerMessage
              mess={mess}
              variant={variant}
              navig={'/home'}
              timetogo="1000"
            />
          )}
        </Col>
      </Row>
      <Offcanvas
        className="left-menu"
        show={show}
        onHide={handleClose}
        scroll={true}
        backdrop={true}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>DashBoard AvenidaCuba</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/country/all">
                  <div className="footer-links-nav">
                    <FaGlobeAmericas
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Countries
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/city/all">
                  <div className="footer-links-nav">
                    <FaCity
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Cities
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/carousel/all">
                  <div className="footer-links-nav">
                    <MdOutlineViewCarousel
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Carousel
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/diary/all">
                  <div className="footer-links-nav">
                    <RxDropdownMenu
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Diary Surprise
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/theme/all">
                  <div className="footer-links-nav">
                    <MdOutlineViewCarousel
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Themes
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/type/all">
                  <div className="footer-links-nav">
                    <RxDropdownMenu
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Service's Type
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex">
                <Link to="/admin/site/all">
                  <div className="footer-links-nav">
                    <MdWeb
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    <SiJsonwebtokens
                      style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                    />{' '}
                    Sites
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
            <Link href="https://www.contadorvisitasgratis.com" title="contador de visitas"><Image src="https://counter4.optistats.ovh/private/contadorvisitasgratis.php?c=efl11emhaqzbz811jaqfu6uqd8upzadm" border="0" title="contador de visitas" alt="contador de visitas"/></Link>
            </Row>
          </div>
          
        </Offcanvas.Body>
      </Offcanvas>
      <Modal centered size='sm' show={sureLogout} onHide={()=>setSureLogout(false)} animation={false}>
                     
                     <Modal.Header className='modal-user'>{texts.userCloseQuestion}</Modal.Header>
                     <Modal.Footer className='modal-user'>
                       <Button variant="warning" onClick={()=>setSureLogout(false)}>
                       {texts.userCloseNo}
                       </Button>
                       <Button variant="secondary" onClick={handlelogout}>
                         {texts.userCloseYes}
                       </Button>
                      
                     </Modal.Footer>
          </Modal>
    </Container>
  )
}

export default UserOptions


/*
 <NavDropdown.Item href="/admin/admin" className="mt-2">
                    <FaTools />
                    &nbsp; Dashboard
                  </NavDropdown.Item>
                ) : (
                  true
                )}
*/
