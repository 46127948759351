import React, { useState, useEffect, useContext } from 'react'
import '../shop/shopHome.css'
import axios from 'axios'
import ServiceCard from './ServiceCard'
import SearchContext from '../context/SearchContext'
import LanguageContext from '../context/LanguageContext'

const URI = `${process.env.REACT_APP_BACKEND}`

const Service = (props) => {
  const { search, searchId } = useContext(SearchContext)
  const { texts } = useContext(LanguageContext)
  const [sales, setSales] = useState([])

  useEffect(() => {
    getSales()
     //eslint-disable-next-line
  }, [search]) 

  const getSales = async () => {
    const res = await axios.get(URI + 'sales')
    let resfilt = res.data.filter(
      (item) =>
        item.published == parseInt(props.published) &&
        (parseInt(props.user) != 0
          ? item.user == parseInt(props.user)
          : true) &&
        (search != '' && search != null
          ? item.title.toLowerCase().includes(search) ||
            item.titleEn.toLowerCase().includes(search) ||
            item.descrip.toLowerCase().includes(search) ||
            item.bolderText.toLowerCase().includes(search) ||
            item.descripEn.toLowerCase().includes(search) ||
            item.workTimeEn.toLowerCase().includes(search) ||
            item.workTime.toLowerCase().includes(search) ||
            item.descripEn.toLowerCase().includes(search) || 
            item.country.countryName.toLowerCase().includes(search) ||
            item.city.cityName.toLowerCase().includes(search) ||
            item.type.typeName.toLowerCase().includes(search)
          : true) &&
          (searchId != 0 ? item.typeId === searchId: true),
    )
    setSales(resfilt.sort((a,b) => a.type.typeName.localeCompare(b.type.typeName))  
    )
  }
  return (
    <div
      className="pb-3 center-class"
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
       {(typeof sales === "undefined" || sales.length === 0 || sales === null) ?
        <div>
              {texts.noFind}       
        </div>
      :
      
        sales.map((item) => (
          <div key={item.id}>

            <ServiceCard item={item} />
          </div>
      ))
    }
    </div>
  )
}

export default Service


