import { useState, useContext, useEffect, useRef } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, Form, Container, Row, Col, Image, Card, Button, Spinner, FloatingLabel } from 'react-bootstrap'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import './sites.css'
import Swal from 'sweetalert2'
import { 
  FaRegWindowClose,
  FaRegCheckSquare,
  FaRegImages,
  FaTelegram, 
  FaFacebook, 
  FaInstagramSquare
 } from 'react-icons/fa'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { FaRegTrashCan, FaRegEnvelope, FaSquareXTwitter } from 'react-icons/fa6'
import { GiLaptop } from "react-icons/gi"
import spa from '../img/navbar/spanish.png'
import eng from '../img/navbar/english.png'
import shopinitop from '../img/shop/frontshop.png';
import { useFormSite } from '../hooks/useFormSite'
import { TimerMessage } from '../components/TimerMessage'
import noPhotos from '../img/shop/nofotos.jpg'
import AOS from 'aos'
import 'aos/dist/aos.css'

const URI = `${process.env.REACT_APP_BACKEND}`
const URIMG = `${process.env.REACT_APP_BACKENDPUB}`

const initialForm = {
  sitesName: '',
  sitesSubt: '',
  sitesSubtEn: '',
  sitesText: '',
  sitesTextEn: '',
  sitesUrl: '',
  sitesMail: '',
  sitesFacebook: '',
  sitesInstagram: '',
  sitesTelegram: '',
  sitesX: '',
  sitesPic:'',
  sitesPicId: 0,
  sitesOrder:0,
  sitesPublished:true,  
  userId:0,
}

const validationForm = (form,lang) => {
  let errors = {}
  //expresiones regulares para cada tipo de campo
  //let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
  let regexMail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
  let regexText100 = /^.{1,100}$/
  let regexText150 = /^.{1,150}$/
  let regexText255 = /^.{1,255}$/
  let regexText500 = /^.{1,500}$/
  //let regexText1000 = /^.{1,1000}$/
  
  if (form.sitesMail && !regexMail.test(form.sitesMail)) {
    errors.sitesMail = '📍 El email posee caracteres no admitidos'
  } else if (
    form.sitesMail &&
    !regexText255.test(form.sitesMail)
  ) {
    errors.sitesMail = '📍 Máximo 255 caracteres'
  }
  
  if (form.sitesFacebook && !regexText255.test(form.sitesFacebook)) {
    errors.sitesFacebook = '📍 Máximo 255 caracteres...'
  }
  if (form.sitesInstagram &&!regexText255.test(form.sitesInstagram)) {
    errors.sitesInstagram = '📍 Máximo 255 caracteres...'
  }
  if (form.sitesTelegram && !regexText255.test(form.sitesTelegram)) {
    errors.sitesTelegram = '📍 Máximo 255 caracteres...'
  }
  if (form.sitesX && !regexText255.test(form.sitesX)) {
    errors.sitesX = '📍 Máximo 255 caracteres...'
  }
   
  if (!form.sitesName) {
    errors.sitesName = 'El Nombre es requerido...'
  } else if (!regexText100.test(form.sitesName)) {
    errors.sitesName = '📍 Máximo 100 caracteres...'
  }
  if (!form.sitesUrl) {
    errors.sitesUrl = 'La Dirección del Sitio requerido...'
  } else if (!regexText500.test(form.sitesUrl)) {
    errors.sitesUrl = '📍 Máximo 500 caracteres...'
  }
  if (lang === 'es')
    {
      if (!form.sitesSubt) {
        errors.sitesSubt = 'El Resumen es requerido...'
      } else if (!regexText150.test(form.sitesSubt)) {
        errors.sitesSubt = '📍 Maximo 150 caracteres...'
      }
      
      if (!form.sitesText) {
        errors.sitesText = 'El Contenido es requerido...'
      //} else if (!regexText1000.test(form.sitesText)) {
      } else if (form.sitesText.lenght > 1000) {
        errors.sitesText = '📍 Maximo 1000 caracteres...'
      }
      
    }
  if (lang === 'en')
    {
      if (!form.sitesSubtEn) {
        errors.sitesSubtEn = 'The Summary is required...'
      } else if (!regexText150.test(form.sitesSubtEn)) {
        errors.sitesSubtEn = '📍 Maximum 150 characters...'
      }
      if (!form.sitesTextEn) {
        errors.sitesTextEn = 'The Content is required...'
      //} else if (!regexText1000.test(form.sitesTextEn)) {
      } else if (form.sitesTextEn.lenght > 1000) {
        errors.sitesTextEn = '📍 Maximum 1000 characters...'
      }
    } 
    console.log(errors)
  return errors
}

const SiteUpd = () => {
  const { texts} = useContext(LanguageContext)
  const { currentUser } = useContext(AuthContext)
  const [bigImage, setBigImage] = useState(null)
  //const [notPublished, setNotPublished] = useState(false)
  //const [vpublished, setPublished] = useState('true')
  const firstDiv = useRef()
  const hiddenFileInput = useRef(null)
  const navigate = useNavigate()
  const { id } = useParams()
  const goBack = () => {
    navigate(-1)
  }
  useEffect(() => {
    firstDiv.current.focus()
    AOS.init()
  }, [])

  //evitar salir del form con esc 27, enter 13  o backspace 8
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('input[type=text]').forEach((node) =>
      node.addEventListener('keypress', (e) => {
        if (e.keyCode === 13 || e.keyCode === 8 || e.keyCode === 27) {
          e.preventDefault()
        }
      }),
    )
  })
  let typeForm = 'updType'

  const {
    form,
    setForm,
    errorsEnd,
    loading,
    responseForm,
    handleChange,
    handleCheck,
    handleSubmit,
  } = useFormSite (initialForm, validationForm, typeForm, id, texts.idiom)
  
 // form.userId = parseInt(currentUser?.dataValues.id)


  /*const goToSomePage = () => {
    navigate('/sites/1/0')
  }*/

  const getSiteById = async () => {
    const res = await axios.get(URI + 'site/' + id)
    setForm(res.data)      
      showImage(res.data.sitesPicId)
  }
  useEffect(() => {
    AOS.init()
    getSiteById()
     //eslint-disable-next-line
  }, [])

  const goToSiteShow = () => {
    navigate(`/sites/sitesin/${id}`)
  }
  const serviceCancel = () => {
    goBack()
  }

  const doNothing = (e) => {
    e.preventDefault()
  }

  const handlerClick = (event) => {
    hiddenFileInput.current.click()
  }

  const showImage = async (imgToShow) => {
     await axios
      .get(URI + 'images/'+imgToShow)
      .then((res) => {
        if (res.data.length > 0)
          setBigImage(res.data)
        else
          setBigImage(noPhotos)
        })
      .catch((err) => {
        console.error(err)
        //setBigImage(noPhotos)
      })  
  }
  const handleAddImage = async (e) => {
    if (e.target.files[0]) {
        console.log(e.target.files[0])
        const imgFormData = new FormData()
        imgFormData.append('image', e.target.files[0])
        e.preventDefault()
        await axios({
          method: 'post',
          url: URI + 'images',
          data: imgFormData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(function (response) { 
            showImage(parseInt(response.data.split('-')[0]))
            form.sitesPicId =  parseInt(response.data.split('-')[0])   
          })
          .catch(function (response) {
            Swal.fire(
              '',
              'IMAGEN 1 no adicionada, vuelva a intentarlo!',
              'error',
            )
          })
    } else console.log('no se selecciono ningun file')
    
  }
  const handlerDeleteImage = async(cImage) => {
    let imageId = parseInt(cImage.split('-')[0])
    setBigImage(noPhotos)
    await axios.delete(`${URI + 'images/'}${imageId}`)
  }
  return (
    <div  className="sites gradient-lightblue " tabIndex={-1} ref={firstDiv}>
      <Container fluid className="sites gradient-lightblue ">
        <Row>
          <Col lg={12} className="section-title" >
            <h1
              className="pt-4"
              data-aos="fade-up"
              data-aos-delay="10"
              style={{ fontSize: '0.9rem' }}
            >
              {texts.siInfo}
            </h1>
            <h3
              style={{ fontFamily: 'fantasy' }}
              data-aos="fade-down"
              data-aos-delay="10"
            >
              {texts.siSubtUpd}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="center"> 
          {currentUser ? (
            <div>
              <div className="center-class mb-3">
              <Alert variant="light" className="mt-2 important-advice" style={{background:'#deebf6'}}>
                  <Alert.Heading style={{ fontSize: '0.9rem' }}>
                    {texts.seAdviceHeader}:
                  </Alert.Heading>
                    <p
                      style={{
                        fontSize: '0.8rem',
                        fontStyle: 'italic',
                        color: '#7e7c7c',
                        fontWeight: 'normal',
                        textAlign:'center'
                      }}
                    >
                      {texts.seAdviceBodySite}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[{texts.seAdviceFooter}]
                    </p>
                    

                </Alert>
                <Image 
                    src={shopinitop}  className="img-fluid shophome-img small-animated " 
                    alt=""
                />
            </div>
            <Form onSubmit={doNothing}> 
              <Card className='card-noborder'>
                <Card.Header className="service-inter">
                  <Row>
                  <Col lg={12} className='d-flex justify-content-center'>
                          <Button 
                            size="sm" 
                            onClick={goToSiteShow}
                            variant="link"
                            className='ioarrow me-5'
                          >
                           
                              <IoArrowUndoOutline
                                    style={{ fontSize: '1rem', color: '#641e02' }}
                                    title={texts.backPage}
                              />
                            
                          </Button>

                        <p>
                          <Button
                           className="me-3 button-shadow"
                           size="sm"
                           variant="warning"
                           onClick={serviceCancel}
                           title={texts.cancel}
                          >
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaRegWindowClose
                               style={{ fontSize: '1.2rem' }}
                              />
                              {texts.seCancel}
                            </div>
                          </Button>
                          <Button
                            className="me-3 button-shadow"
                            size="sm"
                            onClick={handleSubmit}
                            variant="success"
                            title={texts.update}
                          >
                            {loading && (
                              <Spinner
                                style={{ textAlign: 'center' }}
                                size="sm"
                                animation="border"
                                variant="light"
                              />
                            )}
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaRegCheckSquare
                                style={{ fontSize: '1.2rem' }}
                              />
                              {texts.siUpdAct}
                            </div>
                          </Button>
                        </p>
                  </Col>
                </Row>
              </Card.Header>
          <Card.Body className="p-3">
              <Row>
               
                <Col>                       
                    <div className='d-flex section-city'>
                      {texts.idiom ==='es' ?                            
                            <p  style={{fontSize:'0.9rem',fontStyle:'italic'}}>
                                  Su información se introduce  en Español,{' '}     
                                    <img
                                      src={spa}
                                      alt=""
                                      title="Idioma"
                                      className="navicon"
                                    /> 
                             
                                  
                                  {' '} para adicionar en Inglés también, cambie el Idioma del Sitio y vuelva a Editarla en ese Idioma... 
                              </p>
                         
                              :
                              
                              <p  style={{fontSize:'0.9rem',fontStyle:'italic'}}>
                                Your data is adding in English,{' '}     
                                    <img
                                      src={eng}
                                      alt=""
                                      title="English"
                                      className="navicon"
                                    /> 
                             
                                  
                                  .{' '} To add it in Spanish too, change the site language and edit it again...
                                  
                              </p>
                              
                          }
                        </div>
                  </Col>
                </Row>
                <Row>
                 
                  <Col>  
                    
                      {responseForm === true && (
                        <TimerMessage
                        mess={texts.seUpdService}
                          variant={'success'}
                          navig={'../sites/1/0'}
                          timetogo="1000"
                        />
                      )}
                
                  </Col>
                </Row>
                <Row>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <Form.Group>
                      <FloatingLabel label={
                        <span>*{' '}{texts.seSitesName}</span>
                      }>
                          <Form.Control
                            name="sitesName"
                            className="service-title mb-2"
                            value={form.sitesName}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesName && (
                              <p className="service-err">
                                {errorsEnd.sitesName}
                              </p>
                      )}                            
                  </Form.Group>
                  <Form.Group>
                      <FloatingLabel label={<span>*{' '}{texts.seSitesSubt}</span>}>
                          <Form.Control
                            name={texts.idiom === 'es' ? 'sitesSubt' : 'sitesSubtEn'}
                            className="service-title mb-2"
                            value={texts.idiom === 'es' ? form.sitesSubt : form.sitesSubtEn}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesSubt && (
                              <p className="service-err">
                                {errorsEnd.sitesSubt}
                              </p>
                      )}
                  </Form.Group>
                 
                    <Form.Group controlId="imgInput" className='mb-2'>
                      <Form.Control
                        type="file"
                        size="sm"
                        onChange={handleAddImage}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                      />
                      <Button
                        size="sm"
                        text="dark"
                        onClick={handlerClick}
                        variant="warning"
                        style={{ fontSize: '0.8rem' }}
                      >
                        <FaRegImages />
                        &nbsp;{texts.seNewImg}
                      </Button>
                    </Form.Group>
                  <Card>
  
                      {bigImage != null ? (
                          <p>
                            <Image
                              src={
                                bigImage.includes('nofoto')
                                  ? bigImage
                                  : URIMG + bigImage
                              }
                              style={{ width: '100%', height: '100%' }}
                              alt=""
                              fluid
                            />
                             {!bigImage.includes('nofoto') &&   
                              <div className="buttons-bigimage">                              
                                <Button
                                  className="button-shadow"
                                  size="sm"
                                  onClick={() => handlerDeleteImage(bigImage)}
                                  variant="danger"
                                  title={texts.seDelImg}
                                >
                                  <FaRegTrashCan
                                    style={{
                                      fontSize: '1rem',
                                      color: '#e3d8c3',
                                    }}
                                  />
                                </Button>
                              </div>
                              }
                          </p>
                        ) : (
                          <Image
                            src={noPhotos}
                            style={{ width: '100%', height: '100%' }}
                            alt=""
                            fluid
                          />
                        )}
                  </Card>
                  { (currentUser?.dataValues.access === 'admin') &&
                    <Row>
                      <Col xs={6} className="service-city pt-3">
                        <Form.Group>
                          <Form.Check
                              type="checkbox"
                              name="sitesPublished"
                              style={{ paddingTop: '0.4rem' }}
                              value={form.sitesPublished}
                              onChange={handleCheck}
                              checked={form.published}
                              label={texts.siPubSite}
                            />
                        </Form.Group>
                      </Col>
                   </Row> 
                }
                </Col>
                <Col lg={8} md={12} sm={12} xs={12}>
                     <Form.Group>
   
                            <FloatingLabel label={<span>*{' '}{texts.seSitesText}</span>}>                            
                              <Form.Control
                               name={texts.idiom === 'es' ? 'sitesText' : 'sitesTextEn'}
                               className="service-text mb-2"
                               style={{height:'10rem'}}                               
                               value={texts.idiom === 'es' ? form.sitesText : form.sitesTextEn}
                               onChange={handleChange}                    
                               as="textarea"
                               wrap="soft"
                               rows={10}
                              />
                            </FloatingLabel>
                            {errorsEnd.sitesText && (
                              <p className="service-err">
                                {errorsEnd.sitesText}
                              </p>
                            )}                   
                            {errorsEnd.sitesTextEn && (
                              <p className="service-err">
                                {errorsEnd.sitesTextEn}
                              </p>
                            )}
         
                        
                      </Form.Group>
                      <Form.Group>
                      <FloatingLabel label={
                        <span>*{' '}<GiLaptop />{' '}{texts.siUrl}</span>}>
                          <Form.Control
                            name="sitesUrl"
                            className="service-title mb-2"
                            value={form.sitesUrl}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesUrl && (
                              <p className="service-err">
                                {errorsEnd.sitesUrl}
                              </p>
                      )}
                  </Form.Group>
                  <Form.Group>
                      <FloatingLabel label={
                        <span><FaRegEnvelope />{' '}{texts.siEmail}</span>
                      }>
                          <Form.Control
                            name="sitesMail"
                            className="service-title mb-2"
                            value={form.sitesMail}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesMail && (
                              <p className="service-err">
                                {errorsEnd.sitesMail}
                              </p>
                      )}
                  </Form.Group>
                  <Form.Group>
                      <FloatingLabel label={
                        <span><FaFacebook />{` ${texts.facebook}`}</span>
                        }>
                          <Form.Control
                            name="sitesFacebook"
                            className="service-title mb-2"
                            value={form.sitesFacebook}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesFacebook && (
                              <p className="service-err">
                                {errorsEnd.sitesFacebook}
                              </p>
                      )}
                  </Form.Group>
                  <Form.Group>
                      <FloatingLabel label={
                        <span><FaInstagramSquare />{` ${texts.instagram}`}</span>
                        }>
                          <Form.Control
                            name="sitesInstagram"
                            className="service-title mb-2"
                            value={form.sitesInstagram}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesInstagram && (
                              <p className="service-err">
                                {errorsEnd.sitesInstagram}
                              </p>
                      )}
                  </Form.Group>
                  <Form.Group>
                      <FloatingLabel label={
                        <span><FaSquareXTwitter />{` ${texts.x}`}</span>}>
                          <Form.Control
                            name="sitesX"
                            className="service-title mb-2"
                            value={form.sitesX}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesX && (
                              <p className="service-err">
                                {errorsEnd.sitesX}
                              </p>
                      )}
                  </Form.Group>
                  <Form.Group>
                      <FloatingLabel label={
                        <span> <FaTelegram />{` ${texts.telegram}`}</span>}>
                          <Form.Control
                            name="sitesTelegram"
                            className="service-title mb-2"
                            value={form.sitesTelegram}
                            onChange={handleChange}                    
                            type="text"
                          />
                      </FloatingLabel>
                      {errorsEnd.sitesTelegram && (
                              <p className="service-err">
                                {errorsEnd.sitesTelegram}
                              </p>
                      )}
                  </Form.Group>
              </Col>  
            </Row>
            <Row><Col className='service-city'>{texts.fill}</Col></Row>
          </Card.Body>
          <Card.Footer className="mt-2">
          <Row>
              <Col lg={12} className='d-flex justify-content-center' >
              <Button 
                            size="sm" 
                            onClick={goToSiteShow}
                            variant="link"
                            className='ioarrow me-5'
                          >
                            
                              <IoArrowUndoOutline
                                    style={{ fontSize: '1rem', color: '#641e02' }}
                                    title={texts.backPage}
                              />
                            
                          </Button>  
                <p>
                          <Button
                           className="me-3 button-shadow"
                           size="sm"
                           variant="warning"
                           onClick={serviceCancel}
                           title={texts.cancel}
                          >
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaRegWindowClose
                               style={{ fontSize: '1.2rem' }}
                              />
                              {texts.seCancel}
                            </div>
                          </Button>
                          <Button
                            className="me-3 button-shadow"
                            size="sm"
                            onClick={handleSubmit}
                            variant="success"
                            title={texts.update}
                          >
                            {loading && (
                              <Spinner
                                style={{ textAlign: 'center' }}
                                size="sm"
                                animation="border"
                                variant="light"
                              />
                            )}
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaRegCheckSquare
                                style={{ fontSize: '1.2rem' }}
                              />
                              {texts.siUpdAct}
                            </div>
                          </Button>
                        </p>
                </Col>
                </Row>
              </Card.Footer>
            </Card>                  
          </Form>
          </div>  
        ) : (
          <TimerMessage
            mess={texts.RegisterSite}
            variant={'danger'}
            navig={'../sites/1/0'}
            timetogo="2000"
          />
        )}
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default SiteUpd

