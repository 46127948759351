import axios from 'axios'
import { createContext, useEffect, useState } from 'react'

const URI = `${process.env.REACT_APP_BACKEND}`

export const AuthContext = createContext()

export const AuthContexProvider = ({ children }) => {
  
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null,
  )

 

  //const [usrLang, setUsrLang] = useState('es')

  const [email, setEmail] = useState()
  const [otp, setOTP] = useState()
  const [showWhat, setShowWhat] = useState()

 
  const login = async (inputs) => {   
    const res = await axios.post(URI + 'auth/login', inputs)
        setCurrentUser(res.data) 
  }

  const logout = async (id,inputs) => {
      await axios.post(URI + 'auth/logout/' + id , {
      userLang: inputs, 
    })
    setCurrentUser(null)
  }

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(currentUser))

  }, [currentUser])

    return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, login, logout, otp, setOTP, setEmail, email, setShowWhat, showWhat }}>
      {children}
    </AuthContext.Provider>
  )
}
