import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Badge, Row, Container, Col, Form } from 'react-bootstrap'
import '../admin.css'
import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoArrowUndoOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`

const EditCity = () => {
  const [form, setForm] = useState([])
  const [vcountryId, setCountryId] = useState('')
  const [countryList, setCountryList] = useState([])
  // const [countryName, setCountryName] = useState('')

  const { id } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    getCountry()
  }, [vcountryId])
  const getCountry = async () => {
    const res = await axios.get(URI + 'country')
    setCountryList(res.data)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.put(URI + 'city/' + id, form)
    Swal.fire({
      title: 'Updated City!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/city/all')
  }

  useEffect(() => {
    getCityById()
     //eslint-disable-next-line
  }, [])

  const getCityById = async () => {
    const res = await axios.get(URI + 'city/' + id)
    setForm(res.data)
  }

  const handleSelectCountry = (e) => {
    setCountryId(e.target.value)
    // setCountryName(e.target.options[e.target.selectedIndex].text)
    handleChange(e)
  }

  const doNothing = (e) => {
    e.preventDefault()
  }

  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" href="/admin/city/all" variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Updating a City
          </h5>
          <Form onSubmit={doNothing}>
            <Form.Group>
              <Form.Label>Pais</Form.Label>
              <Form.Select
                name="countryId"
                className="service-city"
                onChange={handleSelectCountry}
                value={form.countryId}
              >
                {countryList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.countryName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                name="cityName"
                className="service-title"
                value={form.cityName}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  className="mt-2 mb-2"
                >
                  Save
                  <FaRegCheckCircle />
                </Button>
                <Button
                  variant="danger"
                  href="/admin/city/all"
                  className="mt-2 mb-2"
                >
                  Cancel
                  <IoCloseCircleOutline />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default EditCity
