import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Routers from '../routers/Routers'
import { SearchProvider } from '../context/SearchContext'
import { LanguageProvider } from '../context/LanguageContext'

const Layout = () => {
  return (
    <>
       <SearchProvider>
        <LanguageProvider>
          <Header />
          <div>
            <Routers />
          </div>
          <Footer />
        </LanguageProvider>
      </SearchProvider>
    </>
  )
}
export default Layout