import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import LanguageContext from '../context/LanguageContext'
import '../components/footer.css'
import { TfiWrite } from 'react-icons/tfi'
import logo from '../img/logo/logo.png'
import {
  FaArrowAltCircleUp,
  FaFacebook,
  FaInstagramSquare,
  FaHome,
  FaGlobeAmericas,
  FaStreetView,
} from 'react-icons/fa'
import {
  FaRegEnvelope,
  FaMobileScreen,
  FaSquareXTwitter,
} from 'react-icons/fa6'
import { BiMessageEdit, BiSolidDonateHeart } from 'react-icons/bi'
import { Container, Row, Col, Image } from 'react-bootstrap'

 
const Footer = () => {
  const { texts } = useContext(LanguageContext)

  const Mailto = ({ email, subject, body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };
  
  return (
    <>
      <div id="footer">
          <Container fluid>
            <Row className="footer-top p-3">
              <Col lg={2} md={12} sm={12} xs={12} className="mb-3 ps-3 footer-links">
                <h5>{texts.foLinks}</h5>
                <Row>
                  <Col className="d-flex">
                    <Link to="/home">
                      <div className="footer-links-nav">
                        <FaHome
                          style={{ fontSize: '1rem', paddingBottom: '3px' }}
                        />{' '}
                        {texts.heHome}
                      </div>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex">
                    <Link to="/sites/1/0">
                      <div className="footer-links-nav">
                        <FaGlobeAmericas
                          style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                        />{' '}
                        {texts.heSites}
                      </div>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex">
                    <Link to="/shop/shophome/1/0">
                      <div className="footer-links-nav">
                        <FaStreetView
                          style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                        />{' '}
                        {texts.heServices}
                      </div>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex">
                    <Link to="/shop/shophome/serviceadd">
                      <div className="footer-links-nav">
                        <TfiWrite
                          style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                        />{' '}
                        {texts.heOffer}
                      </div>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex">
                    <Link to="/sites/siteadd">
                      <div className="footer-links-nav">
                        <TfiWrite
                          style={{ fontSize: '0.8rem', marginBottom: '3px' }}
                        />{' '}
                        {texts.heSiteNew}
                      </div>
                    </Link>
                  </Col>
                </Row>
               
              </Col>
              <Col lg={3} md={12} sm={12} xs={12} className="footer-links mb-3">
                <h5>{texts.foContactUs}...</h5>
                <Row>
                  <Col>
                    <div className="mt-1">
                      <FaMobileScreen /> {texts.phone}: 555-5555
                    </div>
                    <div className="mt-2">
                      <FaRegEnvelope /> 
                      Email:
                        <Mailto email='avenidacuba2024@gmail.com' subject='' body=''>avenidacuba2024@gmail.com</Mailto> 
                    </div>
                    <div className="mt-2">
                      <Link to="/aboutus">
                        <BiMessageEdit /> {texts.foSendMess}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={7} md={12} sm={12} xs={12} className="footer-info">
                <Image
                src={logo} 
                alt={texts.title}
                title={texts.title}
                 />
                
                <Row className='mt-2'>
                  <Col>
                    <p>{texts.foAvenue}</p>

                    <div className="social-links mt-3 center">
                      <Link
                        href="#"
                        className="donation"
                        alt="Make a donation!!"
                      >
                        <BiSolidDonateHeart />
                      </Link>
                      <Link href="#">
                        <FaFacebook />
                      </Link>
                      <Link href="#">
                        <FaInstagramSquare />
                      </Link>
                      <Link href="#">
                        <FaSquareXTwitter />
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="footer-bottom">
                <Row>
                  <Col className="copyright">
                    &copy; Copyright{' '}
                    <strong>
                      <span>Avenida Cuba! 2024</span>
                    </strong>
                    . All Rights Reserved
                  </Col>
                </Row>
                <Row>
                  <Col className="credits">✧ Designed by YourBestSites ✧</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </Container>
        
      </div>

      <Link href="#" className="d-flex justify-content-end ">
        <FaArrowAltCircleUp className=" back-to-top" />
      </Link> 
    </>
  )
}

export default Footer

