import { useState } from 'react'
import axios from 'axios'

const URI = `${process.env.REACT_APP_BACKEND}`

export const useFormSite = (initialForm, validationForm, typeForm, id, lang) => {
  const [form, setForm] = useState(initialForm)
  const [errorsEnd, setErrorsEnd] = useState({})
  const [loading, setLoading] = useState(false)
  const [responseForm, setResponseForm] = useState(null)
  const [topass, setTopass] = useState(null)
  const [imgToDelete, setImgToDelete] = useState([])

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
    handleValidate()
  }
  const handleCheck = (e) => {
    const { name, value } = e.target
    
    if (e.target.checked) {
      setForm({
        ...form,
        [name]: 1,
      })
    } else {
      setForm({
        ...form,
        [name]: 0,
      })
    }
    handleValidate()
  }

  const handleValidate = () => {
    setErrorsEnd(validationForm(form,lang))
  }

  const handleBlur = (e) => {
   // handleChange(e)
     // setErrors(validationForm(form,lang))
  }
 

  const handleSubmit = async (e) => {
    e.preventDefault()
     if (Object.keys(errorsEnd).length === 0) {
      console.log(form)
      if (lang === 'es')
        {
         
          if (form.sitesSubtEn === '' || form.sitesSubtEn == null)
            form.sitesSubtEn = form.sitesSubt
          if (form.sitesTextEn === '' || form.sitesTextEn == null)
            form.sitesTextEn = form.sitesText
          
        }
        else
        {
          if (form.sitesSubt === '' || form.sitesSubt == null)
            form.sitesSubt = form.sitesSubtEn
          if (form.sitesText === '' || form.sitesText == null)
            form.sitesText = form.sitesTextEn
         
        }
      setLoading(true)

      try {
        switch (typeForm) {
          case 'updType': {
            await axios.put(URI + 'site/' + id, form)
            setResponseForm(true)
            setTopass('')
            setLoading(false)
            
            return
          }
          case 'addType': {
            await axios.post(URI + 'site/', form)
            setResponseForm(true)
            setTopass('')
            setLoading(false)            
            setForm(initialForm)
            return
          }
          default:
            return null
        }
      } catch (error) {        
        setLoading(false)
        setResponseForm(false)
      }
    } else {
      return
    }
  }

  return {
    form,
    setForm,
    errorsEnd,
    loading,
    responseForm,
    topass,
    imgToDelete,
    setImgToDelete,
    handleChange,
    handleCheck,
    handleBlur,
    handleSubmit,
  }
}
