import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import SitesCard from './SitesCard.jsx'
import axios from 'axios'
//aos
import AOS from 'aos'
//styles
import 'aos/dist/aos.css'
import '../content/sites.css'
import SearchContext from '../context/SearchContext'
import LanguageContext from '../context/LanguageContext'
import { AuthContext } from '../context/AuthContext'
import { TfiPencilAlt } from 'react-icons/tfi'

const URI = `${process.env.REACT_APP_BACKEND}`

const Sites = () => {
  const { texts} = useContext(LanguageContext)
  const { published } = useParams()
  const { user } = useParams()
  const navigate = useNavigate()
  const { currentUser } = useContext(AuthContext)
 // let { sitesid } = useParams()
  const [sites, setSite] = useState([])
  const { search } = useContext(SearchContext)

  useEffect(() => {
    AOS.init()
  }, [])
 
  useEffect(() => {
    getSite()
     //eslint-disable-next-line
  }, [search])

  const getSite = async () => {
    const res = await axios.get(URI + 'site')
    let resfilt = res.data.filter(
      (item) =>
        item.sitesPublished == parseInt(published) &&
        (parseInt(user) != 0
          ? item.userId == parseInt(user)
          : true) &&
        (search != '' && search != null ?
          item.sitesName.toLowerCase().includes(search) ||
          item.sitesSubt.toLowerCase().includes(search)
          : true ),    
        ) 
      setSite(resfilt.sort((a,b) => a.sitesName.localeCompare(b.sitesName)) 
      )   
  }
   
  const siteAdd = () => {
    navigate('/sites/siteadd')
  }
  const siteAddWithoutUser = () => {
    navigate('/auth/login/1/1')
  }

  return (
    <Container fluid className="sites ">
      <Row className="section-title">
        <Col>
          <h1
            className="pt-4"
            data-aos="fade-up"
            data-aos-delay="10"
            style={{ fontSize: '0.9rem' }}
          >
            {texts.siInfo}
          </h1>
          <h3
            style={{ fontFamily: 'fantasy' }}
            data-aos="fade-down"
            data-aos-delay="10"
          >
            {texts.siSubt}
          </h3>
        </Col>
      </Row>
      <Row >
        <Col>
          {currentUser ? (
            <p>
              <Button
                variant="primary"
                className="btn-circle-sites"
                onClick={siteAdd}
                title="Adicionar nuevo Sitio"
              >
                <TfiPencilAlt />
              </Button>
              <span className="service-link" style={{ color: '#2d6dfd' }}>
                &nbsp;{texts.siAddSiteButton}
              </span>
            </p>
           ) : (
            <p>
              <Button
                variant="primary"
                className="btn-circle-sites"
                onClick={siteAddWithoutUser}
                title={texts.siAddSiteButton}
              >
                <TfiPencilAlt />
              </Button>
              <span className="service-link" style={{ color: '#2d6dfd' }}>
                &nbsp;{texts.siAddSiteButton}
              </span>
            </p>
           )}  
        </Col>
      </Row>
                

      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="sites-center mb-3">
          {sites.map((item) => (
            <div key={item.id}>
              <span></span>
              <SitesCard item={item} />
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default Sites
