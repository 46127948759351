import React, { useState, useContext } from 'react'
import axios from 'axios'
import LanguageContext from '../context/LanguageContext'
import { FaStar, FaRegWindowClose, FaRegCheckSquare } from 'react-icons/fa'
import { Button, Container, Alert } from 'react-bootstrap'
import '../components/getRating.css'
import { TimerMessage } from '../components/TimerMessage'

const URI = `${process.env.REACT_APP_BACKEND}`

const GetRating = (props) => {

  const { texts } = useContext(LanguageContext)
  const [rating, setRating] = useState(null)
  const [vcomment, setComment] = useState('')
  const [hover, setHover] = useState(null)
  const [maxChar, setMaxChar] = useState(500)
  const [sended, setSended] = useState(false)
  const [tooLargeComment, setTooLargeComment] = useState(false)
  let esLang = texts.idiom === 'es' ? true : false

  const textRating = (vrating) => {
    switch (vrating) {
      case null:
        return ''
      case 0:
        return esLang?'Pésimo':'Worst' 
      case 1:
        return esLang?'Muy Malo': 'Poor' 
      case 2:
        return esLang?'Malo': 'Nothing Special' 
      case 3:
        return esLang?'Bueno': 'Good'
      case 4:
        return esLang?'Muy Bueno': 'Very Good'
      case 5:
        return esLang?'Excelente!': 'Excellent!'
      default:
        return ' '
    }
  }

  const handleReset = () => {
    setComment('')
    setRating(null)
  }
  
  const handleSubmit = async (e) => {
     e.preventDefault()
     if (vcomment.length > 500) {
        setTooLargeComment(true)
      } else {
        setTooLargeComment(false)
        if (props.propsType === 'serv') {
          await axios.post(URI + 'shopeval/', {
            idSale: props.propsId,
            Comment: vcomment,
            Eval: rating != null ? rating : 0,
            userName: props.propsUser,
          })
          setSended(true)
        } else {
          await axios.post(URI + 'siteeval/', {
            idSite: props.propsId,
            Comment: vcomment,
            Eval: rating != null ? rating : 0,
            userName: props.propsUser,
          })
          setSended(true)
        }
      }

    }
    
    const handleMaxCharacters = (e) => {
          setMaxChar(500-(e.target.value.length))
          setComment(e.target.value)
    }
   
    //onChange={(e) => setComment(e.target.value)}

  return (
    <Container>
      <div className="rating-eval rating-center">
        <span style={{ color: '#010101', marginRight: '2rem' }}>{texts.evalRate}:</span>
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1
          return (
            <label>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => setRating(ratingValue)}
                className="nodisplay"
              />
              <FaStar
                className="star"
                color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
                size={20}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          )
        })}
        <span style={{ verticalAlign: 'bottom', marginLeft: '1rem' }}>
          {textRating(rating)}
        </span>
      </div>
      {sended && (
        <TimerMessage
          mess={texts.evalSend}
          variant={'success'}
          navig={props.navig}
          timetogo="1000"
        />
      )}
      {tooLargeComment && (
        <span>
               <Alert
            variant="danger"
            style={{
              margin: '1rem',
              textAlign: 'center',
            }}
          >
            {texts.evalError}
          </Alert>
          {setTimeout(()=> setTooLargeComment(false),parseInt(1500))}
        </span>
        
         )}
        
        

      <div className="comment-mine mt-4">
        <form>
          <div className='alert-comment mb-1' >Máximo 500 caracteres : {maxChar} pendientes</div>
          <div className="row textarea-mine">
            <textarea
              className="form-control inputstl"
              id="vcomment"
              placeholder={
                props.propsId === 0
                  ? texts.evalRegis
                  : texts.evalGiveOp
              }
              rows="5"
              disabled={props.propsId === 0 ? true : false}
              value={vcomment}
             
              
              onChange= {handleMaxCharacters}
              autoFocus
              autocomplete="off"
            ></textarea>
          </div>
          <div className="btn-textarea m-2">
            {!sended && (
              <p>
                <Button
                  onClick={handleSubmit}
                  variant="outline-dark"
                  style={{
                    height: '2rem',
                    fontSize: '0.9rem',
                    marginRight: '1rem',
                    marginTop: '2',
                  }}
                >
                 {texts.evalSendOp} <FaRegCheckSquare />
                </Button>

                {(vcomment !== '' || rating > 0) && (
                  <Button
                    onClick={handleReset}
                    variant="outline-danger"
                    style={{
                      height: '2rem',
                      fontSize: '0.9rem',
                      marginTop: '2',
                    }}
                   >
                    {texts.evalDelOp} <FaRegWindowClose />
                   </Button>
                )}
              </p>
            )}
          </div>
        </form>
      </div>
      <div></div>
    </Container>
  )
}

export default GetRating

/*
  const sontoFather = (fromGetRat) => {
    setEvalYes(fromGetRat)
  }
*/