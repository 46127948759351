import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Spinner,
  Image,
} from 'react-bootstrap'
import { FaPersonArrowDownToLine, FaEye, FaEyeSlash } from 'react-icons/fa6'
//import { useForm } from '../hooks/useForm'
import '../authentication/auth.css'
//import { RecoveryContext } from './Auth'
//import brand from '../img/navbar/brand.png'
import { TimerMessage } from '../components/TimerMessage'
import logo from '../img/logo/logowhite.png'

const initialForm = {
  username: '',
  usermail: '',
  password: '',
}

const URI = `${process.env.REACT_APP_BACKEND}`

const Registration = () => {

  const {texts} = useContext(LanguageContext)
  const navigate = useNavigate()
  //const {setEmail, email , setOTP} = useContext(AuthContext)
  const [agree, setAgree] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [topass, setTopass] = useState(null)
 // const [err, setErr] = useState(null)
  //const { login } = useContext(AuthContext)

  const validationsForm = (form) => {
    let errors = {}
    let regexUserName = /^[A-Za-z0-9ÑñÁáÉéÍíÓóÚúÜü\s]+$/
   // let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
    let regexUserMail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
    if (!form.username.trim()) {
      errors.username = texts.errReq
    } else if (!regexUserName.test(form.username.trim())) {
      errors.username =texts.errFormatName
    } else if (form.username.trim().length > 15) {
      errors.username = texts.err15
    }
    if (!form.usermail.trim()) {
      errors.usermail = texts.errReq
    } else if (!regexUserMail.test(form.usermail.trim())) {
      errors.usermail = texts.errFormat
    } else if (form.usermail.trim().length > 150) {
      errors.usermail = texts.err150
    }
    if (!form.password.trim()) {
      errors.password = texts.errReq
    }
    return errors
  }

  const logingo = () => {
    navigate('/auth/login/0/0')
  }

  const doNothing = (e) => {
    e.preventDefault()
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }
  
  const handleBlur = (e) => {
    setTopass('')
    setResponse(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors(validationsForm(form))
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        await axios.post(URI + 'auth/registration', form)
        setTopass(form.username)
        setLoading(false)
        setResponse(true)
      } catch (error) {
        if (error.toString().indexOf('400') !== -1) 
          setTopass(texts.errUserExist)
        if (error.toString().indexOf('400') !== -1) 
          setTopass(texts.errRegis)       
        setLoading(false)
        setResponse(false)
      }
    }
  }

  return (
    <div className="auth">
      <Container fluid className="auth-card">
        <Row>
          <Col>
          <Image
                    src={logo} 
                    alt={texts.title}
                    title={texts.title}
                  />{' '} 
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Header className="auth-card-header">
                {texts.auRegis}
              </Card.Header>
              <Card.Body>
                <Form onSubmit={doNothing}>
                  <>
                    <Row>
                      <Col>
                        {(topass != null && topass.substr(0, 6)) ===
                          texts.seCheck && <p className="auth-err">{topass}</p>}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">{texts.auUserName} </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="username"
                          placeholder={texts.auUserNamePH}
                          type="text"
                          autoFocus
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={form.username}
                          disabled={response ? true : false}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      {errors.username && (
                        <p className="auth-err">{errors.username}</p>
                      )}
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        {texts.auEmail} (Email):
                      </Col>
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <input
                          autoComplete="off"
                          className="form-control inputstl auth-input"
                          name="usermail"
                          placeholder= {texts.auEmailPH}
                          type="text"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={form.usermail}
                          disabled={response ? true : false}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      {errors.usermail && (
                        <p className="auth-err">{errors.usermail}</p>
                      )}
                    </Row>
                    <Row>
                      <Col className="auth-label">{texts.auPass}:</Col>
                    </Row>
                    <Row>
                      <Col className="auth-icontext">
                        <input
                          className="form-control inputstl auth-input"
                          type={showPwd ? 'text' : 'password'}
                          autoComplete="off"
                          name="password"
                          placeholder={texts.auPassPH}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={form.password}
                          disabled={response ? true : false}
                          required
                        />
                        <span
                          className="icon-inside"
                          onClick={() => setShowPwd(!showPwd)}
                        >
                          {showPwd ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      {errors.password && (
                        <p className="auth-err">{errors.password}</p>
                      )}
                    </Row>
                    <Row>
                      <Col className="auth-label">
                        <div>
                          <Form.Check
                            type="checkbox"
                            name="agree"
                            style={{ paddingTop: '0.4rem' }}
                            value={agree}
                            onChange={(e) => setAgree(!agree)}
                            checked={agree}
                            disabled={response ? true : false}
                            label={texts.auTyCon}
                          />{' '}
                        </div>
                      </Col>
                    </Row>
                    <Row></Row>
                    
                    <Row>
                      <Col>
                        {!response && (
                          <Button
                            onClick={handleSubmit}
                            className="auth-btnini"
                            disabled={!agree}
                          >
                            {loading && (
                              <Spinner
                                style={{ textAlign: 'center' }}
                                size="sm"
                                animation="border"
                                variant="success"
                              />
                            )}{' '}
                            {texts.auRegisMine}
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div></div>
                      </Col>
                    </Row>
                  </>
                </Form>
              </Card.Body>

              <Card.Footer className="auth-registration">
                {!response && (
                  <Button
                    variant="warning"
                    onClick={logingo}
                    style={{ fontSize: '0.8rem' }}
                  >
                    Es usuario?...autentifiquese aquí{' '}
                    <FaPersonArrowDownToLine />
                  </Button>
                )}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="auth-advice">
            <Link to={'/conditions'}>
              [Términos y Condiciones de Uso del Sitio]
            </Link>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col>
          {response && (
            <div>
              <TimerMessage
                mess={
                  'Bienvenido a AvenidaCuba!... Ahora debe identificarse en el Sistema, entre su email y su contraseña...por favor!.'
                }
                variant={'success'}
                timetogo="2000"
              />
              {navigate('/auth/login/0/0')}
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default Registration
