import axios from 'axios'
import { useState, useEffect } from 'react' //hooks, gestiona estado de los componentes funcionales
import { Link } from 'react-router-dom'

import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'

const URI = `${process.env.REACT_APP_BACKEND}`

// configurating hooks
const AllDiary = () => {
  const [diary, setDiary] = useState([])
  useEffect(() => {
    getDiary()
  }, [])

  //procedure to show all types
  const getDiary = async () => {
    const res = await axios.get(URI + 'diary')
    setDiary(res.data)
  }

  // procedure to delete a type
  const deleteDiary = async (id) => {
    await axios.delete(`${URI + 'diary/'}${id}`)
    getDiary()
  }
  /*
   function getDate() {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    const date = today.getDate()
    return `${month}${date}${year}`
  }
  const [today, setToday] = useState(getDate());
  */

  return (
    <div>

      <div className="container-elements">
        <div className="row">
          <div className="col offset-sm-1">
            <h5>GESTION DE SORPRESAS DIARIAS</h5>
            <Link to="/admin/diary/add" className="btn btn-primary mt-2 mb-2">
              Adicionar{' '}
              <i className="bi bi-plus-circle-fill" aria-hidden="true"></i>
            </Link>
            <table className="table">
              <thead className="table-primary">
                <tr>
                  <th>Fecha</th>
                  <th>Tipo</th>
                  <th>Procede</th>
                  <th>Fichero</th>
                  <th>Detalles</th>
                  <th>Detalles en Ingles</th>
                </tr>
              </thead>
              <tbody>
                {diary.map((item) => (
                  <tr key={item.id}>
                    <td> {item.dateId} </td>
                    <td> {item.type===1?'Escrito': (item.type===2?'Imagen':'Video')} </td>
                    <td> {item.from===1?'Cubanos': 'Redes'} </td>
                    <td> {item.diary} </td>
                    <td> {item.diaryDet} </td>
                    <td> {item.diaryDetEn} </td>
                    <td>
                      <Link
                        to={`../admin/diary/edit/${item.id}`}
                        className="btn btn-info"
                      >
                        <FaRegEdit />
                      </Link>
                      <button
                        onClick={() => deleteDiary(item.id)}
                        className="btn btn-danger"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AllDiary
