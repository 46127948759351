import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import '../admin.css'
import { Form, Container, Row, Col } from 'react-bootstrap'

import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`
/*
const initialForm = {
  carouselId: '',
  imgActive: false,
  img: '',
  sImg: '',
  imgName: '',
  imgNameEn: '',
  imgPlace: '',
  imgPlaceEn: '',
  link: '',
}
*/
const EditCarousel = () => {
  const [form, setForm] = useState([])
  const navigate = useNavigate()
  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }
  const handleCheck = (e) => {
    const { name, value } = e.target
    
    if (e.target.checked) {
      setForm({
        ...form,
        [name]: 1,
      })
    } else {
      setForm({
        ...form,
        [name]: 0,
      })
    }
  }
 
  const { id } = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.put(URI + 'carousel/' + id, form)
    Swal.fire({
      title: 'Imagen de Carousel actualizada satisfactoriamente!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/carousel/all')
  }

  useEffect(() => {
    getCarouselById()
     //eslint-disable-next-line
  }, [])

  const getCarouselById = async () => {
    const res = await axios.get(URI + 'carousel/' + id)
    setForm(res.data)
  }

  const doNothing = (e) => {
    e.preventDefault()
  }
  return (
    <div>
     <Container className="container-elements">
        <Row>
          <Col className="col offset-sm-1">
            <div className="header-elements">
              Adicionando Imagenes para Carouseles
            </div>
            <Form onSubmit={doNothing}>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Codigo del Carousel:
                </label>
                <div className="col-sm-1">
                  <input
                    name="carouselId"
                    value={form.carouselId}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Imagen Activa:
                </label>
                <div className="col-sm-6">
                  <Form.Check
                    type="checkbox"
                    name="imgActive"
                    style={{ paddingTop: '0.4rem' }}
                    value={form.imgActive === 1 ? true :false}
                    onChange={handleCheck}
                    checked={form.imgActive}
                  />
                
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Imagenes:</label>
                <div className="col-sm-6">
                  <input
                    name="img"
                    value={form.img}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Imagenes chicas:</label>
                <div className="col-sm-6">
                  <input
                    name="sImg"
                    value={form.sImg}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Nombre de la Imagen:
                </label>
                <div className="col-sm-6">
                  <input
                    name="imgName"
                    value={form.imgName}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Lugar de la Imagen:
                </label>
                <div className="col-sm-6">
                  <input
                    name="imgPlace"
                    value={form.imgPlace}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 ">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary mt-2 mb-2"
                  >
                    Actualizar
                    <FaRegCheckCircle />
                  </button>
                  <Link
                    to="/admin/carousel/all"
                    className="btn btn-danger mt-2 mb-2"
                  >
                    Cancelar
                    <IoCloseCircleOutline />
                  </Link>
                </div>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default EditCarousel
