import axios from 'axios'
import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'

const URI = `${process.env.REACT_APP_BACKEND}`

const AllSites = () => {
  const [site, setSite] = useState([])

  const getSite = async () => {
    const res = await axios.get(URI + 'site')
    setSite(res.data)
  }

  useEffect(() => {
    getSite()
  }, [])

  const deleteSite = async (id) => {
    await axios.delete(`${URI}site/${id}`)
    getSite()
  }

  return (
    <div>
  
      <Container fluid className="container-elements">
        <div className="row">
          <div className="col m-2">
            <h5>ADMINISTRACION DE SITIOS</h5>
            <Link to="/admin/site/add" className="btn btn-primary mt-2 mb-2">
              Adicionar{' '}
              <i className="bi bi-plus-circle-fill" aria-hidden="true"></i>
            </Link>
            <table className="table">
              <thead className="table-primary">
                <tr>
                  <th>Nombre del Sitio</th>
                  <th>Subtitulo</th>
                  <th>Subtitulo Ingles</th>
                  <th>Texto</th>
                  <th>Texto Ingles</th>
                  <th>EMail</th>
                  <th>Facebook</th>
                  <th>Instagram</th>
                  <th>Telegram</th>
                  <th>X(Twiter)</th>
                  <th>Orden</th>
                  <th>Imagen</th>
                  <th>Pub?</th>
                </tr>
              </thead>
              <tbody>
                {site.map((item) => (
                  <tr key={item.id}>
                    <td> {item.sitesName} </td>
                    <td> {item.sitesSubt} </td>
                    <td> {item.sitesSubtEn} </td>
                    <td> {item.sitesText} </td>
                    <td> {item.sitesTextEn} </td>
                    <td> {item.sitesUrl} </td>
                    <td> {item.sitesMail} </td>
                    <td> {item.sitesFacebook} </td>
                    <td> {item.sitesInstagram} </td>
                    <td> {item.sitesTelegram} </td>
                    <td> {item.sitesX} </td>
                    <td> {item.sitesOrder} </td>
                    <td> {item.sitesPic} </td>
                    <td> {item.sitePublished} </td>
                    <td>
                      <Link
                        to={`../admin/site/edit/${item.id}`}
                        className="btn btn-info"
                      >
                        <FaRegEdit />
                      </Link>
                      <button
                        onClick={() => deleteSite(item.id)}
                        className="btn btn-danger"
                      >
                        <FaRegTrashAlt />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default AllSites
