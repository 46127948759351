import React, { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import {
  InputGroup,
  FloatingLabel,
  Form,
  Container,
  Row,
  Col,
  Image,
  Card,
  Button,
  Spinner,
  Alert,
  Badge,
} from 'react-bootstrap'
import {
  FaRegWindowClose,
  FaCity,
  FaRegClock,
  FaGlobeAmericas,
  FaRegCheckSquare,
  FaRegImages,
  FaStar,
} from 'react-icons/fa'
import { GiLaptop } from "react-icons/gi";
import Swal from 'sweetalert2'
import { FaRegTrashCan, FaRegEnvelope, FaMobileScreen } from 'react-icons/fa6'

import { IoArrowUndoOutline } from 'react-icons/io5'
import { useFormServ } from '../hooks/useFormServ'
import { TimerMessage } from '../components/TimerMessage'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../shop/shopHome.css'
import noPhotos from '../img/shop/nofotos.jpg'
import spa from '../img/navbar/spanish.png'
import eng from '../img/navbar/english.png'
import shopinitop from '../img/shop/frontshop.png'

const URI = `${process.env.REACT_APP_BACKEND}`
const URIMG = `${process.env.REACT_APP_BACKENDPUB}`

const initialForm = {
  title: '',
  titleEn: '',
  typeId: 0,
  descrip: '',
  descripEn: '',
  bolderText: '',
  bolderTextEn: '',
  workTime: '',
  workTimeEn: '',
  contactMail: '',
  contactPhone: '',
  countryId: 0,
  cityId: 0,
  published: false,
  user: '',
  imgs: '',
}

const actyear = new Date().getFullYear().toString()

const validationForm = (form,lang) => {
  let errors = {}
  //expresiones regulares para cada tipo de campo
  //let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
  let regexPhone = /^[0-9,+, ,(,),-]*$/
  let regexMail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
  let regexText150 = /^.{1,150}$/
  let regexText255 = /^.{1,255}$/
  let regexText500 = /^.{1,500}$/
  let regexText30 = /^.{1,30}$/
  let regexText5000 = /^.{1,5000}$/
  
  
  if (form.contactPhone && !regexPhone.test(form.contactPhone)) {
    errors.contactPhone = '📍 El teléfono posee caracteres no admitidos'
  } else if (
    form.contactPhone &&
    !regexText30.test(form.contactPhone)
  ) {
    errors.contactPhone = '📍 Máximo 30 caracteres'
  }
  if (form.contactMail && !regexMail.test(form.contactMail)) {
    errors.contactMail = '📍 El email posee caracteres no admitidos'
  } else if (
    form.contactMail &&
    !regexText150.test(form.contactMail)
  ) {
    errors.contactMail = '📍 Máximo 150 caracteres'
  }
  if (form.typeId < 1 ) {
    errors.typeId = '📍 Tiene que seleccionar un tipo de Servicio...'
  }
  if (form.countryId < 1) {
    errors.countryId = '📍 Tiene que seleccionar un País...'
  }
  if (form.cityId < 1) {
    errors.cityId = '📍 Tiene que seleccionar una Ciudad...'
  }
  if (!regexText255.test(form.webSite)) {
    errors.webSite = '📍 Máximo 255 caracteres...'
  }

  if (lang === 'es')
    {
      if (!form.title) {
        errors.title = 'El Titulo es requerido...'
      } else if (!regexText150.test(form.title)) {
        errors.title = '📍 Maximo 150 caracteres...'
      }
      
      if (!form.descrip || form.descrip === '') {
        errors.descrip = 'La Descripcion es requerida...'
      } else if (!regexText5000.test(form.descrip)) {
        errors.descrip = '📍 Máximo 5000 caracteres...'
      }
      if (!regexText150.test(form.bolderText)) {
        errors.bolderText = '📍 Máximo 150 caracteres...'
      }
      if (!regexText500.test(form.workTime)) {
        errors.workTime = '📍 Máximo 500 caracteres...'
      }
    }
  if (lang === 'en')
    {
      if (!regexText150.test(form.bolderTextEn)) {
        errors.bolderTextEn = '📍 Maximum 150 characters...'
      }
      if (!regexText500.test(form.workTimeEn)) {
        errors.workTimeEn ='📍 Maximum 500 characters...'
      }
      if (!form.descripEn || form.descripEn === '') {
        errors.descripEn = '📍 The description is required...'
      } else if (!regexText5000.test(form.descripEn)) {
        errors.descripEn = '📍 Maximum 5000 characters...'
      }
      if (!form.titleEn) {
        errors.titleEn = '📍 Title is required..'
      } else if (!regexText150.test(form.titleEn)) {
        errors.titleEn = '📍 Maximum 150 characters...'
      }
    } 
    console.log(errors)
  return errors
}

const ServiceAdd = () => {
  const { texts } = useContext(LanguageContext)
  //evitar salir del form con esc 27, enter 13  o backspace 8
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('input[type=text]').forEach((node) =>
      node.addEventListener('keypress', (e) => {
        if (e.keyCode === 13 || e.keyCode === 8 || e.keyCode === 27) {
          e.preventDefault()
        }
      }),
    )
  })
  let typeForm = 'addType'

  const {
    form,
    errorsEnd,
    loading,
    responseForm,
    topass,
    imgToDelete,
    setImgToDelete,
    handleChange,
    handleCheck,
    handleSubmit,
  } = useFormServ(initialForm, validationForm, typeForm, 0, texts.idiom)
  const firstDiv = useRef()
  const { currentUser, logout } = useContext(AuthContext)
  const [bigImage, setBigImage] = useState(null)
  const [err, setErr] = useState(null)
  form.user = currentUser?.dataValues.id
  const [vtypeName, setTypeName] = useState('')
  const [vcountryId, setCountryId] = useState('')
  const [vcountryName, setCountryName] = useState('')
  const [notPublished, setNotPublished] = useState(false)
  const [vcityName, setCityName] = useState('')
  const [typeList, setTypeList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [cityList, setCityList] = useState([])
  const [image, setImage] = useState([])
  const [imageList, setImageList] = useState([])
  //const [listUpdated, setListUpdated] = useState(false)
  const [imgItem, setImgItem] = useState([])
  const [currentImage, setCurrentImage] = useState(null) //modal
  const [letBegin, setLetBegin] = useState(false)
  const hiddenFileInput = useRef(null)
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  const goToSomePage = () => {
    navigate('/shop/shophome/1/0')
  }
  
  useEffect(() => {
    getType()
     //eslint-disable-next-line
  }, [])

  const getType = async () => {
    const restype = await axios.get(URI + 'type')
    setTypeList(restype.data.sort((a,b) => a.typeName.localeCompare(b.typeName))

    )
  }

  useEffect(() => {
    getCountry()
  }, [vcountryId])
  const getCountry = async () => {
    const res = await axios.get(URI + 'country')
    setCountryList(res.data.sort((a,b) => a.countryName.localeCompare(b.countryName)))
  }

  useEffect(() => {
    getCity()
     //eslint-disable-next-line
  }, [vcountryId])

  const getCity = async () => {
    const res = await axios.get(URI + 'city')
    form.countryId === 0
      ? setCityList([])
      : setCityList(
          res.data.filter((dato) => dato.countryId === parseInt(vcountryId)).sort((a,b) => a.cityName.localeCompare(b.cityName)),
        )
  }

  const swap = (val1, val2, arr) => {
    if (!arr.includes(val1) || !arr.includes(val2)) return
    let val1_index = arr.indexOf(val1)
    let val2_index = arr.indexOf(val2)
    arr.splice(val1_index, 1, val2)
    arr.splice(val2_index, 1, val1)
  }
  const handlerSelectFirst = () => {
    let firstImageId = parseInt(currentImage.split('-')[0])
    swap(firstImageId, parseInt(imgItem[0]), imgItem)
    showDiapositive(imgItem)
    form.imgs = imgItem.toString()
    
  }

  //Using imgs string and find images to show from selected service
  const showDiapositive = async (allimgs) => {
    if (allimgs.toString() === "")
      {
        setBigImage(noPhotos)
        setImage(noPhotos)
      }
      
    else 
    {
        await axios
          .get(URI + 'images')
          .then((res) => {
            let pru = res.data.filter((dato) =>
              allimgs.includes(parseInt(dato.split('-')[0])),
            )
            if (pru.length > 0) {
              if (parseInt(pru[0].split('-')[0]) !== allimgs[0]) {
                let firstIndex = pru
                  .map((element) => parseInt(element.split('-')[0]))
                  .indexOf(allimgs[0]) //busco el index del id de la primera imagen dentro del arreglo obtenido con las imagenes de ese servicio.
                swap(pru[firstIndex], pru[0], pru)
              }
              setImage(pru[0])
              setBigImage(pru[0])
              setImageList(pru)
            } else {
              setImage(noPhotos)
              //setBigImage(noPhotos)
            }
          })
          .catch((err) => {
            console.error(err)
          })
    }
  }
  //Executing only one time to iNITIALIZE AOS
  useEffect(() => {
    AOS.init()
  }, [])
  
  const handleAddImage = async (e) => {
    //console.log(`imgItem ${imgItem}`)
    if (e.target.files[0]) {
      if (imgItem.length < 6) {
        const imgFormData = new FormData()
        imgFormData.append('image', e.target.files[0])
        e.preventDefault()
        await axios({
          method: 'post',
          url: URI + 'images',
          data: imgFormData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(function (response) {
            showDiapositive([...imgItem, parseInt(response.data.split('-')[0])])    
            setImgItem([...imgItem, parseInt(response.data.split('-')[0])])
            form.imgs = [
              ...imgItem,
              parseInt(response.data.split('-')[0]),
            ].toString()
          })
          .catch(function (response) {
            Swal.fire(
              '',
              'IMAGEN 1 no adicionada, vuelva a intentarlo!',
              'error',
            )
          })
      } else
        Swal.fire(
          '',
          'IMAGEN no adicionada, excedio la cantidad posible (5) , elimine alguna para adicionar nueva!',
          'error',
        )
    } else console.log('no se selecciono ningun file')
  }

  const handlerDeleteImage = (cImage) => {
    let imageId = parseInt(cImage.split('-')[0])
    // imageId = parseInt(imageId[0])
    const newImgItem = imgItem.filter((img) => img !== imageId)
    setImgToDelete([...imgToDelete, imageId])
    setImgItem(newImgItem)
    form.imgs = newImgItem.toString()
    showDiapositive(newImgItem)
    // setListUpdated(true) // OJOOOOOOOO VER SI ESTO AUN PROCEDE O SE ESTA ACTUALIZANDO DE OTRA MANERA
    //       setModalIsOpen(false)
    /* document.getElementById('imgInput').value = null*/
  }
  /* //TO DELETE IMAGE FROM IMGITEM AND SHOW AGAIN IMAGES
  const handlerDeleteImage = async () => {
    let imageId = currentImage.split('-')
    imageId = parseInt(imageId[0])
    const newImgItem = imgItem.filter((img) => img !== imageId)
    setImgToDelete(imageId)
    setImgItem(newImgItem)
    showDiapositive(newImgItem)
    setListUpdated(true) // OJOOOOOOOO VER SI ESTO AUN PROCEDE O SE ESTA ACTUALIZANDO DE OTRA MANERA
    setModalIsOpen(false)
    document.getElementById('imgInput').value = null
  }*/
  /*TO ADD AN IMAGE
  const handlerAddImage = async (e) => {
    if (e.target.files[0]) {
      if (imgItem.length < 6) {
        const imgFormData = new FormData()
        imgFormData.append('image', e.target.files[0])
        e.preventDefault()
        await axios({
          method: 'post',
          url: URI + 'images',
          data: imgFormData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(function (response) {
            setListUpdated(true)
            console.log(response.data.split('-')[0])
            showDiapositive([...imgItem, parseInt(response.data.split('-')[0])])
            setImgItem([...imgItem, parseInt(response.data.split('-')[0])])
          })
          .catch(function (response) {
            //handle error --- console.log(response);
            Swal.fire(
              '',
              'IMAGEN 1 no adicionada, vuelva a intentarlo!',
              'error',
            )
          })
      } else
        Swal.fire(
          '',
          'IMAGEN no adicionada, excedio la cantidad posible (6) , elimine alguna para adicionar nueva!',
          'error',
        )
    } else console.log('no se selecciono ningun file')
  }*/
  const handlerClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleSelectType = (e) => {
    // setType(e.target.value)
    form.typeId = parseInt(e.target.value)
    setTypeName(e.target.options[e.target.selectedIndex].text)
    handleChange(e)
  }

  const handleSelectCountry = (e) => {
    setCountryId(e.target.value)
    form.countryId = parseInt(e.target.value)
    console.log(`countryId: ${form.countryId}`)
    setCountryName(e.target.options[e.target.selectedIndex].text)
    // setSelectCity(true)
    handleChange(e)
  }

  const handleSelectCity = (e) => {
    form.cityId = parseInt(e.target.value)
    setCityName(e.target.options[e.target.selectedIndex].text)
    handleChange(e)
  }
  const handleCheckPub = (e) => {
    if (form.published)
      setNotPublished(true)
    else
    setNotPublished(false)
    handleCheck(e)
  }
  useEffect(() => {
    firstDiv.current.focus()
  }, [])

  const serviceCancel = () => {
    setLetBegin(false)
    goBack()
  }
  
  return (
    <div className="serviceform center-class" tabIndex={-1} ref={firstDiv}>
      <Container className="m-0 p-1">
        <Row>
          <Col lg={12} className="section-title" data-aos="fade-up">
          <h2 data-aos="fade-left" data-aos-delay="10">
            {texts.seProp} {actyear}
            </h2>
            <p
              data-aos="fade-right"
              data-aos-delay="10"
              style={{ fontFamily: 'fantasy' }}
            >
              {texts.seAdding}
            </p>
            
          </Col>
        </Row>
        {currentUser ? (
          <Form>
            {!letBegin ?
              <div className="center-class mb-3">
               
                  <Alert variant="light" className="mt-2 important-advice" style={{background:'#deebf6'}}>
                      <Alert.Heading style={{ fontSize: '0.9rem' }}>
                        {texts.seAdviceHeader}:
                      </Alert.Heading>
                        <p
                          style={{
                            fontSize: '0.8rem',
                            fontStyle: 'italic',
                            color: '#7e7c7c',
                            fontWeight: 'normal',
                            textAlign:'left'
                          }}
                        >
                          <div>
                          {texts.seAdviceBodyService}
                          </div>
                          <div style={{marginTop:'1px'}}>
                          {texts.seAdviceBodyService2}
                          </div>
                          <div>
                          {texts.seAdviceBodyService3}
                          </div>
                          <div>
                          {texts.seAdviceBodyServic4}
                          </div>
                          <div style={{textAlign:'right',marginTop:'1px'}}>
                              <Button
                                className="me-3 button-shadow"
                                size="sm"
                                variant="warning"
                                onClick={serviceCancel}
                                title={texts.seCancelAdd}
                              >
                                <div style={{ color: 'ffffff', display: 'flex' }}>
                                  <FaRegWindowClose
                                    style={{ fontSize: '1.2rem' }}
                                  />
                                  {texts.seCancel}
                                </div>
                              </Button>
                              <Button
                                className="me-3 button-shadow"
                                size="sm"
                                onClick={()=>setLetBegin(true)}
                                variant="success"
                                title={texts.continue}
                              >
                                 <div style={{ color: 'ffffff', display: 'flex' }}>
                                  <FaRegWindowClose
                                    style={{ fontSize: '1.2rem' }}
                                  />
                                  {texts.continue}
                                </div>
                              </Button>
                        
                          </div>
                          
                        </p>
                        

                    </Alert>
                    <Image 
                        src={shopinitop}  className="img-fluid shophome-img small-animated " 
                        alt=""
                    />
              </div>
            :
                <Card>
                  <Row>
                    <Col>
                      {(topass != null && topass.substr(0, 6)) === texts.seCheck && (
                        <p className="auth-err">{topass}</p>
                      )}
                    </Col>
                  </Row>
                  <Card.Header className="service-inter">
                    <Row>
                      <Col lg={12}>
                        <div style={{ display: 'flex' }}>
                          <div>
                            <Button 
                              size="sm" 
                              onClick={goToSomePage}variant="link"
                            >
                              <Badge bg="light" className="button-shadow">
                                <IoArrowUndoOutline
                                  style={{ fontSize: '1rem', color: '#641e02' }}
                                  title={texts.backPage}
                                />
                              </Badge>
                            </Button>
                          </div>
                          <div style={{ marginLeft: 'auto' }}>
                            <p>
                              <Button
                                className="me-3 button-shadow"
                                size="sm"
                                variant="warning"
                                onClick={serviceCancel}
                                title={texts.seCancelAdd}
                              >
                                <div style={{ color: 'ffffff', display: 'flex' }}>
                                  <FaRegWindowClose
                                    style={{ fontSize: '1.2rem' }}
                                  />
                                  {texts.seCancel}
                                </div>
                              </Button>
                              <Button
                                className="me-3 button-shadow"
                                size="sm"
                                onClick={handleSubmit}
                                variant="success"
                                title={texts.seAddTitle}
                              >
                                {loading && (
                                  <Spinner
                                    style={{ textAlign: 'center' }}
                                    size="sm"
                                    animation="border"
                                    variant="light"
                                  />
                                )}
                                <div style={{ color: 'ffffff', display: 'flex' }}>
                                  <FaRegCheckSquare
                                    style={{ fontSize: '1.2rem' }}
                                  />
                                  {currentUser?.dataValues.access === 'admin'?texts.seAdd : texts.seAddnoAdmin}                              
                                </div>
                              </Button>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body className="p-3">
                    <Row>
                      <Col>                       
                        <div className='d-flex section-title'>
                          {texts.idiom ==='es' ?                            
                                <p  style={{fontSize:'0.9rem'}}>
                                      Su oferta se crea en Español,{' '}     
                                        <img
                                          src={spa}
                                          alt=""
                                          title="Idioma"
                                          className="navicon"
                                        /> 
                                
                                      
                                      {' '} para adicionar en Inglés también, cambie el Idioma del Sitio y vuelva a Editarla en ese Idioma... 
                                  </p>
                            
                                  :
                                  
                                  <p  style={{fontSize:'0.9rem'}}>
                                    Your data is adding in English,{' '}     
                                        <img
                                          src={eng}
                                          alt=""
                                          title="English"
                                          className="navicon"
                                        /> 
                                
                                      
                                      .{' '} To add it in Spanish too, change the site language and edit it again...
                                      
                                  </p>
                                  
                              }
                            </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {responseForm === true && (
                          <TimerMessage
                          mess={texts.seUpdService}
                            variant={'success'}
                            navig={'../shop/shophome/1/0'}
                            timetogo="1000"
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12} xs={12}>
                        <Card
                          className="services-td"
                          data-aos="fade-left"
                          data-aos-delay="2"
                        >
                          <Card.Header className="service-header">
                            <Form.Group>
                              <Row>
                                <Col>
                                <FloatingLabel label={texts.seService}>
                                    <Form.Select
                                      
                                      onChange={handleSelectType}
                                      className="service-titleII"
                                      value={form.typeId}
                                    >
                                      <option value="0" selected>
                                      {texts.seSelect}
                                      </option>
                                      {typeList.map((item) => (
                                        <option key={item.id} value={item.id}>
                                          {texts.idiom ==='es' ? item.typeName: item.typeNameEn}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </FloatingLabel>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                <FloatingLabel label={texts.seTitle}>
                                    <Form.Control
                                      name={texts.idiom === 'es' ? 'title' : 'titleEn'}
                                      className="service-title"
                                      value={texts.idiom === 'es' ? form.title : form.titleEn}
                                      onChange={handleChange}
                            
                                      type="text"
                                    />
                                  </FloatingLabel>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Row>
                              <Col>
                                {(errorsEnd.typeId || errorsEnd.title) && (
                                  <p className="service-err">
                                    {errorsEnd.typeId}&nbsp;&nbsp;{errorsEnd.title}
                                  </p>
                                )}
                                {(errorsEnd.typeId || errorsEnd.titleEn) && (
                                  <p className="service-err">
                                    {errorsEnd.typeId}&nbsp;&nbsp;{errorsEnd.titleEn}
                                  </p>
                                )}
                              </Col>
                            </Row>
                            
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col className="mb-3">
                                <InputGroup size="sm">
                                  <FloatingLabel label={texts.seDescrip}>
                                      <Form.Control
                                        name={texts.idiom ==='es' ? 'descrip' :'descripEn'}
                                        value={texts.idiom ==='es' ? form.descrip : form.descripEn}
                                        onChange={handleChange}
                                        style={{height:'10rem'}}
                                        as="textarea" 
                                        rows={10}
                                        className="service-text"
                                      />
                                  </FloatingLabel>
                                </InputGroup>
                              </Col>
                            </Row>
                            <Row>
                                  <Col>
                                    {errorsEnd.descrip && (
                                      <p className="service-err">
                                        {errorsEnd.descrip}
                                      </p>
                                    )}
                                    {errorsEnd.descripEn && (
                                        <p className="service-err">
                                          {errorsEnd.descripEn}
                                        </p>
                                      )}
                                  </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                              <FloatingLabel label={texts.seBolder}>
                              <Form.Control
                                    name={texts.idiom === 'es' ? 'bolderText' : 'bolderTextEn'}
                                    value={texts.idiom === 'es' ? form.bolderText: form.bolderTextEn }
                                    onChange={handleChange}
                                    
                                    type="text"
                                    className="service-bolder"
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {errorsEnd.bolderText && (
                                  <p className="service-err">
                                    {errorsEnd.bolderText}
                                  </p>
                                )}
                                {errorsEnd.bolderTextEn && (
                                  <p className="service-err">
                                    {errorsEnd.bolderTextEn}
                                  </p>
                                )}
                              </Col>
                            </Row>                        
                            <Row>
                              <Col className="mb-3">
                                <FloatingLabel
                                  label={
                                    <span>
                                      <FaRegClock />{texts.seHours}
                                    </span>
                                  }
                                >
                                  <Form.Control
                                    name={texts.idiom === 'es' ? 'workTime' : 'workTimeEn'}
                                    value={texts.idiom === 'es' ? form.workTime: form.workTimeEn }
                                    onChange={handleChange}
                                    
                                    type="text"
                                    className="service-city"
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {errorsEnd.workTime && (
                                  <p className="service-err">{errorsEnd.workTime}</p>
                                )}
                                {errorsEnd.workTimeEn && (
                                  <p className="service-err">{errorsEnd.workTimeEn}</p>
                                )}
                              </Col>
                            </Row>
                            
                            

                            <Row>
                              <Col xs={6} className="mb-3">
                              <FloatingLabel label={
                                    <span>
                                      <FaMobileScreen />{texts.phone}
                                    </span>
                                  }
                                  >
                                  <Form.Control
                                    value={form.contactPhone}
                                    name="contactPhone"
                                    onChange={handleChange}
                                  
                                    type="text"
                                    className="service-phone"
                                  />
                                </FloatingLabel>
                              </Col>
                              <Col className="mb-3">
                                <FloatingLabel
                                  label={
                                    <span>
                                      <FaRegEnvelope /> {texts.siEmail}
                                    </span>
                                  }
                                >
                                  <Form.Control
                                    value={form.contactMail}
                                    name="contactMail"
                                    onChange={handleChange}
                                    
                                    type="text"
                                    className="service-phone"
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {(errorsEnd.contactPhone || errorsEnd.contactMail) && (
                                  <p className="service-err">
                                    {errorsEnd.contactPhone}&nbsp;
                                    {errorsEnd.contactMail}
                                  </p>
                                )}
                              </Col>
                            </Row>
                            <Row>
                              <Col className="mb-3">
                                <FloatingLabel
                                  label={
                                    <span>
                                      <GiLaptop />{texts.seWeb}
                                    </span>
                                  }
                                >
                                  <Form.Control
                                    name='webSite'
                                    value={form.webSite}
                                    onChange={handleChange}
                                    
                                    type="text"
                                    className="service-city"
                                  />
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {errorsEnd.webSite && (
                                  <p className="service-err">{errorsEnd.webSite}</p>
                                )}                            
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6} className="mb-3">
                                <FloatingLabel
                                  label={
                                    <span>
                                      <FaGlobeAmericas /> {texts.country}
                                    </span>
                                  }
                                >
                                  <Form.Select
                                    name="countryId"
                                    className="service-city"
                                    value={form.countryId}
                                    onChange={handleSelectCountry}
                                    
                                  >
                                    <option value="0" selected>
                                    {texts.seSelect}
                                    </option>
                                    {countryList.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.countryName}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </FloatingLabel>
                              </Col>
                              <Col className="mb-3">
                                <FloatingLabel
                                  label={
                                    <span>
                                      <FaCity />{texts.city}
                                    </span>
                                  }
                                >
                                  <Form.Select
                                    name="cityId"
                                    className="service-city"
                                    value={form.cityId}
                                    onChange={handleSelectCity}
                                    
                                    
                                  >
                                    <option value="0" selected>
                                    {texts.seSelect}
                                    </option>
                                    {cityList.map((item) => (
                                      <option key={item.id} value={item.id}>
                                        {item.cityName}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </FloatingLabel>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {(errorsEnd.countryId || errorsEnd.cityId) && (
                                  <p className="service-err">
                                    {errorsEnd.countryId}&nbsp;{errorsEnd.cityId}
                                  </p>
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col xs={6} className="service-city pt-0">
                                {imageList.length < 5 ? (
                                  <Form.Group controlId="imgInput">
                                    <Form.Control
                                      type="file"
                                      size="sm"
                                      onChange={handleAddImage}
                                      ref={hiddenFileInput}
                                      style={{ display: 'none' }}
                                    />
                                    <Button
                                      size="sm"
                                      text="dark"
                                      onClick={handlerClick}
                                      variant="warning"
                                      style={{ fontSize: '0.8rem' }}
                                    >
                                      <FaRegImages />
                                      &nbsp;{texts.seNewImg}
                                    </Button>
                                  </Form.Group>
                                ) : (
                                  <Alert
                                    variant="primary"
                                    style={{ fontSize: '0.8rem' }}
                                  >
                                    {texts.seMaxImg}
                                  </Alert>
                                )}
                              </Col>
                              { (currentUser?.dataValues.access === 'admin') &&
                                <Col className="service-city pt-0 pb-0">
                                  {currentUser?.dataValues.access}
                                  <Form.Check
                                    type="checkbox"
                                    name="published"
                                    style={{ paddingTop: '0.4rem' }}
                                    value={form.published}
                                    onChange={handleCheckPub}
                                    checked={form.published}
                                    label={texts.sePubOffer}
                                  />
                                </Col>
                              }
                            </Row>
                            {(notPublished) &&
                            <Row>
                              <Col>
                                <p className="service-err">
                                    {texts.seNotPub}
                                  </p>                            
                              </Col>
                            </Row>
                            }
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={6} md={12} sm={12} xs={12}>
                        <Row style={{ height: '40vh' }}>
                          <Col className="service-imgfull">
                            {bigImage != null ? (
                              <p>
                                <Image
                                  src={
                                    bigImage.includes('nofoto')
                                      ? bigImage
                                      : URIMG + bigImage
                                  }
                                  style={{ width: '100%', height: '100%' }}
                                  alt=""
                                  fluid
                                />
                                <div className="buttons-bigimage">
                                  <Button
                                    className="button-shadow me-1"
                                    size="sm"
                                    onClick={() => handlerSelectFirst(bigImage)}
                                    variant="success"
                                    title={texts.seFavImg}
                                  >
                                    <FaStar
                                      style={{
                                        fontSize: '1rem',
                                        color: 'e3d8c3',
                                      }}
                                    />
                                  </Button>
                                  <Button
                                    className="button-shadow"
                                    size="sm"
                                    onClick={() => handlerDeleteImage(bigImage)}
                                    variant="danger"
                                    title={texts.seDelImg}
                                  >
                                    <FaRegTrashCan
                                      style={{
                                        fontSize: '1rem',
                                        color: '#e3d8c3',
                                      }}
                                    />
                                  </Button>
                                </div>
                              </p>
                            ) : (
                              <Image
                                src={noPhotos}
                                style={{ width: '100%', height: '100%' }}
                                alt=""
                                fluid
                              />
                            )}
                          </Col>
                        </Row>
                        {imageList.length > 0 && (
                          <Row
                            style={{ height: '9vh', display: 'flex' }}
                            className="mt-5"
                          >
                            {imageList.map((image) => (
                              <Col
                                key={image}
                                className="me-2 mt-2 p-0"
                                lg={2}
                                md={2}
                                sm={2}
                                xs={2}
                              >
                                <Image
                                  src={URIMG + image}
                                  onClick={() => setBigImage(image)}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                  }}
                                  className="diapositive"
                                  title={texts.seBiggest}
                                  fluid
                                  thumbnail
                                />
                              </Col>
                            ))}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>

                  <Card.Footer className="mt-2 d-flex service-inter">
                  {texts.year}&nbsp;{actyear}
                    <div style={{ marginLeft: 'auto' }}>
                      <p>
                        <Button
                          className="me-3 button-shadow"
                          size="sm"
                          variant="warning"
                          onClick={serviceCancel}
                          title={texts.seCancelAdd}
                        >
                          <div style={{ color: 'ffffff', display: 'flex' }}>
                            <FaRegWindowClose style={{ fontSize: '1.2rem' }} />
                            {texts.seCancel}
                          </div>
                        </Button>
                        <Button
                          className="me-3 button-shadow"
                          size="sm"
                          onClick={handleSubmit}
                          variant="success"
                          title={texts.seAddTitle}
                        >
                          {loading && (
                            <Spinner
                              style={{ textAlign: 'center' }}
                              size="sm"
                              animation="border"
                              variant="light"
                            />
                          )}
                          <div style={{ color: 'ffffff', display: 'flex' }}>
                            <FaRegCheckSquare style={{ fontSize: '1.2rem' }} />
                            {currentUser?.dataValues.access === 'admin'?texts.seAdd : texts.seAddnoAdmin} 
                          </div>
                        </Button>
                      </p>
                    </div>
                  </Card.Footer>
                </Card>
            }
          </Form>
        ) : (
          <TimerMessage
            mess={texts.Register}
            variant={'danger'}
            navig={'../shop/shophome/1/0'}
            timetogo="2000"
          />
        )}
      </Container>
    </div>
  )
}

export default ServiceAdd

