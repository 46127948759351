import axios from 'axios'
import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Badge, Row, Container, Col, Form } from 'react-bootstrap'
import '../admin.css'
import Swal from 'sweetalert2'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { IoArrowUndoOutline } from 'react-icons/io5'

const URI = `${process.env.REACT_APP_BACKEND}`

const AddDiary = () => {
  const [form, setForm] = useState([])
  const hiddenFileInput = useRef(null)
  const navigate = useNavigate()

  const handlerClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleAddImage = (e) => {
    console.log(e.target.files[0]) 
    const { name} = e.target.files[0]
    console.log(name)
    if (e.target.files[0]) {      
      const imgFormData = new FormData()
      imgFormData.append('image', e.target.files[0])
      e.preventDefault()
      axios({
              method: 'post',
              url: URI + 'imagesreg',
              data: imgFormData,
              headers: { 'Content-Type': 'multipart/form-data' },
      })
      alert('llego')
      setForm({
        ...form,
        'diary': name,
      })
      /*.then(function (response) {
      alert('siii')
      })
      console.log(res.data)
      setForm({
              ...form,
              'surprise': e.target.file.name,
      })*/
     /* .then(function (response) {
            alert('siii')
            console.log('se subio bien:')
            console.log(response.data)
            console.log(response.data.File.name)
            setForm({
                    ...form,
                    'surprise': e.target.file.name,
            })
      })
      .catch(function (response) {
            console.log(response.data)
      })*/
    }
    else
    {
      console.log('no se selecciono ningun file')
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await axios.post(URI + 'diary/', form)
    Swal.fire({
      title: 'Sorpresa adicionada satisfactoriamente!',
      text: 'DashBoard',
      icon: 'success',
    })
    navigate('/admin/diary/all')
  }

  const doNothing = (e) => {
    e.preventDefault()
  }
  return (
    <Container className="container-elements">
      <Row>
        <Col lg={12}>
          <h5>
            <Button size="sm" href="/admin/diary/all" variant="link">
              <Badge bg="light" className="button-shadow">
                <IoArrowUndoOutline
                  style={{
                    fontSize: '1rem',
                    color: '#641e02',
                  }}
                  title="DASHBOARD..."
                />
              </Badge>
            </Button>
            Adding a Surprise
          </h5>
          <Form onSubmit={doNothing}>
            <Form.Group>
              <Form.Label>Tipo</Form.Label>
              <Form.Select
                name="type"
                className="service-city"
                onChange={handleChange}
                value={form.type}
              >
                <option value="0" selected>
                  Select type of Surprise
                </option>
                <option value="1">
                  Written
                </option>
                <option value="2">
                  Picture
                </option>
                <option value="3">
                  Video
                </option>
                
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Date(mesdiaaño)</Form.Label>
              <Form.Control
                name="dateId"
                className="service-title"
                value={form.dateId}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Procedencia</Form.Label>
              <Form.Select
                name="from"
                className="service-city"
                onChange={handleChange}
                value={form.from}
              >
                <option value="1">
                  Cubanos
                </option>
                <option value="2">
                  Redes
                </option>               
              </Form.Select>
            </Form.Group>
            {form.type === 1 ?
            <div>
                <Form.Group>
                  <Form.Label>Surprise</Form.Label>
                  <Form.Control
                    name="diary"
                    className="service-title"
                    value={form.diary}
                    onChange={handleChange}
                    type="text"
                  />
                 </Form.Group>
                 <Form.Group>
                  <Form.Label>Surprise in English</Form.Label>
                  <Form.Control
                    name="diaryEn"
                    className="service-title"
                    value={form.diaryEn}
                    onChange={handleChange}
                    type="text"
                  />
                </Form.Group>
                </div>
            :
                <Form.Group controlId="imgInput">
                  <Form.Label>Surprise</Form.Label>
                  <Form.Control
                    name="diary"
                    className="service-title"
                    value={form.diary}
                    onChange={handleChange}
                    type="text"
                  />
                    <Form.Control
                      type="file"
                      size="sm"
                      onChange={handleAddImage}
                      ref={hiddenFileInput}
                      style={{ display: 'none' }}
                    />
                    <Button
                      size="sm"
                      text="dark"
                      onClick={handlerClick}
                      variant="warning"
                      style={{ fontSize: '0.8rem' }}
                    >
                   
                      &nbsp;Nueva Imagen
                    </Button>
              </Form.Group>
            }
            <Form.Group>
              <Form.Label>Details</Form.Label>
              <Form.Control
                name="diaryDet"
                className="service-title"
                value={form.diaryDet}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Details in English</Form.Label>
              <Form.Control
                name="diaryDetEn"
                className="service-title"
                value={form.diaryDetEn}
                onChange={handleChange}
                type="text"
              />
            </Form.Group>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  className="mt-2 mb-2"
                >
                  Save
                  <FaRegCheckCircle />
                </Button>
                <Button
                  variant="danger"
                  href="/admin/diary/all"
                  className="mt-2 mb-2"
                >
                  Cancel
                  <IoCloseCircleOutline />
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default AddDiary
