import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import LanguageContext from '../context/LanguageContext'
import { FaStar, FaPencilAlt } from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import { Accordion, Row, Col } from 'react-bootstrap'
import '../components/getRating.css'

const formatDate = (d) => {
  return (
    d.substring(0, d.indexOf('T')) +
    ' ' +
    d.substring(d.indexOf('T') + 1, d.indexOf('T') + 9)
  )
}

const URI = `${process.env.REACT_APP_BACKEND}`

const GetRatingTotal = (props) => {
  //INITIALIZING VARIABLES

  // For Rating and comments
  const { texts } = useContext(LanguageContext)
  const [vcomments, setComments] = useState([])
  const [vSumTotal, setVSumTotal] = useState(0)
  const [vEvals, setVEvals] = useState(0)
  const [rating, setRating] = useState(null)
  const [hover, setHover] = useState(null)
  let esLang = texts.idiom === 'es' ? true : false

  const textRating = (vrating) => {
    switch (vrating) {
      case 0:
        return esLang?'Pésimo':'Worst' 
      case 1:
        return esLang?'Muy Malo': 'Poor' 
      case 2:
        return esLang?'Malo': 'Nothing Special' 
      case 3:
        return esLang?'Bueno': 'Good'
      case 4:
        return esLang?'Muy Bueno': 'Very Good'
      case 5:
        return esLang?'Excelente!': 'Excellent!'
      default:
        return ''
    }
  }

  //TO SHOW COMMENTS AND RATING
  const getSiteComment = async () => {
    // e.preventDefault()
    if (props.propsType === 'serv') {
      const res = await axios.get(URI + 'shopeval/')
      setComments(res.data.filter((dato) => dato.idSale === props.propsId))
    } else {
      const res = await axios.get(URI + 'siteeval/')
      setComments(res.data.filter((dato) => dato.idSite === props.propsId))
    }
  }
  const getAverage = () => {
    if (vcomments.length) {
      setVEvals(vcomments.length)
      setVSumTotal(
        Math.ceil(
          vcomments.reduce(
            (accumulator, currentValue) =>
              accumulator + parseInt(currentValue.Eval) / vcomments.length,
            0,
          ),
        ),
      )
    } else {
      setVEvals(0)
      setVSumTotal(0)
    }
  }
  useEffect(() => {
    getSiteComment()
     //eslint-disable-next-line
  }, [rating])

  useEffect(() => {
    getAverage()
     //eslint-disable-next-line
  }, [vcomments])

  return (
    <>
      <div className={'rating-eval'}>
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1
          return (
            <label>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => setRating(ratingValue)}
                className="nodisplay"
              />
              <FaStar
                className="star"
                color={ratingValue <= vSumTotal ? '#ffc107' : '#8f8e8d'}
                size={13}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)}
              />
            </label>
          )
        })}
        <span style={{ marginLeft: '0.3rem' }}>
          {props.onlyStars ? (
            <span>[{vEvals}]</span>
          ) : (
            <span>[{vEvals} {texts.evalOpinion}]</span>
          )}
          {props.withEval && (
            <span style={{ textAlign: 'end', marginLeft: '6' }}>
              <Link
                className="button-eval"
                to={
                  props.propsType === 'serv'
                    ? `/shop/shophome/serviceval/${props.propsId}`
                    : `/sites/sitein/${props.propsId}`
                }
              >
                

                  <FaPencil />

                
              </Link>
            </span>
          )}
        </span>
      </div>

      {props.yesComment ? (
        <div className="comment-mine">
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                className="accordion-header pt-2"
                title="Click para visualizarlos todos..."
              >
                {texts.evalComment}
              </Accordion.Header>
              <Accordion.Body style={{ background: '#ffffff' }}>
                {vcomments.map((item) => (
                  <Row key={item.id} className="comment-table p-0 m-0">
                    <Col sm={2} style={{ textAlign: 'left' }}>
                      <FaPencilAlt />
                      {item.userName}:
                    </Col>
                    <Col
                      sm={1}
                      style={{
                        color: 'rgb(171, 44, 9)',
                        textAlign: 'left',
                      }}
                    >
                      {textRating(item.Eval)}
                    </Col>
                    <Col xs={9} style={{ textAlign: 'left' }}>
                      {item.Comment !== '' ? item.Comment : texts.evalCommentNot}
                      ...
                      <span
                        style={{
                          color: 'rgb(145, 145, 147)',
                          fontSize: '0.6rem',
                        }}
                      >
                        [{formatDate(item.createdAt.toString())}]
                      </span>{' '}
                    </Col>
                  </Row>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        true
      )}
    </>
  )
}

export default GetRatingTotal
