import React, { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import {
  InputGroup,
  FloatingLabel,
  Form,
  Container,
  Row,
  Col,
  Image,
  Card,
  Button,
  Spinner,
  Alert,
  Badge,
} from 'react-bootstrap'
import {
  FaRegWindowClose,
  FaArrowAltCircleLeft,
  FaStar,
  FaRegCheckSquare,
  FaRegImages,
  FaCity,
  FaRegClock,
  FaGlobeAmericas,
} from 'react-icons/fa'
import { GiLaptop } from "react-icons/gi";
import Swal from 'sweetalert2'
import { FaRegTrashCan, FaRegEnvelope, FaMobileScreen } from 'react-icons/fa6'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useFormServ } from '../hooks/useFormServ'
import { TimerMessage } from '../components/TimerMessage'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../shop/shopHome.css'
import noPhotos from '../img/shop/nofotos.jpg'
import spa from '../img/navbar/spanish.png'
import eng from '../img/navbar/english.png'


const URI = `${process.env.REACT_APP_BACKEND}`
const URIMG = `${process.env.REACT_APP_BACKENDPUB}`

const validationsForm = (form,lang) => {
  let errors = {}
  //expresiones regulares para cada tipo de campo
  //let regexText = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
  let regexPhone = /^[0-9,+, ,(,),-]*$/
  let regexMail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/
  let regexText150 = /^.{1,150}$/
  let regexText255 = /^.{1,255}$/
  let regexText500 = /^.{1,500}$/
  let regexText30 = /^.{1,30}$/
  let regexText5000 = /^.{1,5000}$/


  if (form.contactPhone && !regexPhone.test(form.contactPhone)) {
    errors.contactPhone = '📍 El teléfono posee caracteres no admitidos'
  } else if (
    form.contactPhone &&
    !regexText30.test(form.contactPhone)
  ) {
    errors.contactPhone = '📍 Máximo 30 caracteres'
  }
  if (form.contactMail && !regexMail.test(form.contactMail)) {
    errors.contactMail = '📍 El email posee caracteres no admitidos'
  } else if (
    form.contactMail &&
    !regexText150.test(form.contactMail)
  ) {
    errors.contactMail = '📍 Máximo 150 caracteres'
  }
  if (!regexText255.test(form.webSite)) {
    errors.webSite = '📍 Máximo 255 caracteres...'
  }

  if (lang === 'es')
    {
      if (!form.title) {
        errors.title = 'El Titulo es requerido...'
      } else if (!regexText150.test(form.title)) {
        errors.title = '📍 Maximo 150 caracteres...'
      }
      
      if (!form.descrip || form.descrip === '') {
        errors.descrip = 'La Descripcion es requerida...'
      } else if (!regexText5000.test(form.descrip)) {
        errors.descrip = '📍 Máximo 5000 caracteres...'
      }
      if (!regexText150.test(form.bolderText)) {
        errors.bolderText = '📍 Máximo 150 caracteres...'
      }
      if (!regexText500.test(form.workTime)) {
        errors.workTime = '📍 Máximo 500 caracteres...'
      }
    }
  if (lang === 'en')
    {
      if (!regexText150.test(form.bolderTextEn)) {
        errors.bolderTextEn = '📍 Maximum 150 characters...'
      }
      if (!regexText500.test(form.workTimeEn)) {
        errors.workTimeEn ='📍 Maximum 500 characters...'
      }
      if (!form.descripEn|| form.descripEn === '') {
        errors.descripEn = '📍 The description is required...'
      } else if (!regexText5000.test(form.descripEn)) {
        errors.descripEn = '📍 Maximum 5000 characters...'
      }
      if (!form.titleEn) {
        errors.titleEn = '📍 Title is required..'
      } else if (!regexText150.test(form.title)) {
        errors.titleEn = '📍 Maximum 150 characters...'
      }
    } 
    console.log(errors)
  return errors
}

const initialForm = {
  title: '',
  titleEn: '',
  typeId: 0,
  descrip: '',
  descripEn: '',
  bolderText: '',
  bolderTextEn: '',
  worktime: '',
  worktimeEn: '',
  contactMail: '',
  contactPhone: '',
  webSite: '',
  countryId: 0,
  cityId: 0,
  published: 1,
  user: '',
  imgs: '',
}

const actyear = new Date().getFullYear().toString()

const ServiceUpd = () => {

  const { texts } = useContext(LanguageContext)
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('select,input').forEach((node) =>
      node.addEventListener('keypress', (e) => {
        if (e.keyCode === 13 || e.keyCode === 8 || e.keyCode === 27) {
          e.preventDefault()
        }
      }),
    )
  })
  /*
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('input[type=text]').forEach((node) =>
      node.addEventListener('keypress', (e) => {
        if (e.keyCode == 13 || e.keyCode == 8 || e.keyCode == 27) {
          e.preventDefault()
        }
      }),
    )
  })
*/
  const [selectCity, setSelectCity] = useState(false)
  const firstDiv = useRef()
  const { currentUser } = useContext(AuthContext)
  //  let vuser = currentUser?.dataValues.userName
  const { id } = useParams()

  let typeForm = 'updType'
  
  const {
    form,
    setForm,
    errorsEnd,
    loading,
    responseForm,
    topass,
    imgToDelete,
    setImgToDelete,
    handleChange,
    handleCheck,
    handleBlur,
    handleSubmit,
  } = useFormServ(initialForm, validationsForm, typeForm, id,texts.idiom)

  const [typeName, setTypeName] = useState('')
  const [typeNameEn, setTypeNameEn] = useState('')
 // const [style, setStyle] = useState('black')
  const [countryName, setCountryName] = useState('')
  const [cityName, setCityName] = useState('')
  const [bigImage, setBigImage] = useState(null)
  const [typeList, setTypeList] = useState([])
 // const [ltype, setLtype] = useState('')
//  const [ltypeId, setLtypeId] = useState('0')
  const [vcountryId, setCountryId] = useState('')
  const [countryList, setCountryList] = useState([])
  const [cityList, setCityList] = useState([])
 // const [lcity, setLcity] = useState('')
  const [image, setImage] = useState([])
  //const [writeIdiom, setWriteIdiom] = useState(currentUser?.dataValues.userLang != null ? currentUser?.dataValues.userLang : 'es')
  //const [secondIdiom, setSecondIdiom] = useState(false)
  const [imageList, setImageList] = useState([])
  // const [listUpdated, setListUpdated] = useState(false)
  const [imgItem, setImgItem] = useState([])
  const hiddenFileInput = useRef(null)
  const navigate = useNavigate()
  const goToServiceShow = () => {
    navigate(`/shop/shophome/serviceshow/${id}`)
  }

  const getOfferById = async () => {
    const res = await axios.get(URI + 'sales/' + id)
    setForm(res.data)
    setImgItem(res.data.imgs != '' ? res.data.imgs.split(',').map(Number) : [])
    showDiapositive(res.data.imgs.split(',').map(Number))
    setCountryId(res.data.countryId)
    setTypeName(res.data.type.typeName)
    setTypeNameEn(res.data.type.typeNameEn)
    setCountryName(res.data.country.countryName)
    setCityName(res.data.city.cityName)
  }
  useEffect(() => {
    AOS.init()
    getOfferById()
  }, [])

  useEffect(() => {
    getType()
  }, [])
  const getType = async () => {
    const restype = await axios.get(URI + 'type')
    setTypeList(restype.data.sort((a,b) => a.typeName.localeCompare(b.typeName)))
  }

  useEffect(() => {
    getCountry()
  }, [vcountryId])
  const getCountry = async () => {
    const res = await axios.get(URI + 'country')
    setCountryList(res.data.sort((a,b) => a.countryName.localeCompare(b.countryName)))
  }

  //TO SHOW Cities IN SELECT
  useEffect(() => {
    getCity()
  }, [vcountryId])
  const getCity = async () => {
    const res = await axios.get(URI + 'city')
    form.country == 0
      ? setCityList([])
      : setCityList(
          res.data.filter((dato) => dato.countryId == parseInt(vcountryId)).sort((a,b) => a.cityName.localeCompare(b.cityName)),
        )
  }

  const swap = (val1, val2, arr) => {
    if (!arr.includes(val1) || !arr.includes(val2)) return
    let val1_index = arr.indexOf(val1)
    let val2_index = arr.indexOf(val2)
    arr.splice(val1_index, 1, val2)
    arr.splice(val2_index, 1, val1)
  }

  const handlerSelectFirst = (cImage) => {
    let firstImageId = parseInt(cImage.split('-')[0])
    swap(firstImageId, parseInt(imgItem[0]), imgItem)
    showDiapositive(imgItem)
    form.imgs = imgItem.toString()
    // setListUpdated(true)
    //       setModalIsOpen(false)
    //       document.getElementById('imgInput').value = null
  }

  //Using imgs string and find images to show from selected service
  const showDiapositive = async (allimgs) => {
    console.log(`key in show ${Object.keys(errorsEnd).length}`)
    if (Object.keys(allimgs).length > 0) {
      await axios
        .get(URI + 'images')
        .then((res) => {
          let pru = res.data.filter((dato) =>
            allimgs.includes(parseInt(dato.split('-')[0])),
          )
          if (pru.length > 0) {
            if (parseInt(pru[0].split('-')[0]) != allimgs[0]) {
              let firstIndex = pru
                .map((element) => parseInt(element.split('-')[0]))
                .indexOf(allimgs[0]) //busco el index del id de la primera imagen dentro del arreglo obtenido con las imagenes de ese servicio.
              swap(pru[firstIndex], pru[0], pru)
            }
            setImage(pru[0])
            setBigImage(pru[0])
            setImageList(pru)
          } else {
            setImage(noPhotos)
            setBigImage(noPhotos)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setImage(noPhotos)
    }
  }
  const serviceAdd = async (e) => {
    navigate(`serviceadd`)
  }

  const serviceCancel = () => {
    navigate(`/shop/shophome/serviceshow/${id}`)
  }

  /*
  //TO EXECUTE UPDATING FOR SELECTED SERVICE
  const serviceUpdating = async (e) => {
    try {
      // updating sale in sales table
      e.preventDefault()
      await axios.put(URI + 'sales/' + id, {
        form,
        /* title: vtitle,
        typeId: parseInt(vtype),
        descrip: vdescrip,
        bolderText: vbolder,
        workTime: vtime,
        contactMail: vcontactMail,
        contactPhone: vcontactPhone,
        user: parseInt(currentUser?.dataValues.id),
        countryId: parseInt(vcountry),
        cityId: parseInt(vcity),
        imgs: imgItem.toString(),
        published: vpublished,
      })
      // cleaningForm()

       Swal.fire({
        title: 'Actualizado!!',
        //text: "Oferta de Servicios",
        icon: 'success',
        imageWidth: '0.3rem',
        imageHeight: '0.3rem',
        width: '10rem',
        height: '10rem ',
        position: 'bottom',
      })
      navigate('/shop/shophome')
    } catch (error) {
      Swal.fire({
        title: 'Actualizacion No satisfactoria',
        text: 'Oferta de Servicios',
        icon: 'warning',
      })
    }
  }
  */
  // send to serviceAdd

  // para guardar imagen para borrar cuando se acepten los cambios----ojooooooo con ESTO, HACERLO

  /* await axios.delete(URI+'images/'+imageId) 
        cuando salve las modificaciones salvo en sales solo las activas y listo
        si puedo despues busco como eliminar las imagenes que ya no se usan
        en un mantenimiento o algo asi comparando images con sales */

  //TO DELETE IMAGE FROM IMGITEM AND SHOW AGAIN IMAGES

  const handlerDeleteImage = (cImage) => {
    let imageId = parseInt(cImage.split('-')[0])
    // imageId = parseInt(imageId[0])
    const newImgItem = imgItem.filter((img) => img !== imageId)
    setImgToDelete([...imgToDelete, imageId])
    setImgItem(newImgItem)
    form.imgs = newImgItem.toString()
    showDiapositive(newImgItem)
    //setListUpdated(true) // OJOOOOOOOO VER SI ESTO AUN PROCEDE O SE ESTA ACTUALIZANDO DE OTRA MANERA
    //       setModalIsOpen(false)
    /* document.getElementById('imgInput').value = null*/
  }

  const handleAddImage = async (e) => {
    if (e.target.files[0]) {
      if (imgItem.length < 6) {
        const imgFormData = new FormData()
        imgFormData.append('image', e.target.files[0])
        e.preventDefault()
        await axios({
          method: 'post',
          url: URI + 'images',
          data: imgFormData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(function (response) {
            // setListUpdated(true)
            showDiapositive([...imgItem, parseInt(response.data.split('-')[0])])
            /*            if (imgItem != []) {
              alert('1')
              setImgItem([...imgItem, parseInt(response.data.split('-')[0])])
              form.imgs = [
                ...imgItem,
                parseInt(response.data.split('-')[0]),
              ].toString()
            } else {
              alert('2')
              setImgItem(parseInt(response.data.split('-')[0]))
              form.imgs = parseInt(response.data.split('-')[0]).toString()
            }
          })
          */
            setImgItem([...imgItem, parseInt(response.data.split('-')[0])])
            form.imgs = [
              ...imgItem,
              parseInt(response.data.split('-')[0]),
            ].toString()
          })
          .catch(function (response) {
            Swal.fire(
              '',
              `IMAGEN 1 no adicionada, vuelva a intentarlo! Error: ${response}`,
              'error',
            )
          })
      } else
        Swal.fire(
          '',
          'IMAGEN no adicionada, excedio la cantidad posible (5) , elimine alguna para adicionar nueva!',
          'error',
        )
    } else console.log('no se selecciono ningun file')
  }

  const handlerClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleSelectType = (e) => {
      setTypeName(e.target.options[e.target.selectedIndex].text)
    handleChange(e)
  }

  const handleSelectCountry = (e) => {
    setCountryId(e.target.value)
    setCountryName(e.target.options[e.target.selectedIndex].text)
    setSelectCity(true)
    handleChange(e)
  }

  const handleSelectCity = (e) => {
    setCityName(e.target.options[e.target.selectedIndex].text)
    handleChange(e)
  }
  useEffect(() => {
    firstDiv.current.focus()
  }, [])
/*
  const handleWriteIdiom = () => {
      setWriteIdiom(writeIdiom === 'es' ? 'en' : 'es')
      setSecondIdiom(true)
  
  }
*/
  return (
    <div className="serviceform center-class " tabIndex={-1} ref={firstDiv}>
      <Container className="m-0 p-1">
        <Row>
          <Col lg={12} className="section-title">
            <h2 data-aos="fade-left" data-aos-delay="10">
            {texts.seProp} {actyear}
            </h2>
            <p
              data-aos="fade-right"
              data-aos-delay="10"
              style={{ fontFamily: 'fantasy' }}
            >
              {texts.seUpdating}
            </p>
          </Col>
        </Row>
        {currentUser ? (
          <Form>
            <Card className="mb-2">
              <Row>
                <Col>
                  {(topass != null && topass.substr(0, 6)) === texts.seCheck && (
                    <p className="auth-err">{topass}</p>
                  )}
                </Col>
              </Row>
              <Card.Header className="service-headfoot">
                <Row>
                  <Col lg={12}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ alignItems: 'center' }}>
                        <Button
                          size="sm"
                          onClick={goToServiceShow}
                          variant="link"
                        >
                          <Badge bg="light" className="button-shadow">
                            <IoArrowUndoOutline
                              style={{ fontSize: '1rem', color: '#641e02' }}
                              title={texts.backPage}
                            />
                          </Badge>
                        </Button>
                      </div>
                      {(form.user === currentUser?.dataValues.id || currentUser?.dataValues.access==='admin')? (
                        <div style={{ marginLeft: 'auto' }}>
                          <Button
                            className="me-3 button-shadow"
                            size="sm"
                            variant="warning"
                            onClick={serviceCancel}
                            title={texts.seCancelUpd}
                          >
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaRegWindowClose style={{ fontSize: '1rem' }} />
                              {texts.seCancel}
                            </div>
                          </Button>

                          <Button
                            className="me-3 button-shadow"
                            size="sm"
                            onClick={handleSubmit}
                            variant="success"
                            title={texts.seUpdChange}
                          >
                            {loading && (
                              <Spinner
                                style={{ textAlign: 'center' }}
                                size="sm"
                                animation="border"
                                variant="light"
                              />
                            )}
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaRegCheckSquare style={{ fontSize: '1rem' }} />{' '}
                              {texts.seUpdate}
                            </div>
                          </Button>
                        </div>
                      ) : (
                        <div style={{ marginLeft: 'auto' }}>
                          <Button
                            className="me-3 button-shadow"
                            size="sm"
                            onClick={serviceAdd}
                            variant="success"
                            title={texts.seAdd}
                          >
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <TfiPencilAlt style={{ fontSize: '1rem' }} />
                              {texts.seAdd}
                            </div>
                          </Button>
                          <Button
                            className="me-3 button-shadow"
                            size="sm"
                            onClick={goToServiceShow}
                            variant="danger"
                            title={texts.backPage}
                          >
                            <div style={{ color: 'ffffff', display: 'flex' }}>
                              <FaArrowAltCircleLeft
                                style={{ fontSize: '1rem' }}
                              />{' '}
                              {texts.seReturn}
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="p-3">
              <Row>
                  <Col>                       
                      <div className='d-flex section-title'>
                      {texts.idiom ==='es' ?                            
                            <p  style={{fontSize:'0.9rem'}}>
                                  Su oferta se crea en Español,{' '}     
                                    <img
                                      src={spa}
                                      alt=""
                                      title="Idioma"
                                      className="navicon"
                                    /> 
                             
                                  
                                  {' '} para adicionar en Inglés también, cambie el Idioma del Sitio y vuelva a Editarla en ese Idioma... 
                              </p>
                         
                              :
                              
                              <p  style={{fontSize:'0.9rem'}}>
                                Your data is adding in English,{' '}     
                                    <img
                                      src={eng}
                                      alt=""
                                      title="English"
                                      className="navicon"
                                    /> 
                             
                                  
                                  .{' '} To add it in Spanish too, change the site language and edit it again...
                                  
                              </p>
                              
                          }
                        </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {responseForm === true && (
                      <TimerMessage
                        mess={texts.seUpdService}
                        variant={'success'}
                        navig={`/shop/shophome/serviceshow/${id}`}
                        timetogo="1000"
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Card
                      className="services-td ms-2"
                      data-aos="fade-left"
                      data-aos-delay="2"
                    >
                      <Card.Header className="service-header">
                        <InputGroup size="sm" className="mb-3">
                          <Row>
                            <Col xs={4}>
                              <FloatingLabel label={texts.seService}>
                                <Form.Select
                                  name="typeId"
                                  onChange={handleSelectType}
                                  className="service-titleII"
                                  value={form.typeId}
                                >
                                  {typeList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {texts.idiom ==='es' ? item.typeName: item.typeNameEn}
                                    </option>
                                  ))}
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                            <Col>
                              <FloatingLabel label={texts.seTitle}>
                                <Form.Control
                                  name={texts.idiom === 'es' ? 'title' : 'titleEn'}
                                  className="service-title"
                                  value={texts.idiom === 'es' ? form.title : form.titleEn}
                                  onChange={handleChange}
                                  
                                  type="text"
                                />
                              </FloatingLabel>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              {errorsEnd.title && (
                                <p className="service-err">{errorsEnd.title}</p>
                              )}
                              {errorsEnd.titleEn && (
                                <p className="service-err">{errorsEnd.titleEn}</p>
                              )}
                            </Col>
                          </Row>
                        </InputGroup>
                      </Card.Header>
                      <Card.Body>
                        <Row>
                          <Col className="mb-2">
                            <InputGroup size="lg" className="mb-1">
                              <FloatingLabel label={texts.seDescrip}>
                                <Form.Control
                                  name={texts.idiom ==='es' ? 'descrip' :'descripEn'}
                                  value={texts.idiom ==='es' ? form.descrip : form.descripEn}
                                  onChange={handleChange}
                                  style={{height:'10rem'}}
                                  as="textarea"
                                  rows={10}
                                  
                                  className="service-text"
                                />
                              </FloatingLabel>
                              <Row>
                                <Col>
                                  {errorsEnd.descrip && (
                                    <p className="service-err">
                                      {errorsEnd.descrip}
                                    </p>
                                  )}
                                  {errorsEnd.descripEn && (
                                    <p className="service-err">
                                      {errorsEnd.descripEn}
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <FloatingLabel label={texts.seBolder}>
                              <Form.Control                                
                                name={texts.idiom === 'es' ? 'bolderText' : 'bolderTextEn'}
                                value={texts.idiom === 'es' ? form.bolderText : form.bolderTextEn}
                                onChange={handleChange}
                                
                                type="text"
                                className="service-bolder"
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {errorsEnd.bolderText && (
                              <p className="service-err">
                                {errorsEnd.bolderText}
                              </p>
                            )}
                            {errorsEnd.bolderTextEn && (
                              <p className="service-err">
                                {errorsEnd.bolderTextEn}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <FloatingLabel label={
                                <span>
                                  <FaRegClock />{texts.seHours}
                                </span>
                              }>
                              <Form.Control
                                name={texts.idiom === 'es' ? 'workTime' : 'workTimeEn'}
                                value={texts.idiom === 'es' ? form.workTime: form.workTimeEn }
                                onChange={handleChange}
                                
                                type="text"
                                className="service-city"
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {errorsEnd.workTime && (
                              <p className="service-err">{errorsEnd.workTime}</p>
                            )}
                            {errorsEnd.workTimeEn && (
                              <p className="service-err">{errorsEnd.workTimeEn}</p>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className="mb-3">
                            <FloatingLabel label={
                                <span>
                                  <FaMobileScreen />{texts.phone}
                                </span>
                              }
                              >
                              <Form.Control
                                value={form.contactPhone}
                                name="contactPhone"
                                onChange={handleChange}
                                
                                type="text"
                                className="service-phone"
                              />
                            </FloatingLabel>
                          </Col>
                          <Col className="mb-3">
                            <FloatingLabel label={
                                <span>
                                  <FaRegEnvelope /> {texts.siEmail}
                                </span>
                              }>
                              <Form.Control
                                value={form.contactMail}
                                name="contactMail"
                                onChange={handleChange}
                                
                                type="text"
                                className="service-phone"
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {errorsEnd.contactPhone && (
                              <p className="service-err">
                                {errorsEnd.contactPhone}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {errorsEnd.contactMail && (
                              <p className="service-err">
                                {errorsEnd.contactMail}
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mb-3">
                            <FloatingLabel
                              label={
                                <span>
                                  <GiLaptop />{texts.seWeb}
                                </span>
                              }
                            >
                               <Form.Control
                                name='webSite'
                                value={form.webSite}
                                onChange={handleChange}
                                
                                type="text"
                                className="service-city"
                              />
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {errorsEnd.webSite && (
                              <p className="service-err">{errorsEnd.webSite}</p>
                            )}                            
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className="mb-3">
                          <FloatingLabel
                              label={
                                <span>
                                  <FaGlobeAmericas /> {texts.country}
                                </span>
                              }
                            >
                              <Form.Select
                                name="countryId"
                                className="service-city"
                                onChange={handleSelectCountry}
                                value={form.countryId}
                              >
                                {countryList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.countryName}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                          <Col className="mb-3">
                          <FloatingLabel
                              label={
                                <span>
                                  <FaCity />{texts.city}
                                </span>
                              }
                            >
                              <Form.Select
                                name="cityId"
                                className="service-city"
                                onChange={handleSelectCity}
                                value={form.cityId}
                              >
                                {selectCity && (
                                  <option value="0" selected>
                                    {texts.seSelect}
                                  </option>
                                )}
                                {cityList.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.cityName}
                                  </option>
                                ))}
                              </Form.Select>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} className="service-city pt-0">
                            {imageList.length < 5 ? (
                              <Form.Group controlId="imgInput">
                                <Form.Control
                                  type="file"
                                  size="sm"
                                  onChange={handleAddImage}
                                  ref={hiddenFileInput}
                                  style={{ display: 'none' }}
                                />
                                <Button
                                  size="sm"
                                  className="button-shadow"
                                  onClick={handlerClick}
                                  variant="warning"
                                  text="dark"
                                  style={{ fontSize: '0.8rem' }}
                                >
                                  <FaRegImages />
                                  &nbsp;{texts.seNewImg}
                                </Button>
                              </Form.Group>
                            ) : (
                              <Alert
                                variant="primary"
                                style={{ fontSize: '0.8rem' }}
                              >
                                {texts.seMaxImg}
                              </Alert>
                            )}
                          </Col>
                          <Col className="service-city pt-0 pb-0">
                            <Form.Check
                              type="checkbox"
                              name="published"
                              style={{ paddingTop: '0.4rem' }}
                              value={form.published}
                              onChange={handleCheck}
                              checked={form.published}
                              label={texts.sePubOffer}
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg={6} md={12} sm={12} xs={12}>
                    <Row style={{ height: '50vh' }}>
                      <Col className="service-imgfull">
                        {bigImage != null ? (
                          <p>
                            <Image
                              src={
                                bigImage.includes('nofoto')
                                  ? bigImage
                                  : URIMG + bigImage
                              }
                              style={{ width: '100%', height: '100%' }}
                              alt=""
                              fluid
                            />
                            <div className="buttons-bigimage">
                              <Button
                                className="button-shadow me-1"
                                size="sm"
                                onClick={() => handlerSelectFirst(bigImage)}
                                variant="success"
                                title={texts.seFavImg}
                              >
                                <FaStar
                                  style={{
                                    fontSize: '1rem',
                                    color: 'e3d8c3',
                                  }}
                                />
                              </Button>
                              <Button
                                className="button-shadow"
                                size="sm"
                                onClick={() => handlerDeleteImage(bigImage)}
                                variant="danger"
                                title={texts.seDelImg}
                              >
                                <FaRegTrashCan
                                  style={{
                                    fontSize: '1rem',
                                    color: '#e3d8c3',
                                  }}
                                />
                              </Button>
                            </div>
                          </p>
                        ) : (
                          <Image
                            src={noPhotos}
                            style={{ width: '100%', height: '100%' }}
                            alt=""
                            fluid
                          />
                        )}
                      </Col>
                    </Row>
                    {imageList.length > 0 && (
                      <Row
                        style={{ height: '9vh', display: 'flex' }}
                        className="mt-5"
                      >
                        {imageList.map((image) => (
                          <Col
                            key={image}
                            className="me-2 mt-2 p-0"
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                          >
                            <Image
                              src={URIMG + image}
                              onClick={() => setBigImage(image)}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              className="diapositive"
                              title={texts.seBiggest}
                              fluid
                              thumbnail
                            />
                          </Col>
                        ))}
                      </Row>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="mt-5 d-flex service-inter  service-headfoot">
                <div className="service-titleII service-title-size">
                {texts.year}&nbsp;{actyear}
                </div>
                {(form.user == currentUser?.dataValues.id || currentUser?.dataValues.access==='admin') ? (
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      className="me-3 button-shadow"
                      size="sm"
                      variant="warning"
                      onClick={serviceCancel}
                      title={texts.seCancelUpd}
                    >
                      <div style={{ color: 'ffffff', display: 'flex' }}>
                        <FaRegWindowClose style={{ fontSize: '1rem' }} />
                        {texts.seCancel}
                      </div>
                    </Button>

                    <Button
                      className="me-3 button-shadow"
                      size="sm"
                      onClick={handleSubmit}
                      variant="success"
                      title={texts.seUpdChange}
                    >
                      {loading && (
                        <Spinner
                          style={{ textAlign: 'center' }}
                          size="sm"
                          animation="border"
                          variant="light"
                        />
                      )}
                      <div style={{ color: 'ffffff', display: 'flex' }}>
                        <FaRegCheckSquare style={{ fontSize: '1rem' }} />{' '}
                        {texts.seUpdate}
                      </div>
                    </Button>
                  </div>
                ) : (
                  <div style={{ marginLeft: 'auto' }}>
                    <Button
                      className="me-3 button-shadow"
                      size="sm"
                      onClick={serviceAdd}
                      variant="success"
                      title={texts.seAdd}
                    >
                      <div style={{ color: 'ffffff', display: 'flex' }}>
                        <TfiPencilAlt style={{ fontSize: '1rem' }} />
                        {texts.seAdd}
                      </div>
                    </Button>
                    <Button
                      className="me-3 button-shadow"
                      size="sm"
                      onClick={goToServiceShow}
                      variant="danger"
                      title={texts.seBackPage}
                    >
                      <div style={{ color: 'ffffff', display: 'flex' }}>
                        <FaArrowAltCircleLeft style={{ fontSize: '1rem' }} />{' '}
                        Regresar
                      </div>
                    </Button>
                  </div>
                )}
              </Card.Footer>
            </Card>
          </Form>
        ) : (
          <TimerMessage
            mess={texts.Register}
            variant={'danger'}
            navig={'../shop/shophome/1/0'}
            timetogo="2000"
          />
        )}
      </Container>
    </div>
  )
}

export default ServiceUpd

