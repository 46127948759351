import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
import GetRating from '../components/GetRating.js'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { TimerMessage } from '../components/TimerMessage'

const URI = `${process.env.REACT_APP_BACKEND}`

const ServiceEval = () => {
  //TO SHOW YEAR
  const actyear = new Date().getFullYear().toString()
  const { texts } = useContext(LanguageContext)
  const { currentUser } = useContext(AuthContext)
  let vuser = currentUser?.dataValues.userName

  // Item id
  const { id } = useParams()

  // to show one sale
 // const [vsale, setSale] = useState([])
  const [vtitle, setTitle] = useState('')
  const [vtypeName, setTypeName] = useState('')

  //NAVEGACION
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }

  //TO GET DATA FOR ONE SERVICE
  const getOfferById = async () => {
    const res = await axios.get(URI + 'sales/' + id)
    setTitle(res.data.title)
    setTypeName(res.data.type.typeName)
  }

  //Executing only one time to get data to show selected service
  useEffect(() => {
    AOS.init()
    getOfferById()
     //eslint-disable-next-line
  }, [])

  return (
    <div className="serviceform center-class">
      <Container className="m-0 p-1">
        <Row>
          <Col lg={12} className="section-title" data-aos="fade-up">
          <h2 data-aos="fade-top" data-aos-delay="10">
              {texts.seProp} {actyear}
            </h2>
            <p>{texts.siEvalTit}</p>
            <h1 className="service-header      ">
              <span style={{ color: '#000000' }}>Tipo de Servicio:</span>{' '}
              {vtypeName}
            </h1>
          </Col>
        </Row>
        {vtitle !== '' ? (
          <>
            <Row>
              <Col lg={12} className="d-flex">
                <div>
                  <Button size="sm" onClick={goBack} variant="link">
                    <Badge bg="light" className="button-shadow">
                      <IoArrowUndoOutline
                        style={{
                          fontSize: '1rem',
                          color: '#641e02',
                        }}
                        title="Regresar a Página Anterior..."
                      />
                    </Badge>
                  </Button>
                </div>
                <div>
                  <span className="service-title">{vtitle}</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card style={{ border: 'none', paddingTop: '1rem' }}>
                  {vuser ? (
                    <GetRating
                      propsType="serv"
                      propsId={id}
                      propsUser={vuser}
                      navig={-1}
                    />
                  ) : (
                    <TimerMessage
                      mess={
                        texts.evalRegis
                      }
                      variant={'danger'}
                      navig={`back`}
                      timetogo="2000"
                    />
                  )}
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          true
        )}
      </Container>
    </div>
  )
}

export default ServiceEval
