import React, { useState, useEffect, useContext, useRef } from 'react'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import axios from 'axios'
import { useParams, useNavigate, Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Button,
  Badge,
} from 'react-bootstrap'
import {
  FaCity,
  FaGlobeAmericas,
  FaRegEdit,
  FaRegClock,
} from 'react-icons/fa'
import { FaRegTrashCan, FaRegEnvelope, FaMobileScreen } from 'react-icons/fa6'
import { TiPencil } from 'react-icons/ti'
import { GiLaptop } from "react-icons/gi";
import { IoArrowUndoOutline } from 'react-icons/io5'
import { BiMessageEdit } from 'react-icons/bi'
import { BsExclamationCircle } from 'react-icons/bs'
import GetRatingTotal from '../components/GetRatingTotal.js'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../shop/shopHome.css'
//importing images
import noPhotos from '../img/shop/nofotos.jpg'
import Swal from 'sweetalert2'

const URI = `${process.env.REACT_APP_BACKEND}`
const URIMG = `${process.env.REACT_APP_BACKENDPUB}`

const ServiceShow = () => {
  const { texts } = useContext(LanguageContext)
  const firstDiv = useRef(null)
  const { currentUser } = useContext(AuthContext)
  const { id } = useParams()
  const vuseraccess = currentUser?.dataValues.access != null ? currentUser?.dataValues.access : ''
  const [form, setForm] = useState({})
  const [typeName, setTypeName] = useState('')
  const [typeNameEn, setTypeNameEn] = useState('')
  const [countryName, setCountryName] = useState('')
  const [cityName, setCityName] = useState('')
  const [image, setImage] = useState([])
  const [bigImage, setBigImage] = useState([])
  const [imageList, setImageList] = useState([])
  
  const [imgItem, setImgItem] = useState([])
  //const [yesTotal, setYesTotal] = useState(0)

  const navigate = useNavigate()
  const actyear = new Date().getFullYear().toString()
  /*
  function infocus(idElemento) {
    document.getElementById(idElemento).focus()
  }
*/
  const getOfferById = async () => {
    const res = await axios.get(URI + 'sales/' + id)
    setImgItem(res.data.imgs.split(',').map(Number))
    showDiapositive(res.data.imgs.split(',').map(Number))
    setTypeName(res.data.type.typeName)
    setTypeNameEn(res.data.type.typeNameEn)
    setCountryName(res.data.country.countryName)
    setCityName(res.data.city.cityName)
    setForm(res.data)
    console.log(res.data)
  }

  const Mailto = ({ email, subject, body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  useEffect(() => {
    AOS.init()
    getOfferById() 
     //eslint-disable-next-line
  }, [])

  const serviceAdd = async (e) => {
    navigate(`/shop/shophome/serviceadd`)
  }

  const serviceUpdate = () => {
    navigate(`/shop/shophome/serviceupd/${id}`)
  }

  const goToShopHome = () => {
    navigate(`/shop/shophome/1/0`)
  }
  const swap = (val1, val2, arr) => {
    if (!arr.includes(val1) || !arr.includes(val2)) return
    let val1_index = arr.indexOf(val1)
    let val2_index = arr.indexOf(val2)
    arr.splice(val1_index, 1, val2)
    arr.splice(val2_index, 1, val1)
  }
  const serviceDelete = () => {
    {vuseraccess === 'admin' ? 
          Swal.fire({
            title: 'Esta seguro de Eliminar la Oferta?',
            text: 'No podra recuperarla una vez borrada!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminela!',
          }).then((result) => {
            if (result.isConfirmed) {
              try {
                // deleting sale in sales table
                axios.delete(URI + 'sales/' + id)
                Swal.fire({
                  title: 'Eliminada!',
                  text: 'Oferta de Servicios',
                  icon: 'success',
                })
                navigate('/shop/shophome/1/0')
              } catch (error) {
                Swal.fire({
                  title: 'No fue posible eliminar la oferta!',
                  text: 'Oferta de Servicios',
                  icon: 'warning',
                })
              }
            }
          })
    :
    Swal.fire({
      title: 'Esta seguro de Eliminar la Oferta?',
      text: 'Por el momento solo será marcada para no ser Publicada!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminela!',
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          form.published = false
          axios.put(URI + 'sales/' + id, form)
          Swal.fire({
            title: 'Eliminada su Publicación!',
            text: 'Oferta de Servicios',
            icon: 'success',
          })
          navigate('/shop/shophome/1/0')
        } catch (error) {
          Swal.fire({
            title: 'No fue posible eliminar la oferta!',
            text: 'Oferta de Servicios',
            icon: 'warning',
          })
        }
      }
    })


    }
  }
  const showDiapositive = async (allimgs) => {
    await axios
      .get(URI + 'images')
      .then((res) => {
        let pru = res.data.filter((dato) =>
          allimgs.includes(parseInt(dato.split('-')[0])),
        )
        if (pru.length > 0) {
          if (parseInt(pru[0].split('-')[0]) !== allimgs[0]) {
            let firstIndex = pru
              .map((element) => parseInt(element.split('-')[0]))
              .indexOf(allimgs[0]) //busco el index del id de la primera imagen dentro del arreglo obtenido con las imagenes de ese servicio.
            swap(pru[firstIndex], pru[0], pru)
          }
          setImage(pru[0])
          setBigImage(pru[0])
          setImageList(pru)
        } else {
          setImage(noPhotos)
          // setBigImage(noPhotos)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    firstDiv.current.focus()
  }, [])

  return (
    <div className="serviceform center-class " tabIndex={-1} ref={firstDiv}>
      <Container className="m-0 p-1">
        <Row>
          <Col lg={12} className="section-title">
            <h2 data-aos="fade-top" data-aos-delay="10">
              {texts.seProp} {actyear}
            </h2>
            <p
              data-aos="fade-down"
              data-aos-delay="10"
              style={{ fontFamily: 'fantasy' }}
            >
              {texts.seDetails}
            </p>
          </Col>
        </Row>
        
          <Card className="mb-2">
            <Card.Header className="service-headfoot">
              <Col lg={12}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Button size="sm" onClick={goToShopHome} variant="link">
                      <Badge bg="light" className="button-shadow">
                        <IoArrowUndoOutline
                          style={{
                            fontSize: '1rem',
                            color: '#641e02',
                          }}
                          title={texts.backPage}
                        />
                      </Badge>
                    </Button>
                  </div>
                  {(form.user === currentUser?.dataValues.id || currentUser?.dataValues.access==='admin') && (
                    <div style={{ marginLeft: 'auto' }}>
                      <Button
                        className="me-3 button-shadow"
                        size="sm"
                        type="none"
                        onClick={serviceAdd}
                        variant="warning"
                        title={texts.seAddTitle}
                      >
                        <div style={{ color: 'ffffff', display: 'flex' }}>
                          <FaRegEdit style={{ fontSize: '1.2rem' }} />
                          {texts.seOffer}
                        </div>
                      </Button>
                      <Button
                        className="me-3 button-shadow"
                        size="sm"
                        onClick={serviceUpdate}
                        variant="warning"
                        title={texts.seUpd}
                      >
                        <div style={{ color: 'ffffff', display: 'flex' }}>
                          <TiPencil style={{ fontSize: '1.2rem' }} />
                          {texts.seUpdate}
                        </div>
                      </Button>
                      <Button
                        className="me-3 button-shadow"
                        size="sm"
                        onClick={() => serviceDelete()}
                        variant="danger"
                        title={texts.seDelete}
                      >
                        <div style={{ color: 'ffffff', display: 'flex' }}>
                          <FaRegTrashCan style={{ fontSize: '1.2rem' }} />
                          {texts.seDel}
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Card.Header>
            <Card.Body className="p-3">
              <Row>
                <Col lg={6} md={12} sm={12} xs={12}>
                  <Card
                    className="services-td"
                    data-aos="fade-left"
                    data-aos-delay="2"
                  >
                    <Card.Header className="service-text">
                      <div className="service-header">
                        &nbsp;☞
                        &nbsp;
                        <span className="service-title">{
                          texts.idiom === 'es' ? form.title : form.titleEn                            
                        }</span>{' '}
                      </div>
                      <div className="service-titleII">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;◇&nbsp;{
                        texts.idiom === 'es' ? typeName : typeNameEn
                        
                      }</div>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="mb-3">
                          <BiMessageEdit />{' '}
                          <span className="service-city">
                            {
                              texts.idiom === 'es' ? form.descrip : form.descripEn
                            }                            
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className="mt-0 mb-1 ms-3 me-3 p-2"
                          
                        >
                          <span className="service-bolder">
                            <BsExclamationCircle /> {
                             texts.idiom === 'es' ? 
                             form.bolderText : 
                             form.bolderTextEn 
                            }
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col className="mb-3">
                          <span className="service-text">
                            <FaRegClock /> {texts.seHours}:{' '}
                            <span className="service-city">
                            {texts.idiom === 'es' ? form.workTime:form.workTimeEn}
                             
                            </span>
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col xs={6} className="mb-3">
                          <span className="service-text">
                            <FaMobileScreen /> {texts.phone}:{' '}
                            <span className="service-phone">
                              {form.contactPhone !== ''
                                ? form.contactPhone
                                : texts.seWithoutData}
                            </span>
                          </span>
                        </Col>
                        <Col className="mb-3">
                          <span className="service-text">
                            <FaRegEnvelope /> Email:{' '}
                            <span className="service-phone">
                              
                                  {form.contactMail 
                                    ?  <Mailto email={form.contactMail} subject={texts.seSubjectMail} body=''>{form.contactMail}</Mailto> 
                                    : texts.seWithoutData}
                                
                            </span>
                          </span>
                        </Col>
                      </Row>
                      
                      <hr />
                      <Row>
                        <Col className="mb-3">
                          <span className="service-text">
                            <GiLaptop/> {texts.seWeb}:{' '}
                            <span className="service-city">
                                <Link to={form.webSite} target="_blank">{form.webSite} </Link>
                            </span>
                          </span>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col xs={6} className="mb-3">
                          <span className="service-text">
                            <FaGlobeAmericas /> Pais:{' '}
                            <span className="service-city">{countryName}</span>
                          </span>
                        </Col>
                        <Col className="mb-3">
                          <span className="service-text">
                            <FaCity /> {texts.seCity}:{' '}
                            <span className="service-city">{cityName}</span>
                          </span>
                        </Col>
                      </Row>
                    </Card.Body>

                    <Card.Footer className="service-footer p-1"></Card.Footer>
                  </Card>
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                  <Row className="mb-3">
                    <Col>
                      <GetRatingTotal
                        propsType="serv"
                        propsId={id}
                        yescomment={true}
                        onlystars={false}
                        withEval={true}
                      />
                    </Col>
                  </Row>
                  <Row style={{ height: '45vh' }}>
                    <Col className="mt-0 ms-2 me-2 service-imgfull">
                      {bigImage !== null && bigImage !== '' ? (
                        <Image
                          src={URIMG + bigImage}
                          style={{ width: '100%', height: '100%' }}
                          alt={bigImage}
                          fluid
                        />
                      ) : (
                        <Image
                          src={noPhotos}
                          alt="2"
                          style={{ width: '100%', height: '100%' }}
                          fluid
                        />
                      )}
                    </Col>
                  </Row>

                  {imageList.length > 0 ? (
                    <Row style={{ height: '9vh' }}>
                      {imageList.map((image) => (
                        <Col
                          key={image}
                          className="me-2 mt-2 p-0"
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                        >
                          <Image
                            src={URIMG + image}
                            onClick={() => setBigImage(image)}
                            style={{ width: '100%', height: '100%' }}
                            className="diapositive"
                            title={texts.seEnlarge}
                            fluid
                            thumbnail
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    true
                  )}
                </Col>
              </Row>
            </Card.Body>

            <Card.Footer className="mt-5 d-flex  service-inter service-headfoot">
              <div className="service-titleII service-title-size">
                {texts.year}&nbsp;{actyear}
              </div>
              {(form.user === currentUser?.dataValues.id || currentUser?.dataValues.access==='admin') && (
                <div style={{ marginLeft: 'auto' }}>
                  <Button
                    className="me-3 button-shadow"
                    size="sm"
                    type="none"
                    onClick={serviceAdd}
                    variant="warning"
                    title={texts.seAddTitle}
                  >
                    <div style={{ color: 'ffffff', display: 'flex' }}>
                      <FaRegEdit style={{ fontSize: '1.2rem' }} />
                      {texts.seOffer}
                    </div>
                  </Button>
                  <Button
                    className="me-3 button-shadow"
                    size="sm"
                    onClick={serviceUpdate}
                    variant="warning"
                    title={texts.seUpd}
                  >
                    <div style={{ color: 'ffffff', display: 'flex' }}>
                      <TiPencil style={{ fontSize: '1.2rem' }} />
                      {texts.seUpdate}
                    </div>
                  </Button>
                  <Button
                    className="me-3 button-shadow"
                    size="sm"
                    onClick={() => serviceDelete()}
                    variant="danger"
                    title={texts.seDelete}
                  >
                    <div style={{ color: 'ffffff', display: 'flex' }}>
                      <FaRegTrashCan style={{ fontSize: '1.2rem' }} />
                      {texts.seDel}
                    </div>
                  </Button>
                </div>
              )}
            </Card.Footer>
          </Card>
    
        <Row data-aos="fade-left" className="mt-5">
          <Col>
            <span className="service-title">{texts.seAbout}</span>
            <p>
              {' '}
              <GetRatingTotal
                propsType="serv"
                propsId={id}
                yesComment={true}
                onlyStars={false}
                withEvals={false}
              />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ServiceShow

/*
 className=" withborder mt-0 mb-1 ms-3 me-3 p-2"
                          style={{ background: '#f4efda' }}
*/