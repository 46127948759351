//import React from 'react';
import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
// importing aos
import AOS from 'aos'
import axios from 'axios'
import 'aos/dist/aos.css'
import './aboutUs.css'
import { Row, Container, Col} from 'react-bootstrap'
import { FaRegEnvelope } from 'react-icons/fa6'
import { BiSolidDonateHeart } from 'react-icons/bi'
import LanguageContext from '../context/LanguageContext'

const URI = `${process.env.REACT_APP_BACKEND}`

const AboutUs = () => {
  const { texts } = useContext(LanguageContext)

  useEffect(() => {
    AOS.init()
  }, [])

  const [form, setForm] = useState([])

  const Mailto = ({ email, subject, body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const handleSendMessage = async() => {
    await axios
    .post(URI + 'nodemailer/usersend', {
      gmail:form.email,
      name: form.nameuser,
      subject: form.subject,
      message: form.message,
      
    })
    .then(() => alert('lo envie'))
    .catch('nuevo pero no lo envie')
    
  }

  /*const handleSendMessageSite = async() => {
    await axios
    .post(URI + 'nodemailer/sitesend', {
      gmail:'marythel1964@gmail.com',
      name: "mensaje para mary",
    })
   // .then(() => alert('lo envie'))
   // .catch('nuevo pero no lo envie')
    
  }
*/
  return (
    <Container className="aboutus">
       <Row>
          <Col lg={12} className="section-title" data-aos="fade-up">
          <h2 data-aos="fade-left" data-aos-delay="10">
            {texts.conTitle} 
            </h2>
            <p
              data-aos="fade-right"
              data-aos-delay="10"
              style={{ fontFamily: 'fantasy' }}
            >
              {texts.conSubt}
            </p>
            
          </Col>
        </Row>
        <Row>
        <Col lg={12}>
            <p style={{fontFamily:'fantasy'}}>{texts.conHeader}</p>
        </Col>
        </Row>
      

      <Row>
        <Col xs={4} className="mt-5" data-aos="fade-right" data-aos-delay="10">
            <div className="d-flex mt-3">
              <span className="col1-icon center-class"><FaRegEnvelope /></span>
               Email:
            </div>
            <p className="ms-4"><Mailto email='avenidacuba2024@gmail.com' subject='' body=''>avenidacuba2024@gmail.com</Mailto> </p>
           {/* <div className="d-flex mt-3">
              <span className="col1-icon center-class"><FaMobileScreen/></span>
              {texts.conCall}:
            </div>
            <p className="ms-4">+1 5589 55488 55s</p>
            */}
            <Link to="/donation" alt={texts.conMakeDon} title={texts.conMakeDon}>
              <div className="d-flex mt-3">
                <span className="col1-icon center-class"><BiSolidDonateHeart /></span>
                {texts.conDonation}
              </div>
            </Link>
          
        </Col>
        <Col xs={8} className="mt-5" data-aos="fade-left" data-aos-delay="20">
          <form action="" method="post" className="php-email-form">
            <div className="row">
              <div className="col-md-6 form-group">
                <input
                  type="text"
                  name="nameuser"
                  className="form-control"
                  id="nameuser"
                  value={form.nameuser}
                  placeholder={texts.conName}
                  required
                />
              </div>
              <div className="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder={texts.conMail}
                  required
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control"
                name="subject"
                id="subject"
                placeholder={texts.conSubject}
                required
              />
            </div>
            <div className="form-group mt-3">
              <textarea
                className="form-control"
                name="message"
                rows="5"
                placeholder={texts.conObject}
                required
              ></textarea>
            </div>
            <div className="my-3">
              <div className="loading">Loading</div>
              <div className="error-message"></div>
              <div className="sent-message">
                {texts.conSended}
              </div>
            </div>
            <div className="text-center">
              <button type="submit" onClick={handleSendMessage}>{texts.conSend}</button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutUs
