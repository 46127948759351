import React, { useEffect } from 'react'
// importing aos
import AOS from 'aos'
import 'aos/dist/aos.css'
import './arts.css'
//images
import artimg1 from '../img/arts/arts-1.jpg'
import artimg2 from '../img/arts/arts-2.jpg'
import artimg3 from '../img/arts/arts-3.jpg'
import artimg4 from '../img/arts/arts-4.jpg'

const Arts = () => {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <section id="arts" className="arts">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>Artesania Fina Cubana</h2>
          <p>Lo más representativo de su cultura</p>
        </div>
        <div className="row" data-aos="fade-left">
          <div className="col-lg-3 col-md-6">
            <div className="member" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic">
                <img src={artimg1} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Arte que nace del barro... alma de la tierra</h4>
                <span>Cerámica</span>
                <div className="social">
                  <a href="">
                    <img src="../img/arts/work.gif" alt="" />
                  </a>
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
            <div className="member" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic">
                <img src={artimg2} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Arte que nace del barro... alma de la tierra</h4>
                <span>Cerámica</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="member" data-aos="zoom-in" data-aos-delay="300">
              <div className="pic">
                <img src={artimg3} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Adornos y otros objetos útiles</h4>
                <span>Talla en Madera</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
            <div className="member" data-aos="zoom-in" data-aos-delay="400">
              <div className="pic">
                <img src={artimg4} className="img-fluid" alt="" />
              </div>
              <div className="member-info">
                <h4>Adornos y otros objetos útiles</h4>
                <span>Talla en Madera</span>
                <div className="social">
                  <a href="">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Arts
