import { createContext, useState } from 'react'

const SearchContext = createContext()

const initialSearch = ''
const initialSearchId = 0

const SearchProvider = ({ children }) => {
  //search
  const [search, setSearch] = useState(initialSearch)
  const [searchId, setSearchId] = useState(initialSearchId)
  const [url, setUrl] = useState('/home')

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase())
  }
  const handleSearchId = (miId) => {
    console.log(miId)
    setSearchId(miId)
  }
  const handleUrl = () => {
    setUrl(window.location.href)
  }
  //const data = { search, handleSearch }
  return (
    <SearchContext.Provider value={{ search, handleSearch, searchId, handleSearchId, url, handleUrl }}>
      {children}
    </SearchContext.Provider>
  )
}
export { SearchProvider }

export default SearchContext
