  import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import LanguageContext from '../context/LanguageContext'
import Service from './Service'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { TfiPencilAlt } from 'react-icons/tfi'
import shopinitop from '../img/shop/frontshop.png';


import {
  Alert,
  Container,
  Row,
  Col,
  Button,
  Image,
  Carousel,
} from 'react-bootstrap'

const URI = `${process.env.REACT_APP_BACKEND}`
const URIMG = `${process.env.REACT_APP_BACKENDPUB}`

const ShopHome = () => {
  
  const { texts} = useContext(LanguageContext)
 // const [search, setSearch] = useState('') //verrrrr si filtra para si  no quitarle el comentario,
 //a este y a la funcion que tiene lo de fathcer
 // const { url, handleUrl } = useContext(SearchContext)
  const { published } = useParams()
  const { user } = useParams()
  const { currentUser } = useContext(AuthContext)
  let userName = currentUser?.dataValues.userName
  //let userId = currentUser?.dataValues.id
  // const [open, setOpen] = useState(false)
  const [carousel, setCarousel] = useState([])
  const [sesionP, setSesionP] = useState('')

  const navigate = useNavigate()
  useEffect(() => {
    AOS.init()
    //handleUrl()
  }, [])
  useEffect(() => {
    if (published === '0') {
      setSesionP(`${texts.seSubtNP}: ${userName}... `)
    } else if (published === '1' && user !== '0') {
      setSesionP(`${texts.seSubtP}: ${userName}... `)
    } else if (published === '1' && user === '0') {
      setSesionP(texts.seSubt)
    }
    //eslint-disable-next-line
  }, [published, texts.seSubt])
 
  const actyear = new Date().getFullYear().toString()
 
  const getCarouselById = async () => {
    const res = await axios.get(URI + 'carousel/caroId/2')
    setCarousel(res.data)
    console.log(res.data)
  }

  useEffect(() => {
    getCarouselById()
  }, [])
  
  const serviceAdd = () => {
    navigate('/shop/shophome/serviceadd')
  }
  const serviceAddWithoutUser = () => {
    navigate('/auth/login/1/2')
  }
/*
  const submenutoFather = (searchList) => {
    setSearch(searchList)
  }
*/
  return (
    <div id='shophome'>
      <Container fluid className="shophome">
        <Row>
          <Carousel indicators={false} className="m-0 p-0">
            {carousel.map((caro) => (
              <Carousel.Item key={caro.id}>
                <Image
                  src={URIMG + 'types/' + caro.img}
                  className="shopcarousel-img"
                  title={`${caro.imgName} en ${caro.imgPlace}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Row>
        
        <Row className="linebottom-light"></Row>
        <Row>
          <Col>
            <div
              className="section-title"
              data-aos="fade-down"
              data-aos-delay="120"
            >
              <div className='d-flex'>
                  <h1 className="pt-3">
                    {texts.seInfo} {actyear}
                  </h1>
                 
              </div>
              <h3 style={{ fontFamily: 'fantasy', marginBottom: '5' }}>
                {sesionP}
              </h3>
              
              <div>
                {currentUser ? (
                  <p>
                    <Button
                      variant="warning"
                      className="btn-circle"
                      onClick={serviceAdd}
                      title={texts.seBtnOffer}
                    >
                      <TfiPencilAlt />
                    </Button>
                    <span className="service-link" style={{ color: '#a4290e' }}>
                      &nbsp;{texts.seBtnOffer}
                    </span>
                  </p>
                ) : (
                  <p>
                    <Button
                      variant="warning"
                      className="btn-circle"
                      onClick={serviceAddWithoutUser}
                      title={texts.seBtnOffer}
                    >
                      <TfiPencilAlt />
                    </Button>
                    <span className="service-link" style={{ color: '#bd0003' }}>
                      &nbsp;{texts.seBtnOffer}
                    </span>
                  </p>
                )}
              </div>             
              <div className="center-class">
                
                <Alert variant="light" className="mt-2 important-advice">
                  <Alert.Heading style={{ fontSize: '0.9rem' }}>
                    {texts.seAdviceHeader}:
                  </Alert.Heading>
                  <p
                    style={{
                      fontSize: '0.8rem',
                      fontStyle: 'italic',
                      color: '#7e7c7c',
                      fontWeight: 'normal',
                    }}
                  >
                    {texts.seAdviceBody}
                  </p>
                  <p
                    style={{
                      fontSize: '0.8rem',
                      fontStyle: 'italic',
                      color: '#5c5b5b',
                      textAlign: 'end',
                      fontWeight: 'normal',
                    }}
                  >
                    {texts.seAdviceFooter}
                  </p>
                  <hr className="mb-0" />
                </Alert>
                <Image 
                    src={shopinitop}  className="img-fluid shophome-img small-animated " 
                    alt=""
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>          
            <Service published={published} user={user} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ShopHome


/*



<div>
                <Submenu 
                  fromTable='type'
                  submenutoFather={submenutoFather}
                /> 
              </div>
              */
