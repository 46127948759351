import React, { useEffect, useState, useContext, useRef } from 'react'
import LanguageContext from '../context/LanguageContext'
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../content/donation.css'
import { Container, Card, Row, Col, Button, Accordion , FloatingLabel, Form } from 'react-bootstrap'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { BiSolidDonateHeart } from 'react-icons/bi'
import { FaRegCheckSquare } from 'react-icons/fa'

const formatDate = (d) => {
  return (
    d.substring(0, d.indexOf('T')) +
    ' ' +
    d.substring(d.indexOf('T'), d.indexOf('T'))
  )
}

const URI = `${process.env.REACT_APP_BACKEND}`

const Donation = () => {

  /*
  Cualquier donación significa una gran diferencia, cada dólar cuenta"
"Tus donaciones irán a [causa o gastos específicos]"
"¡No podemos hacerlo sin ti!"
Botones de donación "Donar, "Donar ahora, "Ayuda a cambiar vidas, "Ayúdanos, "Apoya nuestro trabajo. 
  */

  const { texts } = useContext(LanguageContext)
  const [donors, setDonors] = useState([])
  const [money, setMoney] = useState(0)  
  const [other, setOther] = useState(0) 
  const [name, setName] = useState('')  
  const [anonymous, setAnonymous] = useState(false)
  const [newDonor, setNewDonor] = useState(true) 
  const [toPaid, setToPaid] = useState(true)
  const firstDiv = useRef()

  const { currentUser } = useContext(AuthContext)
  
  const getDonors = async() => {
    const res = await axios.get(URI + 'donor')
    setDonors(res.data)
    console.log(res.data)
    setDonors(res.data.filter((dato) => dato.show===1))
  } 
 
  useEffect(() => {
    AOS.init()
    firstDiv.current.focus()
    getDonors()
    setNewDonor(false)
  }, [newDonor])

  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  const handleOther = (e) => {
    setOther(e.target.value)
    setMoney(e.target.value)
    setToPaid(true)
  }
  const handleName = (e) => {
    if (e.target.value !== null && e.target.value !== '')
      {
        setName(e.target.value)
        setAnonymous(false)
      }
    else
      setName('')
      
  }
  const handleSubmit = async(e) => {
    if (money > 0)
    {
      setToPaid(true)
    // aqui iria el proceso con la pasarela de pago
      e.preventDefault()
      await axios.post(URI + 'donor/', {
        userId: currentUser ? currentUser?.dataValues.id : 0,        
        nickName:name,
        total: money
      })
      setNewDonor(true)
      setMoney(0)
      setOther('')
      setName('')
      Swal.fire({
        title: texts.doOk,
        text: texts.doThanks,
        icon: 'success',
      })
      navigate('/donation')
    }
    else
    setToPaid(false)
  }

  return (
    <div id='donation' className="center-class">
      <Container className="ms-4 me-4 mb-4 p-1" >
        <div  tabIndex={-1} ref={firstDiv}></div>
        <Row >
          <Col lg={12} className="section-title" data-aos="fade-right">
          <h2>{texts.doTitle}</h2>
          <p style={{ fontFamily: 'fantasy' }}>{texts.doSubt}</p>
        </Col></Row>
        <Row><Col className="d-flex mb-1">
        
          <Button 
           size="sm" 
           onClick={goBack}
           variant="link"
           className='donation-back-arrow'>
           
              <IoArrowUndoOutline
                style={{
                  fontSize: '1rem',
                  color: '#ffffff',
                }}
                title={texts.backPage}
              />

          </Button>
            <div className="donation-back-text"  style={{paddingTop:'4'}}>
              {texts.backPage}
            </div>
          </Col>
        </Row> 
        <Row>
          <Col className="d-flex mt-3 mb-3 justify-content-center">
            <Card>
              <Card className='card-border'>
              {texts.idiom === 'es' ?
                <div>
                  <p>AvenidaCuba es una plataforma de ayuda  para la comunidad Cubana. Nuestro propósito es facilitar el intercambio entre los cubanos, donde quiera que se encuentren. Somos un sitio sin fines de lucro, no cobramos, no vendemos, solamente servimos de apoyo para todo el que quiera vender u opinar.</p>                  
                  <p>Si cree que somos de alguna ayuda y que debemos continuar brindando nuestros servicios, puede apoyarnos dejando una donación, por pequeña que sea, será siempre una ayuda y un reconocimiento a nuestro esfuerzo.</p>                  
                  <p style={{textAlign:'right'}}>Muchas Gracias...</p>
                </div>
                :
                <div>
                  <p>AvenidaCuba is a help platform for the Cuban community. Our purpose is to facilitate exchange between Cubans, wherever they are. We are a non-profit site, we do not charge, we do not sell, we only serve as support for anyone who wants to sell or give an opinion.</p>
                  <p>If you think that we are of some help and that we should continue providing our services, you can support us by leaving a donation, no matter how small, it will always be a help and recognition of our efforts.</p>
                  <p style={{textAlign:'right'}}>Thank you so much</p>
                </div>
              }
              </Card>                
            </Card>
          </Col>
          <Col>
            <Form>
              <div className="d-flex mt-5 mb-3 justify-content-center">
                <div onClick={()=>{setMoney(1);setOther(1);setToPaid(true)}} className='donation-circle'>
                  <p style={{marginTop:'25',fontWeight:'bold',fontSize:'1.8rem'}}>$1.00</p>
                </div>
               
                <div onClick={()=>{setMoney(3);setOther(3);setToPaid(true)}}className='donation-circle'><p style={{marginTop:'25',fontWeight:'bold',fontSize:'1.8rem'}}>$3.00</p></div>
             
                <div onClick={()=>{setMoney(5);setOther(5);setToPaid(true)}}  className='donation-circle'><p style={{marginTop:'25',fontWeight:'bold',fontSize:'1.8rem'}}>$5.00</p></div>

                <div onClick={()=>{setMoney(10);setOther(10);setToPaid(true)}}className='donation-circle'><p style={{marginTop:'25',fontWeight:'bold',fontSize:'1.8rem'}}>$10.00</p></div>

              </div>
              <div className="d-flex mt-3 mb-3 justify-content-center">              
                <FloatingLabel
                    label={
                      <span>
                        {texts.doOther}
                      </span>
                    }
                    className='pt-3'
                  >
                    <Form.Control
                      name='money'
                      value={other}
                      className='donation-others'
                      style={{fontWeight:'bold',fontSize:'1.8rem'}}
                      onChange={handleOther}                    
                      type="text"
                    />
                  </FloatingLabel>
              </div>
              
              <div className="d-flex mt-3 justify-content-center">
                <FloatingLabel
                  label={
                    <span>
                     {texts.doFor}
                    </span>
                  }
                  className='pt-3'
                >                  
                  <Form.Control
                    name='name'
                    value={name}
                    className='donation-others'
                    style={{fontSize:'1rem'}}
                    onChange={handleName}                    
                    type="text"

                  />

                    <Form.Check
                      type="checkbox"
                      name="anonymous"
                      style={{ paddingTop: '0.4rem',fontSize:'0.8rem',fontStyle:'italic' }}
                      value={anonymous}
                      onChange={(e) => {
                        anonymous=== false && setName('');
                        setAnonymous(!anonymous)}}
                      checked={anonymous}                                    
                      label={texts.doanonymousyes}
                    />

                </FloatingLabel>
                <Button
                  className="me-3 button-shadow mt-3 mb-4"
                  size="sm"
                  onClick={handleSubmit}
                  variant="danger"
                  title={texts.doAddTitle}
                >              
                  <div style={{ color: 'ffffff', display: 'flex' }}>
                    <FaRegCheckSquare style={{ fontSize: '1rem' }} />
                    {texts.doAdd}
                  </div>
                </Button>
            </div>   
            {!toPaid &&
                <div className='line-error1'>
                  Si desea donar debe poner la cantidad... Gracias
                </div>
            }        
           </Form>
          </Col>
        </Row>
        <Row data-aos="fade-left" className="mt-2">
          <Col>
            <span className="service-title">{texts.doGrateful}</span>
            <p>
              <div className="comment-mine">
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        className="accordion-header pt-0"
                        title={texts.doClickToSee}
                      >
                        {texts.doOurDonors}
                      </Accordion.Header>
                      <Accordion.Body style={{ background: '#ffffff' }}>
                        
                          <div className="comment-table-donor p-0 m-0">                        
                            {donors.map((item) => (
                              <span key={item.id}  >
                                 <BiSolidDonateHeart style={{color:'#bd0003',marginRight:'5',marginBottom:'3'}} />
                                 <span style={{fontWeight:'bolder',color:'#ba8f02',marginRight:'5'}}>{item.nickName===''?texts.doanonymous:item.nickName}</span>              
                                 <span style={{fontSize:'0.7rem',marginTop:'2',marginRight:'7'}}>({item.createdAt!=null ?formatDate(item.createdAt.toString()):'?'}),</span>
                              </span>
                            ))}
                          
                          </div>
                        
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Donation